import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { FormInput } from '../../../interfaces/Form';
import { AppData } from '../../../providers/AppData';
import ManualEnergyUsage from './ManualEnergyUsage';
import EnergyUsageLookup from './EnergyUsageLookup';
import { AppState } from '../../../stores/store';
import { useSelector } from 'react-redux';
import { QuotesState } from '../../../models/quotes-state.model';

const EnergyUsage: FunctionComponent<FormInput> = (props: FormInput) => {
  const [showManualUsage, setShowManualUsage] = useState<boolean>(false);
  const { toggles } = useContext(AppData);
  const quotes = useSelector<AppState, QuotesState>(state => state.quotes);
  const isError = quotes.electricity.error !== null || quotes.gas.error !== null;
  const [removeCompleted, setRemoveCompleted] = useState<boolean>(false);
  
  const handleInput = (event: boolean) => {
    if (event) {
      props.updateGroupStatus(true);
    } else {
      setShowManualUsage(true);
      props.updateGroupStatus(false);
    }
  };

  useEffect(() => {
    if (isError) {
      setRemoveCompleted(true);      
    }
  }, [isError]);

  useEffect(() => {
    if (removeCompleted) {
      props.updateGroupStatus(false);
    }
  }, [removeCompleted])

  return (
    <>
      {(toggles.show_energy_usage_lookup_step && !showManualUsage && !isError) ? (
        <EnergyUsageLookup formikProps={props} handleInput={handleInput} />
      ) : (
        <ManualEnergyUsage {...props} />
      )}
    </>
  );
};

export default EnergyUsage;