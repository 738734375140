import { Address } from "../../../models/address.model";
import { CompanyByNumber } from "../../../models/company.model";

export type FreeTypedSoleTrader = Omit<CompanyByNumber, "registeredAddress"> & {
  registeredAddress: Pick<Address, "postCode">;
};

export const createFreeTypedSoleTrader = (
  name: string,
  postcode: string
): FreeTypedSoleTrader => {
  const soleTrader: FreeTypedSoleTrader = {
    companyStatus: "active",
    creditScore: {
      score: 60,
      provider: "FreeTyped",
      checkedOn: new Date().toUTCString(),
    },
    incorporationDate: new Date().toUTCString(),
    isMicroBusiness: true,
    name: name,
    number: "unknown",
    registeredAddress: {
      postCode: postcode,
    },
    sicCodes: [],
  };
  return soleTrader;
};
