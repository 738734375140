import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import { composeWithDevTools } from 'redux-devtools-extension';

import {AppStages, JourneyForm} from '../models/journey.model';
import { SupplierConfig } from "../models/supplier-config.model";
import { AnalyticsState } from "./analytics/models";
import { QuotesState } from "../models/quotes-state.model";
import { MetadataState } from "./reducers/Metadata";

export interface AppState {
    journey: JourneyForm;
    quotes: QuotesState;
    suppliers: SupplierConfig[];
    viewedTour : boolean;
    changedFromDualFuel : boolean;
    completedStages : AppStages[];
    analytics: AnalyticsState;
    metadata: MetadataState;
}

export default createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));