import React, { useContext } from "react";

import IconProps from "../../interfaces/Icon";
import { AppData } from "../../providers/AppData";

const IconElectricity = (props: IconProps) => {
    const context = useContext(AppData);

    const icon = context.branding.electricity_icon;
    if (icon && icon.url) {
      return (
        <img
          width="80"
          height="80"
          className={props.wrapperClass || ""}
          src={icon.url}
          alt={icon.alt ?? ""}
        />
      );
    }
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" className={ props.wrapperClass || '' }>
            <polygon points="30.41 66 38.2 39.16 30.41 39.16 37.6 14 45.99 14 41.2 30.77 49.59 30.77 31.61 66 30.41 66" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
        </svg>
    );
};

export default IconElectricity;