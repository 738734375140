const isBrowser = () => typeof window !== "undefined";
export const canStoreLocalStorageItem = (key: string): boolean =>
  getLocalStorageItem("acceptCookies") === "true" || key === "acceptCookies";

export const setLocalStorageItem = (
  key: string,
  value: string,
  ttlDays: number = 180
): boolean => {
  if (!isBrowser() || !canStoreLocalStorageItem(key)) {
    return false;
  }

  const date = new Date();
  date.setDate(date.getDate() + ttlDays);

  const item = {
    value,
    expiry: ttlDays ? date : null,
  };
  localStorage.setItem(key, JSON.stringify(item));

  return true;
};

export const getLocalStorageItem = (key: string): string | null => {
  if (!isBrowser()) {
    return null;
  }

  const storedItem = localStorage.getItem(key);

  if (!storedItem) {
    return null;
  }

  const item = JSON.parse(storedItem);
  if (item.expiry && new Date() > new Date(item.expiry)) {
    localStorage.removeItem(key);

    return null;
  }

  return item.value;
};
