import styled from "styled-components";
import { format, subYears } from "date-fns";
import { Field, getIn } from "formik";
import { Label } from "./Label"

const noop = () => { };

type FieldWrapperProps = {
  name: string;
  type: string;
  label: string;
  placeholder: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const DataInput = styled.input`
  display: inline-block;
  position: relative;

  &::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
`; 
 
export const FieldWrapper = ({
  name,
  label,
  placeholder,
  type,
  onChange = noop,
}: FieldWrapperProps) => (
  <Field name={name}>
    {({ field, form: { touched, errors } }: any) => (
      <div
        className={
          getIn(touched, field.name) && getIn(errors, field.name)
            ? "form__group invalid"
            : "form__group"
        }
      >
        <Label htmlFor={field.name}>{label}</Label>
        <DataInput
          name={field.name}
          placeholder={placeholder}
          className={"form__control"}
          type={type}
          {...field}
          onChange={onChange}
          max={
            type === "date"
              ? `${format(subYears(new Date(), 18), "yyyy-MM-dd")}`
              : undefined
          }
        />
        {getIn(touched, field.name) && getIn(errors, field.name) && (
          <span className={"form__error"}>
            {getIn(errors, field.name) ?? ""}
          </span>
        )}
      </div>
    )}
  </Field>
);
