import { useContext } from 'react';
import styled from 'styled-components';
import { AppData } from '../providers/AppData';

const Wrapper = styled.div`
  margin-top: 1rem;
  color: var(--stepper-bar-text);
`;

const StepperWrapper = styled.div`
  margin-top: auto;
  padding-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  border-bottom: 2px solid #ccc;
`;

const StepperItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  &:before {
    position: absolute;
    content: '';
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 20px;
    left: -50%;
    z-index: 2;
  }
  &:after {
    position: absolute;
    content: '';
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 2;
  }
  &:first-child&:before {
    content: none;
  }
  &:last-child&:after {
    content: none;
  }
`;

const StepperCounter = styled.div`
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #ccc;
  margin-bottom: 6px;
`;

const StepperName = styled.div``;

const StepperItemActive = styled(StepperItem)`
  font-weight: bold;
`;

const StepperCounterCompleted = styled(StepperCounter)`
  background-color: var(--stepper-bar);
`;

const StepperItemCompleted = styled(StepperItem)`
  &:after {
    position: absolute;
    content: '';
    border-bottom: 2px solid var(--stepper-bar);
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 3;
  }
`;

const ProgressStepsBar = () => {
  const { labels } = useContext(AppData);
  const url = window.location.pathname;
  return (
    <Wrapper>
      <StepperWrapper>
        {url.includes('/journey/supply-details') && (
          <>
            <StepperItemActive>
              <StepperCounter>1</StepperCounter>
              <StepperName>{labels.stepper_first_step}</StepperName>
            </StepperItemActive>
            <StepperItem>
              <StepperCounter>2</StepperCounter>
              <StepperName>{labels.stepper_second_step}</StepperName>
            </StepperItem>
          </>
        )}
        {url.includes('/journey/company-details') && (
          <>
            <StepperItemCompleted>
              <StepperCounterCompleted>1</StepperCounterCompleted>
              <StepperName>{labels.stepper_first_step}</StepperName>
            </StepperItemCompleted>
            <StepperItemActive>
              <StepperCounter>2</StepperCounter>
              <StepperName>{labels.stepper_second_step}</StepperName>
            </StepperItemActive>
          </>
        )}
        <StepperItem>
          <StepperCounter>3</StepperCounter>
          <StepperName>{labels.stepper_third_step}</StepperName>
        </StepperItem>
      </StepperWrapper>
    </Wrapper>
  );
};

export default ProgressStepsBar;
