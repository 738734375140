import React from 'react';
import { AddressComponentState } from '../../models/address.model';
import { FormInput } from '../../interfaces/Form';
import { Journey, JourneyUtilityType } from '../../models/journey.model';

import SingleFuelAddressLookup from './AddressLookup/SingleFuelAddressLookup';
import DualFuelAddressLookup from './AddressLookup/DualFuelAddressLookup';

class AddressLookup extends React.Component<
  FormInput & { journey: Journey },
  AddressComponentState
> {
  public render = (): JSX.Element => {
    const type = this.props.form.values.journeyUtilityType;
    return (
      <>
        {(type === JourneyUtilityType.Electricity ||
          type === JourneyUtilityType.Gas) && (
          <SingleFuelAddressLookup {...this.props} />
        )}

        {type === JourneyUtilityType.DualFuel && (
          <DualFuelAddressLookup {...this.props} />
        )}
      </>
    );
  };
}

export default AddressLookup;
