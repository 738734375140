import styled from "styled-components";

const TextButton = styled.button`
  appearance: none;
  padding: 0;
  margin: 0;
  color: var(--button-primary);
  background-color: transparent;
  box-shadow: none;
  text-decoration: underline;
  border: none;
  cursor: pointer;
  outline: none;
  min-width: 0;
  font-family: var(--font-family);
  font-size: 1.6rem;
  font-weight: normal;
`;

export default TextButton;
