// Lib
import React from "react";

import { QuoteDetailsProps } from "../../interfaces/Quotes";

import QuoteDetailsList from "./QuoteDetailsList";

class QuoteDetails extends React.Component<QuoteDetailsProps> {
  public render = (): JSX.Element => {
    return (
          <QuoteDetailsList
            listType={this.props.listType}
            quote={this.props.quote}
            children={this.props.children}
          />
    )
  };
}

export default QuoteDetails;
