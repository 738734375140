import { useContext, useEffect, useState } from 'react';
import { AppData } from '../../providers/AppData';
import styled from 'styled-components';

const Text = styled.p`
  font-size: 1.8rem;
`;

const QuotesLoadMessage = () => {
  const { labels } = useContext(AppData);
  const [index, setIndex] = useState(0);

  const loadingMessages =
    labels.quotes_loading_messaging.length === 1 ||
    labels.quotes_loading_messaging.length === 0
      ? [
          'Checking meter details...',
          'Checking your current supplier...',
          'Loading your personalised quotes...',
        ]
      : labels.quotes_loading_messaging.map((message) => message.text);

  useEffect(() => {
    if (index === loadingMessages.length - 1) return;
    const tick = () => setIndex((i) => i + 1);
    const id = setInterval(tick, 4500);
    return () => clearInterval(id);
  }, [index, loadingMessages.length]);

  return (
    <>
      <Text>{loadingMessages[index % loadingMessages.length]}</Text>
    </>
  );
};

export default QuotesLoadMessage;
