import { CLEAR_QUOTES, SAVE_ELECTRICITY_QUOTES, SAVE_GAS_QUOTES, SAVE_ELECTRICITY_ERROR, SAVE_GAS_ERROR } from "../actions/types";
import { quotesInitialState } from "../initialState";
import { Quote } from "../../models/quote.model";
import { QuotesState } from "../../models/quotes-state.model";

export type QuotesAction = {
    type: typeof SAVE_ELECTRICITY_QUOTES | typeof SAVE_GAS_QUOTES
    payload: Quote[],
} | {
    type: typeof SAVE_ELECTRICITY_ERROR | typeof SAVE_GAS_ERROR
    payload: string
} | {
    type: typeof CLEAR_QUOTES 
}

export default function Quotes(state: QuotesState = quotesInitialState, action: QuotesAction): QuotesState{
    switch (action.type){
        case SAVE_ELECTRICITY_QUOTES : {
            return {...state, electricity: {...state.electricity, quotes: action.payload}}
        }

        case SAVE_GAS_QUOTES: {
            return {...state, gas: {...state.gas, quotes: action.payload}}
        }

        case SAVE_ELECTRICITY_ERROR: {
            return {...state, electricity: {...state.electricity, error: action.payload}}
        }

        case SAVE_GAS_ERROR: {
            return {...state, gas: {...state.gas, error: action.payload}}
        }

        case CLEAR_QUOTES : {
            return quotesInitialState;
        }

        default:
            return state;
    }
}