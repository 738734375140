import { JourneyCompanyType } from "../../../interfaces/CompanyType";
import { Company } from "../../../models/company.model";
import CompanyLookup, { CompanyLookUpProps } from "./CompanyLookup";
import SoleTraderLookup from "./SoleTraderLookup";

export default function CompanyLookUpWrapper(props: CompanyLookUpProps) {
  const isSoleTrader = props.companyType === JourneyCompanyType.SoleTrader;

  const handleOnSubmit = (company: Company) => {
      props.handleSubmit(company)
  }
  

  const handleOnDeselect = () => {
      props.handleSubmit(undefined)
  }

  if (isSoleTrader) {
    return (
      <SoleTraderLookup
        onSelectedCompany={handleOnSubmit}
        onDeselectedCompany={handleOnDeselect}
      />
    );
  } else {
    return <CompanyLookup {...props} />;
  }
}
