// Lib
import React from "react";

// TS Definitions
import { JourneyCompanyType, CompanyTypes } from "../../interfaces/CompanyType";
import { AppLayoutData } from "../../interfaces/Journey";
import { AppData } from "../../providers/AppData";


export type CompanyTypeProps = {
    name: string;
    companyType: JourneyCompanyType | undefined;
    handleSubmit: (value: JourneyCompanyType | undefined) => void;
}

class CompanyType extends React.Component<CompanyTypeProps> {
    static contextType: React.Context<AppLayoutData> = AppData;
    private types: CompanyTypes[] = ["Limited", "LLP", "SoleTrader"];

    public render = (): JSX.Element => {
        return (
            <>
                <label htmlFor={this.props.name} className={"form__label"}>
                    {this.context.labels.company_type_title}
                </label>
                <div className={"radio-group"}>
                    {this.types.map((option, idx) => {
                        let label = "";

                        switch(JourneyCompanyType[option]){
                            case JourneyCompanyType.Limited:
                                label = this.context.labels.company_type_limited;
                                break;
                            case JourneyCompanyType.LLP:
                                label = this.context.labels.company_type_llp;
                                break;
                            case JourneyCompanyType.SoleTrader:
                                label = this.context.labels.company_type_sole_trader;
                                break;
                        }

                        return (
                            <div key={`${this.props.name}-${option}`} className={"radio-button"}>
                                <input name={this.props.name}
                                       id={`${this.props.name}-${option}`}
                                       onChange={() => this.props.handleSubmit(JourneyCompanyType[option])}
                                       onBlur={() => this.props.handleSubmit(JourneyCompanyType[option])}
                                       type={"radio"}
                                       checked={this.props.companyType === JourneyCompanyType[option]}
                                       value={JourneyCompanyType[option]}
                                       data-testid={`journeyCompanyType-${option}`} />
                                <label htmlFor={`${this.props.name}-${option}`}>{label}</label>
                            </div>
                        );
                    })}
                </div>
            </>
        )
    }
}

export default CompanyType;