import React from "react";

import IconProps from "../../interfaces/Icon";

const Spinner = (props: IconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" className={props.wrapperClass || ''}>
            <circle cx="50" cy="50" fill="none" stroke={props.stroke || '#ffffff'} strokeWidth="5" r="40" strokeDasharray="188.49555921538757 64.83185307179586">
                <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur={props.duration || "1.2s"} values="0 50 50;360 50 50" keyTimes="0;1" />
            </circle>
        </svg>
    );
};

export default Spinner;