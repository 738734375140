// Lib
import React from "react";
import { Field } from "formik";

// Components
import IconTick from "../icons/IconTick";
import SoleTraderDetails from "./SoleTraderDetails/SoleTraderDetails";
// import TitleDropdown from "./TitleDropdown";

// Providers
import { AppData } from "../../providers/AppData";

// TS Definitions
import { AppLayoutData } from "../../interfaces/Journey";
import { SwitchFormComponentProps } from "../../interfaces/Form";

type PersonalDetailsProps = SwitchFormComponentProps & { isSoleTraderJourney: boolean };
class PersonalDetails extends React.Component<PersonalDetailsProps> {
  static contextType: React.Context<AppLayoutData> = AppData;

  public callFieldChangeFromInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    this.props.onFieldChange(
      event.target.getAttribute("name") ?? "",
      event.target.value
    );
  };

  public checkKeyInput = (
    event: React.KeyboardEvent<HTMLInputElement>
  ): void | boolean => {
    const utilKeys = [
      "Backspace",
      "Delete",
      "+",
      "Tab",
      "CapsLock",
      "ArrowRight",
      "ArrowLeft",
      "ArrowDown",
      "ArrowUp",
      "Control",
      "v",
      "c",
      "a"
    ];
    if (!/^\d+$/.test(event.key) && utilKeys.indexOf(event.key) === -1) {
      event.preventDefault();
    }

    return true;
  };

  public callFieldChangeFromDropdown = (
    name: string,
    value: string | boolean | null
  ) => {
    this.props.onFieldChange(name, value);
  };

  public render = (): JSX.Element => {
    return (
      <>
        <div className={"form__intro"}>
          <h2>
            <IconTick wrapperClass={"icon"} />
            {this.context.labels.contact_information_title}
          </h2>
          <p>{this.context.labels.contact_information_sub_title}</p>
        </div>
        <div className={"grid"}>
          <div className={"col-xs-6"}>
            <div
              className={
                this.props.errors.contactInformation?.firstname &&
                this.props.touched.contactInformation?.firstname
                  ? "form__group invalid"
                  : "form__group"
              }
            >
              <label className={"form__label"}>
                {this.context.labels.contact_information_first_name_placeholder}
              </label>
              <Field
                type="text"
                name="contactInformation.firstname"
                className={"form__control"}
                placeholder={
                  this.context.labels.contact_information_first_name_placeholder
                }
                value={this.props.values?.contactInformation.firstname}
                onChange={this.callFieldChangeFromInput}
                data-testid={"journeyContactInformation-first_name"}
              />
              {this.props.errors.contactInformation?.firstname &&
              this.props.touched.contactInformation?.firstname ? (
                <span className={"form__error"}>
                  {this.props.errors.contactInformation?.firstname || ""}
                </span>
              ) : null}
            </div>
          </div>
          <div className={"col-xs-6"}>
            <div
              className={
                this.props.errors.contactInformation?.surname &&
                this.props.touched.contactInformation?.surname
                  ? "form__group invalid"
                  : "form__group"
              }
            >
              <label className={"form__label"}>
                {this.context.labels.contact_information_surname_placeholder}
              </label>
              <Field
                type="text"
                name="contactInformation.surname"
                className={"form__control"}
                value={this.props.values?.contactInformation.surname}
                placeholder={
                  this.context.labels.contact_information_surname_placeholder
                }
                onChange={this.callFieldChangeFromInput}
                data-testid={"journeyContactInformation-surname"}
              />
              {this.props.errors.contactInformation?.surname &&
              this.props.touched.contactInformation?.surname ? (
                <span className={"form__error"}>
                  {this.props.errors.contactInformation?.surname || ""}
                </span>
              ) : null}
            </div>
          </div>
          <div className={"col-xs-6"}>
            <div
              className={
                this.props.errors.contactInformation?.email &&
                this.props.touched.contactInformation?.email
                  ? "form__group invalid"
                  : "form__group"
              }
            >
              <label className={"form__label"}>
                {this.context.labels.contact_information_email_placeholder}
              </label>
              <Field
                type="email"
                name="contactInformation.email"
                className={"form__control"}
                value={this.props.values?.contactInformation.email}
                placeholder={this.context.labels.contact_information_email_placeholder}
                onChange={this.callFieldChangeFromInput}
                data-testid={"journeyContactInformation-email"}
              />
              {this.props.errors.contactInformation?.email &&
              this.props.touched.contactInformation?.email ? (
                <span className={"form__error"}>
                  {this.props.errors.contactInformation?.email || ""}
                </span>
              ) : null}
            </div>
          </div>
          <div className={"col-xs-6"}>
            <div
              className={
                this.props.errors.contactInformation?.phoneNumber &&
                this.props.touched.contactInformation?.phoneNumber
                  ? "form__group invalid"
                  : "form__group"
              }
            >
              <label className={"form__label"}>
                {this.context.labels.contact_information_phone_number_placeholder}
              </label>
              <Field
                type="tel"
                inputMode={"numeric"}
                maxLength={"13"}
                name="contactInformation.phoneNumber"
                className={"form__control"}
                value={this.props.values?.contactInformation.phoneNumber}
                placeholder={
                  this.context.labels.contact_information_phone_number_placeholder
                }
                onKeyDown={this.checkKeyInput}
                onChange={this.callFieldChangeFromInput}
                data-testid={"journeyContactInformation-phone_number"}
              />
              {this.props.errors.contactInformation?.phoneNumber &&
              this.props.touched.contactInformation?.phoneNumber ? (
                <span className={"form__error"}>
                  {this.props.errors.contactInformation?.phoneNumber || ""}
                </span>
              ) : null}
            </div>
          </div>
        </div>
        {this.props.isSoleTraderJourney && (
          <div style={{ marginTop: "3.5rem" }}>
            <SoleTraderDetails {...this.props} />
          </div>
        )}
      </>
    );
  };
}

export default PersonalDetails;
