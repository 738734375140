import { ThunkDispatch } from 'redux-thunk';
import { Action, Dispatch } from 'redux';
import API from '../../lib/API';

import {
  AppStages,
  Journey,
  JourneyForm,
  JourneyUtilityType,
} from '../../models/journey.model';
import {
  saveElectricityErrors,
  saveElectricityQuotes,
  saveGasErrors,
  saveGasQuotes,
} from './saveQuotes';
import { Quote } from '../../models/quote.model';
import { updateJourney } from './updateJourney';
import { QuotesAction } from '../reducers/Quotes';
import { AppState } from '../store';
import { FuelType } from '../../models/utility-detail';
import { storeMpxn } from '../reducers/Metadata';
import { updateStage } from './updateStage';
type MyRootState = {};
type MyExtraArg = undefined;
type MyThunkDispatch = ThunkDispatch<MyRootState, MyExtraArg, Action>;

export const loadQuotes = () => {
  return async (dispatch: MyThunkDispatch, getState: () => AppState) => {
    const { journey } = getState();
    if (journey.journeyUtilityType === JourneyUtilityType.Electricity) {
      const electricity = await internalGetQuotes(
        journey,
        JourneyUtilityType.Electricity,
        dispatch,
        saveElectricityErrors,
        updateStage,
        saveElectricityQuotes
      );
      dispatch(
        updateJourney({
          quotes: {
            electricityQuotes: electricity,
            gasQuotes: [] as Quote[],
          },
        } as JourneyForm)
      );
    }
    if (journey.journeyUtilityType === JourneyUtilityType.Gas) {
      const gas = await internalGetQuotes(
        journey,
        JourneyUtilityType.Gas,
        dispatch,
        saveGasErrors,
        updateStage,
        saveGasQuotes
      );
      dispatch(
        updateJourney({
          quotes: {
            electricityQuotes: [] as Quote[],
            gasQuotes: gas,
          },
        } as JourneyForm)
      );
    }

    if (journey.journeyUtilityType === JourneyUtilityType.DualFuel) {
      const gas = await internalGetQuotes(
          journey,
          JourneyUtilityType.Gas,
          dispatch,
          saveGasErrors,
          updateStage,
          saveGasQuotes
      );
      const electricity = await internalGetQuotes(
        journey,
        JourneyUtilityType.Electricity,
        dispatch,
        saveElectricityErrors,
        updateStage,
        saveElectricityQuotes
      );
      dispatch(
        updateJourney({
          quotes: {
            electricityQuotes: electricity,
            gasQuotes: gas,
          },
        } as JourneyForm)
      );
    }
  };
};

const internalGetQuotes = async (
  journey: Journey,
  utilityType: JourneyUtilityType,
  dispatch: Dispatch,
  errorFunction: (content: string) => QuotesAction,
  removeStageFunction: (stage: AppStages, remove: boolean) => any,
  quoteFunction: (content: Quote[]) => QuotesAction
): Promise<Quote[]> => {
  try {
    const resp = await API.getQuotes(journey.uid as string, utilityType);

    if (resp.message) {
      dispatch(errorFunction(resp.message));  
    }

    dispatch(quoteFunction(resp.quotes));

    const fuelType = utilityType === JourneyUtilityType.Electricity ? FuelType.Electricity : FuelType.Gas;

    if (resp.metadata.currentSupplier) {
      if (fuelType === FuelType.Electricity) {
        journey.currentSupplier = {
          electricity: {
            reference: resp.metadata.currentSupplier,
            name: ''
          }
        }
      }
      else {
        journey.currentSupplier = {
          gas: {
            reference: resp.metadata.currentSupplier,
            name: ''
          }
        }
      }
    }

    dispatch(storeMpxn({ mpxn: resp.metadata.meterPoint, fuelType }));
    const quoteDetails = journey.utilities.quoteDetails;
    if (fuelType === FuelType.Gas)
    {
      const index = quoteDetails.findIndex(detail => detail.quoteType === 1);
      quoteDetails[index].metadata.mprn = resp.metadata.mpxn;
      quoteDetails[index].supplyConsumption.eac = Number(resp.metadata.aq);
      quoteDetails[index].supplyConsumption.energy = Number(resp.metadata.aq);
    }

    if (fuelType === FuelType.Electricity)
    {
      const index = quoteDetails.findIndex(detail => detail.quoteType === 0);
      quoteDetails[index].supplyConsumption.eac = Number(resp.metadata.eac);
      quoteDetails[index].supplyConsumption.energy = Number(resp.metadata.eac);
    }
    
    return resp.quotes;

  } catch (err_1) {
    dispatch(errorFunction('api_error'));
    return [];
  }
};
