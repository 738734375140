import { Address } from "../models/address.model";
import { Company } from "../models/company.model";
import { ContactInformation } from "../models/contact-information.model";
import { DirectDebit } from "../models/direct-debit.model";
import { Consent, CurrentSupplier, JourneyForm, Stage, Utilities } from "../models/journey.model";
import { Consumption } from "../models/consumption.model";
import { ConsumptionTypes } from "../models/consumption-types";
import { QuotesState } from "../models/quotes-state.model";

export const initialState: JourneyForm = {
    "uid": null,
    "type": "b2b",
    "created": {} as Date,
    "completed": false,
    "supplyAddress": {} as Address,
    "gasSupplyAddress": {} as Address,
    "supplyCompany": {} as Company,
    "contactInformation": {} as ContactInformation,
    "directDebit": {} as DirectDebit,
    "utilities": {} as Utilities,
    "journeyUtilityType": null,
    "affiliate": "",
    "stage": {} as Stage,
    "consent": {} as Consent,
    "quotePeriod": null,
    "supplyUsage": [{} as Consumption],
    "supplyConsumptionType": ConsumptionTypes.Spend,
    "utilityDetails": [],
    "legals": { mandatory: {}, optional: {} },
    "ipAddress": "",
    "currentSupplier": { electricity: {}, gas: {} } as CurrentSupplier,
    "payments": {
        gocardless: {
            paidStatus: "unset",
            recurringPayment: true,
            metadata: {
                billingRequestFlowId: undefined,
                billingRequestId: undefined,
                customerId: undefined,
            }
        }
    },
    "journeyState": {
        "state": 0,
        "externalId": "",
        "sentWebQuote": false
    },
    "quotes": {
        "electricityQuotes": [],
        "gasQuotes": []
    },
    "saveQuote": {
        "identifier": undefined,
        "publicUrl": undefined,
        "salesPublicUrl": undefined
    },
    "companyType": undefined,
    "metadata": { 
        stackInformation: undefined,
        switchStatus: undefined
    },
    "usageLookup": false
};

export const quotesInitialState: QuotesState = {
    electricity: {
        quotes: [],
        error: null,
    },
    gas: {
        quotes: [],
        error: null,
    }
};
