import { ThunkDispatch } from "redux-thunk";
import { Action } from 'redux';
import API from "../../lib/API";
import { SupplierConfig } from "../../models/supplier-config.model";
import { saveSuppliers } from "./saveSuppliers";

type MyRootState = {};
type MyExtraArg = undefined;
type MyThunkDispatch = ThunkDispatch<MyRootState, MyExtraArg, Action>;

export const getSuppliers = () => {
    return (dispatch: MyThunkDispatch) => {
        return API.getSuppliers()
            .then((resp: SupplierConfig[]) => {
                return Promise.resolve(dispatch(saveSuppliers(resp)));
            })
            .catch(err => {
                console.log(err);
                return Promise.reject('Error occurred while trying to get quotes');
            });
    }
}