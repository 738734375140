import {CREATE_JOURNEY, RESTART_JOURNEY, SAVE_JOURNEY} from "./types";
import {Journey, JourneyUtilityType} from "../../models/journey.model";

export const saveJourneyToState = (content: any) => ({
    type: SAVE_JOURNEY,
    payload: {...content}
})

export const saveInitialJourneyToState = (content: Journey) => ({
    type: CREATE_JOURNEY,
    payload: {...content}
})


export const resetJourney = (journeyUtilityType: JourneyUtilityType) => ({
    type: RESTART_JOURNEY,
    payload: journeyUtilityType
})