import { useContext, useEffect, useState } from 'react';
import { AddressSearchType } from '../../../models/address.model';
import { FuelType } from '../../../models/utility-detail';
import { AppData } from '../../../providers/AppData';
import styled from "styled-components";


interface InputTypeBtnProps {
  fuelType: number;
  elecType: AddressSearchType;
  gasType: AddressSearchType;
  changeType: (type: AddressSearchType, inputFuelType: number) => void;
}

const InputTypeBtn = ({
  fuelType,
  elecType,
  gasType,
  changeType,
}: InputTypeBtnProps) => {
  const { labels } = useContext(AppData);

  return (
    <>
      {fuelType === FuelType.Electricity && (
        <button
          onClick={() => changeType(elecType, fuelType)}
          type={'button'}
          className={'text-button'}
        >
          {elecType === AddressSearchType.Postcode
            ? labels.supply_address_change_to_mpan ?? 'Change to MPAN'
            : labels.supply_address_change_to_postcode ?? 'Change to Postcode'}
        </button>
      )}
      {fuelType === FuelType.Gas && (
        <button
          onClick={() => changeType(gasType, fuelType)}
          type={'button'}
          className={'text-button'}
        >
          {gasType === AddressSearchType.Postcode
            ? labels.supply_address_change_to_mprn ?? 'Change to MPRN'
            : labels.supply_address_change_to_postcode ?? 'Change to Postcode'}
        </button>
      )}
    </>
  );
};

export default InputTypeBtn;
