// Lib
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps } from "react-router";

// Components
import Spinner from "../components/icons/Spinner";

// Actions
import { loadJourney, TypeOfLoad } from "../stores/actions/loadJourney";
import { getSuppliers } from "../stores/actions/getSuppliers";
import { analyticsPageView } from "../stores/analytics/actions";

const mapStateToProps = (state: any) => state;
const dispatchProps = { loadJourney, getSuppliers, analyticsPageView };
const connector = connect<any, typeof dispatchProps>(mapStateToProps, dispatchProps);

class LoadingJourney extends React.Component<ConnectedProps<typeof connector> & RouteComponentProps & { loadRoute: string, typeOfLoad: TypeOfLoad }> {

    public componentDidMount = (): void => {
        this.props.analyticsPageView(window.location.pathname);

        const id = new URLSearchParams(this.props.location.search).get('id')
        if(id){
            Promise.all([
                this.props.loadJourney(id, this.props.typeOfLoad),
                this.props.getSuppliers()
            ])
            .then(() => {
                this.props.history.push(this.props.loadRoute ?? "/results");
            })
            .catch((err : any) => {
                console.warn(err);
                this.props.history.push("/");
            });
        } else {
            this.props.history.push("/");
        }
    }

    public render = (): JSX.Element => {
        return (
            <div className={"loading-journey"}>
                <div className={"loading-journey__inner"}>
                    <Spinner />
                    <h2>Loading Journey</h2>
                </div>
            </div>
        );
    }
}

export default connector(LoadingJourney);