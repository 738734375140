import { MeterDetails } from './journey.model';

export interface LookupMetadata {
    mpan: string | undefined;
    mprn: string | undefined;
    addressId: string;
}

export enum FuelType {
    Electricity,
    Gas
}

export interface IUtilityDetail {
    attributes: { [key: string]: string; };
    fuelType: FuelType;
    meterPoint: string;
    meterDetails: MeterDetails[];
    isRealMeterPoint: boolean;
    isSmartMeter: boolean;
}

export function isElectricityUtilityDetail(detail: IUtilityDetail): boolean {
    return detail.hasOwnProperty('meterTimeswitchClass');
}
export interface UtilityDetail extends IUtilityDetail {
    attributes: { [key: string]: string };
    fuelType: FuelType;
    meterPoint: string;
    profileClass: string;
    meterTimeswitchClass: string;
    energisationStatus: string;
    standardSettlementConfiguration: string;
    lineLossFactorClass: string;
    gridSupplyPoint: string;
    measurementClass: string;
    externalReference: string;
    meterDetails: MeterDetails[];
    isRealMeterPoint: boolean;
}

export function isGasUtilityDetail(detail: IUtilityDetail): boolean {
    return detail.hasOwnProperty('ldz');
}
export interface GasUtilityDetail extends IUtilityDetail {
    attributes: { [key: string]: string };
    fuelType: FuelType;
    meterPoint: string;
    ldz: string;
    gasTransporter: string;
    igtIndicator: boolean;
    gasLargeSiteIndicator: boolean;
    meterDesignation: string;
    meterStatus: string;
    aq: string;
    shipper: string;
    supplier: string;
    matchType: string;
    meterDetails: MeterDetails[];
    isRealMeterPoint: boolean;
}
