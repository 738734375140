// Lib
import React from "react";
import Select, { ActionMeta, ValueType } from "react-select";

// TS Interfaces
import { FilterProps, FiltersState, TermSelectOptions } from "../../interfaces/Filters";
import { AppLayoutData } from "../../interfaces/Journey";

// Icons
import { DropdownChevron } from "../icons/DropdownChevron";
import IconTick from "../icons/IconTick";
import IconGreenEnergy from "../icons/IconGreenEnergy";
import { FeatureToggle } from "../misc/FeatureToggle";

// Providers
import { AppData } from "../../providers/AppData";
import { Quote } from "../../models/quote.model";

import styled from "styled-components";

class Filters extends React.Component<FilterProps, FiltersState>{
    static contextType: React.Context<AppLayoutData> = AppData;

    state : FiltersState = {
        isFastSwitching: false,
        isGreen: false,
        termLength: null,
        filtersOpen: false
    }

    private termOptions: Array<TermSelectOptions> = [
          { value: 0, label: 'Variable'},
          { value: 6, label: '6 Months'},
          { value: 12, label: '12 Months'},
          { value: 24, label: '24 Months' },
          { value: 36, label: '36 Months' },
          { value: 48, label: '48 Months' },
          { value: 60, label: '60 Months' }
    ];

    private getTermOptions = (electricityQuotes: Quote[], gasQuotes: Quote[]) => {
        const termsFromAllQuotes = electricityQuotes
          .concat(gasQuotes)
          .map((quote) => quote.termInMonths);
    
        const uniqueTerms = Array.from(new Set(termsFromAllQuotes)).sort();
    
        const availibleTermOptions = this.termOptions.filter((option) =>
          uniqueTerms.includes(option.value)
        );
    
        return availibleTermOptions;
      };

    private changeFasterSwitching = (ev: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            isFastSwitching : ev.target.checked
        }, this.updateFilters);
    }

    private changeGreenEnergy = (ev: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            isGreen : ev.target.checked
        },  this.updateFilters);
    }

    private changeTermLength = (value: ValueType<TermSelectOptions, boolean>, actionMeta: ActionMeta<TermSelectOptions>) => {
        const option = value as TermSelectOptions;

        this.setState({
            termLength : (actionMeta.action === 'clear') ? null : option.value
        }, this.updateFilters);
    }

    private updateFilters = ():void => {
        if(typeof this.props.filtersUpdated !== 'undefined'){
            this.props.filtersUpdated(this.state);
        }
    }

    private toggleFilters = (ev: React.MouseEvent): void => {
        ev.stopPropagation();
        if(ev.target !== ev.currentTarget) return;
        this.setState({filtersOpen: !this.state.filtersOpen})
    }

    public render = () => {
        const filtersClass = (this.state.filtersOpen) ? "form form--filters open" : "form form--filters";

        return (
            <>
                <button id={"toggle-filters"} type={'button'} onClick={this.toggleFilters} className={"text-button"}>
                    Filter
                </button>
                <div className={filtersClass} onClick={this.toggleFilters}>
                    <div className={"form__group"}>
                        <label className={"form__label"}>{this.context.labels.quotes_page_filters_text ?? 'Filter Term:'}</label>
                        <Select options={this.getTermOptions(this.props.gasQuotes, this.props.electricityQuotes)}
                                isClearable={true}
                                isSearchable={false}
                                onChange={this.changeTermLength}
                                components={{ ...DropdownChevron }}
                                placeholder={"Term length"}
                                className={"styled-select"}
                                classNamePrefix={"styled-select"} />
                    </div>
                    <div className={"form__group"}>
                        <span className={"form__label"}>{
                            this.context.toggles.show_filter_faster_switching || 
                            this.context.toggles.show_green_energy_filter 
                            ? "Filter Options" 
                            : <></>}
                        </span>
                        <div className={"checkboxes"}>
                            <FeatureToggle
                                name={"show_filter_faster_switching"}
                                fallback={true}
                                render={
                                    <div className={"checkbox"}>
                                        <input type={"checkbox"} id={"faster-switching"} onChange={this.changeFasterSwitching}/>
                                        <label htmlFor={"faster-switching"}>
                                        <div className={"dummy"}>
                                            <IconTick wrapperClass={"dummy__tick"}/>
                                        </div>
                                        Faster Switching
                                        </label>
                                    </div>
                                }
                                otherwiseRender={<></>}
                            />
                            <FeatureToggle
                                name={"show_green_energy_filter"}
                                fallback={true}
                                render={
                                <div className={"checkbox"}>
                                    <input type={"checkbox"} id={"green-energy"} onChange={this.changeGreenEnergy} />
                                    <label htmlFor={"green-energy"}>
                                        <div className={"dummy"}>
                                            <IconTick wrapperClass={"dummy__tick"}/>
                                        </div>
                                        Green Energy
                                        <span className={"green-energy"}><IconGreenEnergy /></span>
                                </label>
                            </div>
                            }
                            otherwiseRender={<></>}
                            />
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Filters;