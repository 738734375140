// Lib
import React from "react";
import { addressFormat } from "../../lib/addressFormat";
// TS Definitions
import { Address, AddressPlateFunctions } from "../../models/address.model";

// Icon
import IconEdit from "../icons/IconEdit";
import { FuelType } from "../../models/utility-detail";
import { hidePartialMpxnString } from "../../lib/string-replacement";

class AddressPlate extends React.Component<Address & AddressPlateFunctions>{
    public clearAddress = (e: React.MouseEvent<HTMLButtonElement>) : void => {
        if (this.props.onChangeAddress) {
            this.props.onChangeAddress(e);
        }
    }

    public render = (): JSX.Element => {
        const isElec = this.props.meterType === FuelType.Electricity;
        let mpxnLabel = 'UDPRN: ';
        if (this.props.mpxn.length) {
            mpxnLabel = isElec ? 'MPAN: ' : 'MPRN: ';
        } 
        const mpxnValue = this.props.mpxn.length ? this.props.mpxn : this.props.udprn;

        return (
            <div className={"address-plate"}>
                <h5 className={"address-plate__number"}>
                    {addressFormat(this.props, true)}
                </h5>
                <button className={"icon-button address-plate__edit"} type={"button"} onClick={ this.clearAddress.bind(this) }>
                    <span>Edit</span>
                    <IconEdit />
                </button>
                <p className={"address-plate__utility"}>{mpxnLabel}{hidePartialMpxnString(mpxnValue)}</p>
            </div>
        );
    }
}

export default AddressPlate;