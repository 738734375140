import { CHANGED_FROM_DUAL_FUEL } from "../actions/types";

export default function ChangedFromDualFuel(state: boolean = false, action: any) {
  switch (action.type) {
    case CHANGED_FROM_DUAL_FUEL: {
      return action.payload;
    }

    default:
      return state;
  }
}
