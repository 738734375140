// Lib
import React from "react";

// Icons
import GasIcon from "../icons/IconGas";
import ElectricityIcon from "../icons/IconElectricity";
import DualFuelIcon from "../icons/IconBoth";

// Providers
import { AppData } from "../../providers/AppData";

// TS Definitions
import { JourneyUtilityType } from "../../models/journey.model";
import { FormInput } from "../../interfaces/Form";
import { SupplyTypes } from "../../interfaces/SupplyType";
import { AppLayoutData } from "../../interfaces/Journey";
import { AppState } from "../../stores/store";
import { resetJourney } from "../../stores/actions/saveJourneyToState";
import { connect, ConnectedProps } from "react-redux";

const formatOptionForTestId = (option: string) => {
  const formattedOption = option.toLowerCase();
  if (formattedOption === "dualfuel") {
    return "dual_fuel";
  }
  return formattedOption;
};

const mapStateToProps = (state: AppState) => state;
const dispatchProps = { resetJourney };
const connector = connect(mapStateToProps, dispatchProps);

class SupplyType extends React.Component<ConnectedProps<typeof connector> & FormInput>{
    static contextType: React.Context<AppLayoutData> = AppData;

    private types: SupplyTypes[] = ["Electricity", "Gas", "DualFuel"];

    public checkGroupState = (name: string, value: number) => {
        this.props.resetJourney(value)
        this.props.form?.setFieldValue(name, value);
        this.props.updateGroupStatus(value !== null);
    }

    public componentDidMount = () => {
        setTimeout(() => this.props.updateGroupStatus(this.props.field?.value !== null), 0);
    }

    public renderLabelContent = (serviceType: JourneyUtilityType): JSX.Element => {
        switch(serviceType){
            case JourneyUtilityType.Electricity :
                return (
                    <>
                        <ElectricityIcon wrapperClass={"radio-button__icon"} />
                        <span>{ this.context.labels.supply_type_selection_electricity || "Electricity" }</span>
                    </>
                );
            case JourneyUtilityType.Gas :
                return (
                    <>
                        <GasIcon wrapperClass={"radio-button__icon"} />
                        <span>{ this.context.labels.supply_type_selection_gas || "Gas" }</span>
                    </>
                );
            case JourneyUtilityType.DualFuel :
                return (
                    <>
                        <DualFuelIcon wrapperClass={"radio-button__icon"} />
                        <span>{ this.context.labels.supply_type_selection_electricity_and_gas || "Gas & Electric" }</span>
                    </>
                );
        }
    }

    public render = (): JSX.Element => {
        return (
            <>
                <label htmlFor={this.props.field?.name} className={"form__label"}>
                    {this.context.labels.supply_type_selection_title}
                </label>
                <div className={"radio-group"}>
                    {this.types.map((option, idx) => {
                        return (
                            <div
                                key={`${this.props.field?.name}-${option}`} 
                                className={"radio-button radio-button--with-image"} 
                                data-testid={`${this.props.field?.name}-${formatOptionForTestId(option)}`}
                            >
                                <input name={this.props.field?.name}
                                       id={`${this.props.field?.name}-${option}`}
                                       onChange={() => this.checkGroupState(this.props.field?.name || '', JourneyUtilityType[option])}
                                       type={"radio"}
                                       checked={this.props.field?.value === JourneyUtilityType[option]}
                                       value={JourneyUtilityType[option]} />
                                <label htmlFor={`${this.props.field?.name}-${option}`}>
                                    { this.renderLabelContent(JourneyUtilityType[option]) }
                                </label>
                            </div>
                        );
                    })}
                </div>
            </>
        )
    }
}

export default connector(SupplyType);