// Lib
import React from "react";
import { Link } from "react-router-dom";

// Providers
import { AppData } from "../../providers/AppData";

// TS Interfaces
import { AppLayoutData } from "../../interfaces/Journey";

class NoResults extends React.Component{
    static contextType: React.Context<AppLayoutData> = AppData;

    public render = (): JSX.Element => {
        return (
            <div className={"quote-list"} >
                <h2 className={"quote-list__title"}>
                    <span> {this.context.labels.no_quotes_error_title}</span>
                </h2>
                <div className={"notice"}>
                    <p>{this.context.labels.no_quotes_error_content}</p>
                    <Link to={"/"}>Go back to quote builder</Link>
                </div>
            </div>
        )
    }
}

export default NoResults;