import {REMOVE_STAGE, UPDATE_STAGE, UPDATE_STAGES} from "../actions/types";
import { AppStages } from "../../models/journey.model";

export default function CompletedStages(state: AppStages[] = [], action: any){
    switch (action.type){
        case UPDATE_STAGE : {
            return Array.from(new Set([...state, action.payload]));
        }

        case UPDATE_STAGES : {
            return Array.from(new Set([...state, ...action.payload]));
        }

        case REMOVE_STAGE : {
            return state.filter((stage: AppStages) => stage !== action.payload )
        }

        default:
            return state;
    }
}