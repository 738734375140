import React from "react";
import { StackEnvironments } from "../../../aws/configuration/config-types";
import { environment } from "../../lib/environment";

type ToggleProps = {
  render: JSX.Element;
  otherwiseRender: JSX.Element;
};

type EnvironmentToggleProps = {
  name: keyof StackEnvironments;
  fallback: boolean;
} & ToggleProps;

export const IfCustomerPortal: React.FunctionComponent<ToggleProps> = ({
  render,
  otherwiseRender,
}) => {
  return (
    <EnvironmentToggle
      name={"REACT_APP_IS_CUSTOMER_PORTAL"}
      fallback={false}
      render={render}
      otherwiseRender={otherwiseRender}
    />
  );
};

export const EnvironmentToggle: React.FunctionComponent<EnvironmentToggleProps> = ({
  name,
  fallback,
  render,
  otherwiseRender,
}) => {
  const shouldRender = environment?.[name] || fallback;
  return shouldRender ? render : otherwiseRender;
};
