// Lib
import React from "react";
import { Field, Form, FormikProps, withFormik } from "formik";

// Yup Schema
import { saveQuotesSchema } from "../../schemas/SaveQuoteSchema";

// TS Interfaces
import { Journey } from "../../models/journey.model";
import { SaveQuoteFormProps } from "../../interfaces/Modals";

//Icons
import Spinner from "../icons/Spinner";

class SaveQuoteForm extends React.Component<SaveQuoteFormProps & FormikProps<Journey>>{
    public checkKeyInput = (event: React.KeyboardEvent<HTMLInputElement>): void|boolean => {
        const utilKeys = ["Backspace", "Delete", "+", "Tab", "CapsLock", "ArrowRight", "ArrowLeft", "ArrowDown", "ArrowUp"];
        if(!/^\d+$/.test(event.key) && utilKeys.indexOf(event.key) === -1){
            event.preventDefault()
        }

        return true;
    }

    public render = () => {
        return (
            <Form className={"form form--details"}>
                <div className={
                    this.props.errors.contactInformation?.email && this.props.touched.contactInformation?.email
                        ? 'form__group invalid'
                        : "form__group"
                }>
                    <label className={"form__label"}>Title</label>
                    <Field type="email"
                           name="contactInformation.email"
                           className={"form__control"}
                           placeholder={"Enter your email address (required)"} />
                    {this.props.errors.contactInformation?.email && this.props.touched.contactInformation?.email ? (
                        <span className={"form__error"}>{this.props.errors.contactInformation?.email}</span>
                    ): null}
                </div>
                <div className={
                    this.props.errors.contactInformation?.phoneNumber && this.props.touched.contactInformation?.phoneNumber
                        ? 'form__group invalid'
                        : "form__group"
                }>
                    <label className={"form__label"}>Title</label>
                    <Field type={"text"}
                           inputMode={"numeric"}
                           maxLength="13"
                           name="contactInformation.phoneNumber"
                           className={"form__control"}
                           placeholder={"Enter your phone number (optional)"} />
                    {this.props.errors.contactInformation?.phoneNumber && this.props.touched.contactInformation?.phoneNumber ? (
                        <span className={"form__error"}>{this.props.errors.contactInformation?.phoneNumber}</span>
                    ): null}
                </div>

                <div className={"form__actions"}>
                    <button type={"submit"}
                            className={"button"}
                            disabled={!this.props.isValid || this.props.isSubmitting}
                            data-loading={(this.props.isSubmitting ? 'true' : '')} >
                        <Spinner wrapperClass={"button__spinner"}/>
                        Save quote
                    </button>
                </div>
            </Form>
        );
    }
}

export default withFormik<SaveQuoteFormProps, Journey>(
    {
        validationSchema: saveQuotesSchema,
        validateOnMount: true,
        mapPropsToValues: props => {
            return props.journey;
        },
        handleSubmit: (values: Journey, { props, setSubmitting }) => {
            setSubmitting(true);

            if(props.onSubmitted !== null){
                props.onSubmitted(values).then((savedRouteId: string) => {
                    setSubmitting(false);

                    if(props.onCompleted !== null){
                        props.onCompleted(savedRouteId);
                    }
                });
            }
        }
    }
)(SaveQuoteForm);