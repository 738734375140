import { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import CompanyType, { CompanyTypeProps } from "./CompanyType";
import { CompanyLookUpProps } from "./CompanyLookup/CompanyLookup";
import CompanyLookupWrapper from "./CompanyLookup";
import { FormInput } from "../../interfaces/Form";
import { Company } from "../../models/company.model";
import { useSelector } from "react-redux";
import { AppState } from "../../stores/store";
import { JourneyCompanyType } from "../../interfaces/CompanyType";

type CompanyDetailsProps = FormInput;

const FieldWrapper = styled.div`
  padding-top: 25px;
  padding-bottom: 25px;
`;

const CompanyDetails: FunctionComponent<CompanyDetailsProps> = (props) => {
  const supplyCompany = useSelector<AppState, Company>(state => state.journey.supplyCompany);
  const companyType = useSelector<AppState, JourneyCompanyType | undefined>(state => state.journey.companyType);

  const [isTypeComplete, setTypeComplete] = useState(companyType !== undefined);
  const [isLookupComplete, setLookupComplete] = useState(!!supplyCompany?.name);

  useEffect(() => {
    props.updateGroupStatus(isTypeComplete && isLookupComplete);
  }, [isTypeComplete, isLookupComplete]);

  const companyTypeProps: CompanyTypeProps = {
    name: "companyType",
    companyType: props.form.values.companyType,
    handleSubmit: (value) => {
      props.form?.setFieldValue("companyType", value);
      props.form?.setFieldValue("supplyCompany", {} as Company);
      props.updateGroupStatus(false);
      setTypeComplete(typeof value !== "undefined");
    },
  };

  const companyLookupProps: CompanyLookUpProps = {
    name: "supplyCompany",
    companyType: props.form.values.companyType,
    supplyCompany: props.form.values.supplyCompany,
    handleSubmit: (value: Company) => {
      props.form.setFieldValue("supplyCompany", value);
      props.updateGroupStatus(isTypeComplete && typeof value !== "undefined" && Object.keys(value).length > 0);
      setLookupComplete(typeof value !== "undefined" && Object.keys(value).length > 0);
    },
  };

  return (
    <>
      <FieldWrapper>
        <CompanyType {...companyTypeProps} />
      </FieldWrapper>
      <FieldWrapper>
        {isTypeComplete && (
          // Key in CompanyLookupWrapper is used to rerender the component on companyType change
          <CompanyLookupWrapper
            {...companyLookupProps}
            key={props.form.values.companyType}
          />
        )}
      </FieldWrapper>
    </>
  );
};

export default CompanyDetails;
