// Lib
import React from "react";

// Icons
import Spinner from "../icons/Spinner";

class LoadingPlate extends React.Component{
    public render = () : JSX.Element => {
        return (
            <div className={"loading-plate"}>
                <Spinner stroke={"#2E435B"} duration={"0.8s"} wrapperClass={"loading-plate__spinner"}/>
            </div>
        )
    }
}

export default LoadingPlate;