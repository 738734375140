export const testValidPhone = (value: string): boolean => {
    if(typeof value ==="undefined" || !value.length)
        return true;

    const landlineRegex = /^((\(?0\d{4}\)?\s?\d{3}\s?\d{3})|(\(?0\d{3}\)?\s?\d{3}\s?\d{4})|(\(?0\d{2}\)?\s?\d{4}\s?\d{4}))(\s?#(\d{4}|\d{3}))?$/;
    const mobileRegex = /^(\+44\s?7\d{3}|\(?07\d{3}\)?)\s?\d{3}\s?\d{3}$/;

    let isValidLandline = landlineRegex.test(value);
    let isValidMobile = mobileRegex.test(value);

    return (isValidMobile || isValidLandline);
}