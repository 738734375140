import { useContext } from "react";
import { AppData } from "../../providers/AppData";
import { RichText } from "prismic-reactjs";
import styled from "styled-components";

const Button = styled.button`
  margin-top: 2rem;
  align-self: center;
`;

const TextHeader = styled.h3`
  text-align: center;
`;

const TextParagraph = styled.p`
  text-align: center;
`;

const ContactSalesEnding = () => {
  const { labels } = useContext(AppData);
  return (
    <>
      <TextHeader>{labels.contact_modal_ending_text_header}</TextHeader>
      <TextParagraph>
        {RichText.render(labels.contact_modal_ending_text)}
      </TextParagraph>
      <Button
        type={"button"}
        className={"button"}
        onClick={() => window.location.href = '/'}
      >
        <span>{labels.contact_modal_ending_button_text}</span>
      </Button>
    </>
  );
};

export default ContactSalesEnding;
