export class Regexes {
    public static readonly POSTCODE = /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?)))) *?[0-9][A-Za-z]{2}) *$/;
    public static readonly COMPANY_NUMBER = /^(OC|SC|SO|NI|NC|[0-9]{2})[0-9]{6}$/;
    public static readonly EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    public static readonly PHONE_NUMBER = /^(?:0|\+?44)(?:\d\s?){9,10}$/;
    public static readonly MOBILE_PHONE_NUMBER = /^(?:0|\+?44)(?:\d\s?){10}$/;
    public static readonly BANK_ACCOUNT_NAME = /^[A-z .'-]+$/;
    public static readonly PASSWORD = /^(?=.*[A-Z])(?=.*[!@#$&*%?`~=:;|{}[\]()_-])(?=.*[0-9])(?=..*[a-z]).{8,}$/;
    public static readonly MONEY = /^[0-9]+(\.[0-9]{2})?$/;
}
