import Select from 'react-select';
import styled from 'styled-components';

const Form = styled.form``;
const Input = styled.input`
  border-color: var(--accent);
  border-width: 0.2rem;
  border-radius: 0.4rem;
  padding: 1rem;
  margin-right: 0.5rem;
  border-radius: 0.4rem;
  background-color: transparent;
  border: 0.2rem solid var(--accent);
  color: var(--accent);
  font-size: 2rem;
  line-height: 3rem;
  width: 84%;
  max-width: 34.5rem;
  text-align: left;
  padding: 1.3rem 2rem;
  outline: none !important;
  font-family: var(--font-family);
`;

const Button = styled.button`
  cursor: pointer;

  background-color: var(--button-primary);
  color: var(--button-primary__text);

  appearance: none;
  box-shadow: none;

  border: none;
  border-radius: 0.4rem;

  line-height: 2.1rem;
  font-family: var(--font-family);
  font-size: 2rem;
  padding: 1rem 1rem;
  position: absolute;
  min-width: 60px;
  min-height: 60px;

  transition: background-color 0.2s linear, opacity 0.2s linear;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  &:hover,
  &:focus {
    background-color: var(--button-primary--hover);
    color: var(--button-primary--hover__text);
  }
`;

const StyledSelect = styled(Select)`
  max-width: 410px;
  margin-top: 1rem;
`;

const Loading = styled.div`
  margin: 0 auto;
  text-align: center;
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid var(--accent);
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Error = styled.span`
  display: block;
`;

interface LookupFormWithSelectListProps {
  value: string;
  onChange: any;
  onClick: any;
  placeholder: string;
  isLoading: boolean;
  isError: string | undefined;
  defaultOptions: any[];
  selectListOnChange: any;
  selectPlaceholder: string;
}

const LookupFormWithSelectList = ({
  value,
  onChange,
  onClick,
  placeholder,
  isLoading,
  isError,
  defaultOptions,
  selectListOnChange,
  selectPlaceholder,
}: LookupFormWithSelectListProps) => {
  return (
    <>
      <Form>
        <Input
          type="text"
          value={value}
          onChange={onChange}
          placeholder={placeholder}
        />
        <Button type="button" onClick={onClick}>
          {isLoading ? <Loading /> : 'Go'}
        </Button>
        {isError && <Error>{isError}</Error>}
      </Form>
      {defaultOptions.length > 0 && !isError && !isLoading && (
        <>
          <StyledSelect
            options={defaultOptions}
            onChange={selectListOnChange}
            placeholder={selectPlaceholder}
          />
        </>
      )}
    </>
  );
};

export default LookupFormWithSelectList;
