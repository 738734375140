import {Styles} from "react-joyride";

export const TOUR_STYLE : Styles = {
     options: {
         zIndex: 10000,
     },
     overlay: {
         backgroundColor: "rgba(41, 43, 46, 0.7)",
         mixBlendMode: "darken"
     },
     spotlight: {
         backgroundColor: "white"
     },
     tooltipFooter: {
         display: "none"
     },
     buttonNext: {
         display: "none"
     },
     buttonBack: {
         display: "none"
     },
     buttonClose: {
         display : "none"
     },
     tooltip: {
         opacity: "1",
         padding: "2.3rem 2rem",
         fontSize: "1.6rem",
         lineHeight: "2.4rem",
         borderRadius: "1.5rem",
         maxWidth: "58rem",
         backgroundColor: "white",
         color: "var(--accent)",
         textAlign: "center"
     },
     tooltipContent: {
        padding: "0"
     },
}

export const FLOATER_PROPS = {
    disableAnimation: true,
    styles: {
        arrow: {
            length: 8,
            spread: 20,
        }
    }
}