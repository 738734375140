import { Journey, JourneyForm, JourneyUtilityType } from "../models/journey.model";
import { SupplierConfig } from "../models/supplier-config.model";
import { CompanyByNumber, CreditDetails, CreditScore, SearchCompanies } from "../models/company.model";
import { Address, AddressDetail } from "../models/address.model";
import { QuoteServiceResponse } from "../models/quote-service-response.model";
import { ContactSalesRequest } from "../interfaces/HalfHour";
import { SwitchResponse } from "../models/switch-response.model";
import { PurchaseEventData } from "../models/purchase-event.model";
import { environment } from "./environment";
import { createQueryParamString } from "./createQueryParamString";
import { TypeOfSave } from "../models/typeOfSave";
import { DirectDebit, ValidateBankDetailsResponse } from "../models/direct-debit.model";

class API {
  //"https://2jvlwqz3vh.execute-api.eu-west-2.amazonaws.com/Prod/api"
  private static API_BASE: string = environment.REACT_APP_API_URL ?? "";

  public static createJourney(stackName: string): Promise<Journey> {
    return API._makeRequest("journey/b2b", "POST", {
      stackName
    });
  }

  public static validateBankDetails(
      bankDetails: DirectDebit, 
      supplierReference: string
  ): Promise<ValidateBankDetailsResponse>{
    return API._makeRequest(`bank/${supplierReference}`, 'POST', {
      bankDetails
    });
  }
 
  public static updateJourney(journey: JourneyForm): Promise<boolean> {
    return API._makeRequest(`journey`, "PUT", journey as {});
  }

  public static getCompanyByName(companyName: string): Promise<SearchCompanies> {
    return API._makeRequest(`company/search?name=${companyName}`, "GET", {});
  }

  public static getCompanyByNumber(companyNumber: string): Promise<CompanyByNumber> {
    return API._makeRequest(`company/${companyNumber}`, "GET", {});
  }

  public static getAddressByPostCode(
    postcode: string,
    utilityType: JourneyUtilityType | null = null
  ): Promise<Address[]> {
    let url = `addresslookup/${postcode}`;

    if (utilityType !== null) {
      url += `?utilityType=${utilityType}`;
    }

    return API._makeRequest(url, "GET", {});
  }
  public static getAddressByMPAN(
    mpan: string,
    utilityType: JourneyUtilityType | null = null
  ): Promise<Address[]> {
    if (utilityType === JourneyUtilityType.Electricity || utilityType === JourneyUtilityType.DualFuel) {
      return API._makeRequest(`addresslookup/mpan/${mpan}`, "GET", {});
    }

    if (utilityType === JourneyUtilityType.Gas) {
      return API._makeRequest(`addresslookup/mprn/${mpan}`, "GET", {});
    }

    return Promise.resolve([]);
  }

  public static getQuotes(
    journeyUid: string,
    utilityType: JourneyUtilityType
  ): Promise<QuoteServiceResponse> {
    return API._makeRequest(`quote/b2b/${journeyUid}/${utilityType}`, "GET", {});
  }

  public static creditCheckRegistered(businessReference: string): Promise<CreditScore> {
    return API._makeRequest(
      `company/credit-check?businessReference=${businessReference}`,
      "GET",
      {}
    );
  }

  public static creditCheckNonRegistered(
    businessReference: string
  ): Promise<CreditDetails> {
    return API._makeRequest(
      `company/credit-check-non-registered?businessReference=${businessReference}`,
      "GET",
      {}
    );
  }

  public static getSoleTraderByName(
    companyName: string,
    postcode: string = ""
  ): Promise<SearchCompanies> {
    let url = `company/search-sole-trader?name=${companyName}`;

    if (typeof postcode !== "undefined" && postcode.length)
      url += `&postCode=${postcode}`;

    return API._makeRequest(url, "GET", {});
  }

  public static getSuppliers(): Promise<SupplierConfig[]> {
    return API._makeRequest(`supplierConfig`, "GET", {});
  }

  public static saveQuotes({
    uid,
    contactInformation,
  }: Pick<Journey, "uid" | "contactInformation">): Promise<string> {
    const query = createQueryParamString({
      email: contactInformation.email,
      phone: contactInformation.phoneNumber
    })

    return API._makeRequest(
      `journey/generate-param/b2b/${uid}?${query}`,
      "GET",
      {}
    );
  }

  public static saveQuoteEmail({
    uid,
    contactInformation,
    typeOfSave
  }: Pick<Journey, "uid" | "contactInformation"> & { typeOfSave: TypeOfSave }): Promise<boolean> {
    const query = createQueryParamString({
      email: contactInformation.email,
      phone: contactInformation.phoneNumber,
      typeOfSave: typeOfSave
    })

    return API._makeRequest(
      `email/send-saved-journey/b2b/${uid}?${query}`,
      "GET",
      {}
    );
  }

  public static getJourneyByPublicParam(publicId: string): Promise<JourneyForm> {
    return API._makeRequest(`journey/saved-journey?id=${publicId}`, "GET", {});
  }

  public static loadJourney(id: string): Promise<JourneyForm> {
    const stackName = environment.REACT_APP_STACK_NAME ?? 'unknown';
    return API._makeRequest(`loadjourney?id=${id}`, "POST", {
      stackName
    });
  }

  public static contactSalesRep(data: ContactSalesRequest): Promise<boolean> {
    return API._makeRequest(`email/contact-sales`, "POST", data);
  }

  public static switch(journeyUid: string): Promise<SwitchResponse[]> {
    return API._makeRequest(`switch/b2b/${journeyUid}`, "POST", {});
  }

  public static checkJourneyIsComplete(journeyUid: string): Promise<boolean> {
    return API._makeRequest(`journey/is-complete/b2b/${journeyUid}`, "GET", {});
  }

  public static completeJourney(journeyUid: string): Promise<boolean> {
    return API._makeRequest(`journey/complete/b2b/${journeyUid}`, "PUT", {});
  }

  public static getPurchaseData(journeyUid: string): Promise<PurchaseEventData> {
    return API._makeRequest(`journey/purchase/b2b/${journeyUid}`, "GET", {});
  }

  public static async getIpAddress(): Promise<string> {
    return API._makeRequest("ipaddress", "GET", {});
  }

  public static async addressLookup(postcode: string): Promise<Array<Address>> {
    return API._makeRequest(`addresslookup/${postcode}`, "GET", {});
  }

  private static wait(delay:number){
    return new Promise((resolve) => setTimeout(resolve, delay));
  }

  private static fetchRetry(url:string, delay:number, tries:number, fetchOptions = {}): Promise<any> {
    
    function onError(err:any): Promise<any>{
      const triesLeft = tries - 1;
      
      if(!triesLeft){
        throw err;
      }

      console.log(`Try ${triesLeft} - Failed to fetch retrying....`);

      return API.wait(delay)?.then(() => API.fetchRetry(url, delay, triesLeft, fetchOptions));
    }

    return fetch(url, fetchOptions).catch(onError);
  }


  private static _makeRequest(url: string, type: string, data: {}): Promise<any> {
    data = data || {};

    return API.fetchRetry(`${API.API_BASE}/${url}`, 1000, 3, {
      method: type || "GET",
      redirect: "error",
      mode: "cors",
      referrer: "no-referrer",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
      body: type !== "GET" ? JSON.stringify(data) : null,
    }).then((response) => {
        if (response.ok) {
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then((json : any) => {
              return Promise.resolve(json);
            });
          } else {
            return response.text().then((text : string) => {
              return Promise.resolve(text);
            });
          }
        } else {
          return Promise.reject(response);
        }
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
}

export default API;
