import {AppLayoutData, JourneyPageLayout} from "../interfaces/Journey";
import {getAppStageByComponentType} from "./getAppStageByComponentType";
import {FormInput} from "../interfaces/Form";
import { RichTextBlock } from "prismic-reactjs";

export const formatAppData = (appContent : any): AppLayoutData => {
    let appData = appContent.results[0].data;

    appData.pages = appData.pages.map((page: { components: any[]; }) => ({
        ...page, 
        components: page.components.map((component: any) => ({type: component.text}) as any)
    }))

    appData.detailsPage = appData.pages.pop();
    appData.detailsPage.includedSteps = appData.detailsPage.components.map((component: FormInput) => {
        return getAppStageByComponentType(component.type);
    });

    appData.pages = appData.pages.map((page: JourneyPageLayout, idx: number): JourneyPageLayout => {
        page.pageIndex = idx;
        page.isLastPage = (typeof appData.pages[(idx + 1)] === 'undefined') || false;
        page.isFirstPage = (typeof appData.pages[(idx - 1)] === 'undefined') || false;
        page.nextPage = (appData.pages[(idx + 1)]?.route && !page.isLastPage) ? `/journey/${appData.pages[(idx + 1)]?.route}`: '';
        page.prevPage = (appData.pages[(idx - 1)]?.route && !page.isFirstPage) ? `/journey/${appData.pages[(idx - 1)]?.route}`: '';
        page.prevPageText = (appData.pages[(idx - 1)]?.route && !page.isFirstPage) ? `Back to ${appData.pages[(idx - 1)]?.name}`: '';
        page.includedSteps = page.components.map((component: FormInput) => {
            return getAppStageByComponentType(component.type);
        });
        return page;
    });

    appData.labels = appData.text[0] || {};
    appData.branding = appData.branding[0] || {};
    appData.toggles = appData.toggles[0] || {};
    appData.variables = appData.variables[0] || {};

    Object.keys(appData.branding).forEach((key: string) => {
        if(key === "logo"){
            appData.logoURL = (appData.branding[key] as any).url;
        }
        if(key === "favicon"){
            appData.faviconURL = (appData.branding[key] as any).url;
        }
    });

    const prismicSlice = (appData as PrismicSlice).body ?? []
    const slices: ListOptions = {
        mandatory_legal_options: prismicSlice.find(
            ({ primary: { name_of_object = '' } }) => 
                name_of_object === 'mandatory_legal_options'
        )?.items ?? [],
        optional_legal_options: prismicSlice.find(
            ({ primary: { name_of_object = '' } }) => 
                name_of_object === 'optional_legal_options'
        )?.items ?? []
    }
    appData.listOptions = slices;

    return appData;
}

export type ListOptions = {
    mandatory_legal_options: Array<LegalOptionsSliceItem>
    optional_legal_options: Array<LegalOptionsSliceItem>
}

export type PrismicSlice = {
    body: Array<PrismicSliceBody>
}

export type PrismicSliceBody = {
    slice_type: string,
    slice_label: string | null | undefined,
    primary: LegalOptionsSlice,
    items: Array<LegalOptionsSliceItem>
}

export type LegalOptionsSlice = {
    name_of_object: keyof ListOptions
}

export type LegalOptionsSliceItem = {
    key: string
    value: RichTextBlock[]
}