import { ThunkDispatch } from "redux-thunk";
import { Action } from 'redux';
import {VIEWED_TOUR} from "./types";

type MyRootState = {};
type MyExtraArg = undefined;
type MyThunkDispatch = ThunkDispatch<MyRootState, MyExtraArg, Action>;

const viewedTour = (state: boolean) => ({
    type: VIEWED_TOUR,
    payload: state
});

export const toggleViewedTour = (state: boolean) => {
    return (dispatch: MyThunkDispatch) => Promise.resolve(dispatch(viewedTour(state)));
}