import { ThunkDispatch } from "redux-thunk";
import { Action } from 'redux';
import {UPDATE_STAGE, REMOVE_STAGE, UPDATE_STAGES} from "./types";
import {AppStages} from "../../models/journey.model";

type MyRootState = {};
type MyExtraArg = undefined;
type MyThunkDispatch = ThunkDispatch<MyRootState, MyExtraArg, Action>;

const stageUpdate = (stage: AppStages, state: boolean) => ({
    type: (state) ? UPDATE_STAGE : REMOVE_STAGE,
    payload: stage
});

export const updateStage = (stage: AppStages, state: boolean = true) => {
    return (dispatch: MyThunkDispatch) => Promise.resolve(dispatch(stageUpdate(stage, state)));
}

const stagesUpdate = (stages: AppStages[]) => ({
    type: UPDATE_STAGES,
    payload: stages
});

export const updateStages = (stages: AppStages[]) => {
    return (dispatch: MyThunkDispatch) => Promise.resolve(dispatch(stagesUpdate(stages)));
}