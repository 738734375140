import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { FieldProps } from 'formik';
import { useState } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { addressFormat } from '../../../lib/addressFormat';
import API from '../../../lib/API';
import { Address, AddressDetail } from '../../../models/address.model';
import { FuelType } from '../../../models/utility-detail';
import AddressPlate from '../../journey/AddressPlate';
import { Label } from './Label';

const Form = styled.form``;
const Input = styled.input`
  height: 50px;
  border-color: var(--accent);
  border-width: 0.2rem;
  border-radius: 0.4rem;
  padding: 1rem;
  margin-right: 0.5rem;
  border-radius: 0.4rem;
  background-color: transparent;
  border: 0.2rem solid var(--border);
  color: var(--accent);
  font-size: 1.6rem;
  line-height: 1.6rem;
  width: 84%;
  max-width: 34.5rem;
  text-align: left;
  padding: 1.3rem 2rem;
  outline: none !important;
  font-family: var(--font-family);
`;

const Button = styled.button`
  cursor: pointer;

  background-color: var(--button-primary);
  color: var(--button-primary__text);

  appearance: none;
  box-shadow: none;

  border: none;
  border-radius: 0.4rem;

  line-height: 2.1rem;
  font-family: var(--font-family);
  font-size: 2rem;
  padding: 1rem 1rem;
  position: absolute;
  min-width: 50px;
  min-height: 50px;

  transition: background-color 0.2s linear, opacity 0.2s linear;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  &:hover,
  &:focus {
    background-color: var(--button-primary--hover);
    color: var(--button-primary--hover__text);
  }
`;

const StyledSelect = styled(Select)`
  max-width: 410px;
  margin-top: 1rem;
`;

const Loading = styled.div`
  margin: 0 auto;
  text-align: center;
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid var(--accent);
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Error = styled.span`
  display: block;
`;

const Div = styled.div`
  margin-top: 20px;
`;

const searchByPostcodeDebounced = AwesomeDebouncePromise(
  API.getAddressByPostCode,
  500
);

const SoleTraderAddressLookup = ({ label, onChange, field }: any & FieldProps) => {
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState<string | undefined>(undefined);
  const [defaultOptions, setDefaultOptions] = useState<any[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<Address>(
    {} as Address
  );
  const [isAddressSelected, setIsAddressSelected] = useState<boolean>(false);

  const getAddresses = async (inputValue: string): Promise<any> => {
    return await Promise.resolve(searchByPostcodeDebounced(inputValue, 0))
      .then((responses) => {
        if (responses.length > 0) {
          const elecArray: Address[] = responses.filter(
            (address) => address.meterType === FuelType.Electricity
          );
          const showMpan = elecArray.length > 1;
          const ele = elecArray.map<AddressDetail>((address) => ({
            address,
            value: addressFormat(address),
            label: addressFormat(address),
            showMPAN: showMpan,
          }));

          const options = [...ele];
          setDefaultOptions(options);

          return options;
        }
      })
      .catch(() => {
        setIsLoading(false);
        setIsError('Please check your postcode is correct.');
      });
  };

  const getOptions = async (inputValue: string): Promise<any> => {
    if (inputValue.length >= 4 && inputValue.length <= 7) {
      return await getAddresses(inputValue);
    } else {
      setIsError('Please check your postcode is correct.');
      setIsLoading(false);
    }
  };

  const handleInputChange = (event: any) => {
    setInputValue(event.target.value);
  };

  const handleInputSubmit = async () => {
    setIsLoading(true);
    setIsError(undefined);

    if (inputValue.length) {
      const response = await getOptions(inputValue.replace(/\s/g, ''));

      if (!response) {
        if (!isError) {
          setIsError('No results found');
        }
      }
    } else {
      setIsError('Please enter a value');
    }
    setIsLoading(false);
  };

  const handleChange = (selectedOption: any) => {
    const selected = selectedOption as AddressDetail;
    setIsAddressSelected(true);
    setSelectedAddress(selected.address);
    onChange(field.name, selected.address);
  };

  const handleReset = () => {
    setIsAddressSelected(false);
    setSelectedAddress({} as Address);
    onChange(field.name, {} as Address)
  };
  return (
    <Div>
      <Label>{label}</Label>
      {!isAddressSelected ? (
        <>
          {' '}
          <Form>
            <Input
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              placeholder={'Enter postcode'}
            />
            <Button type="button" onClick={handleInputSubmit}>
              {isLoading ? <Loading /> : 'Go'}
            </Button>
            {isError && <Error>{isError}</Error>}
          </Form>
          {defaultOptions.length > 0 && !isError && !isLoading && (
            <>
              <StyledSelect
                options={defaultOptions}
                onChange={handleChange}
                placeholder={'Select your address'}
              />
            </>
          )}
        </>
      ) : (
        <AddressPlate {...selectedAddress} onChangeAddress={handleReset} />
      )}
    </Div>
  );
};

export default SoleTraderAddressLookup;
