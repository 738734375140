// Lib
import { RichText } from 'prismic-reactjs';
import React from 'react';
import styled from 'styled-components';

// TS Interfaces
import { AppLayoutData, BrandingImageData } from '../../interfaces/Journey';

// Providers
import { AppData } from '../../providers/AppData';
import { EnvironmentToggle } from '../misc/EnvironmentToggle';

import TrustpilotWidet from '../misc/TrustpilotWidget';

const StyledHeader = styled.header.attrs((props: {color: string | null}) => props)`
  background: ${(props) => props.color} !important;
`
class Header extends React.Component {
  static contextType: React.Context<AppLayoutData> = AppData;
  context!: React.ContextType<typeof AppData>;

  public render = (): JSX.Element => {
    const logo: BrandingImageData = this.context.branding[
      'logo'
    ] as BrandingImageData;
    const headerRightImage: BrandingImageData = this.context.branding[
      'header_right_image'
    ] as BrandingImageData;
    const headerText =
      this.context.labels.header_right_text
        ?.slice(0, 4)
        ?.filter(({ type, text }) => type === 'list-item' && text) ?? [];

    const backgroundColor = this.context.variables.header_gradient;
    return (
      <StyledHeader id="app-header" color={backgroundColor}>
        <div className={'container-full'}>
          {logo.url && (
            <a
              href={this.context.labels.header_left_image_link ?? '/'}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={logo.url}
                alt={logo.alt ?? ''}
                id={'app-header__logo'}
              />
            </a>
          )}
          <EnvironmentToggle
            name={'REACT_APP_TRUSTPILOT_URL'}
            fallback={false}
            render={<TrustpilotWidet />}
            otherwiseRender={
              <>
                {headerText.length > 0 && RichText.render(headerText)}
                {headerText.length === 0 && headerRightImage.url && (
                  <a
                    href={this.context.labels.header_right_image_link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={headerRightImage.url}
                      alt={headerRightImage.alt ?? ''}
                      id={'app-header__trustpilot'}
                    />
                  </a>
                )}
              </>
            }
          />
        </div>
      </StyledHeader>
    );
  };
}

export default Header;
