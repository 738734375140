/**
 * @param {string} value - The string to modify
 * @param {string} replacer - Character to replace the part of the string with
 * @param {number} startIndex - The zero-based index number indicating the beginning of the substring.
 * @param {number} endIndex - Zero-based index number indicating the end of the substring. The substring includes the characters up to, but not including, the character indicated by end.
 */
export const hidePartOfString = (
  value: string,
  replacer: string,
  startIndex: number,
  endIndex: number
): string => {
  const count = endIndex - startIndex;
  return (
    value.substring(0, startIndex) + replacer.repeat(count) + value.substring(endIndex)
  );
};

export const hidePartialMpxnString = (value: string): string => {
  return value ? hidePartOfString(value, '#', 2, 6) : '';
};
