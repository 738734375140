import React, { FunctionComponent, useEffect, useState, useContext } from "react";
import { InputActionMeta, ValueType } from "react-select";
import CreatableSelect from "react-select/creatable";
import { Modal } from "react-bootstrap";
import IconClose from "../icons/IconClose";

import { Supplier, SupplierConfig } from "../../models/supplier-config.model";
import { AppData } from "../../providers/AppData";
import styled from "styled-components";

const makeSuppliers = (supplierConfigs: Array<SupplierConfig>): Array<Supplier> => {
  const noSupplier: Supplier = {
    name: "None of the above",
    reference: "no_supplier",
  };
  return [
    ...Object.values(supplierConfigs).map((s) => ({
      name: s.name,
      reference: s.reference,
    })),
    noSupplier,
  ];
};

type Option = { label: string; value: Supplier };

export const CurrentSupplierSelection: FunctionComponent<{
  suppliers: Array<SupplierConfig>;
  onSupplierChange: (supplier: Supplier) => void;
  defaultSupplier: Supplier | null | undefined;
  title: string;
  placeholder: string;
}> = (props) => {
  const context = useContext(AppData);
  const [suppliers, setSuppliers] = useState(makeSuppliers(props.suppliers));
  const [formValue, setFormValue] = useState<Option | undefined>(
    props?.defaultSupplier?.name
      ? {
          label: props.defaultSupplier.name,
          value: props.defaultSupplier,
        }
      : undefined
  );
  const [formInput, setFormInput] = useState<string | undefined>(undefined);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setSuppliers(makeSuppliers(props.suppliers));
  }, [props.suppliers]);

  const handleChange = (
    changedSupplier: ValueType<{ label: string; value: Supplier }, false>
  ) => {
    if (changedSupplier) {
      setFormValue(changedSupplier);

      props.onSupplierChange(changedSupplier?.value);
    }
  };

  const handleCreate = (createdSupplier: string) => {
    if (createdSupplier) {
      const newSupplier: Supplier = {
        name: createdSupplier,
        reference: createdSupplier,
      };

      setFormValue({
        label: createdSupplier,
        value: newSupplier,
      });

      props.onSupplierChange(newSupplier);
    }
  };

  const handleInputChange = (input: string, { action }: InputActionMeta) => {
    if (action === "input-blur" && formInput) {
      handleCreate(formInput);
    }
    if (action === "input-change") {
      setFormInput(input);
    }
  };

  return (
    <div>
      <label className={"form__label"}>
        {props.title ?? "Who is your current supplier?"}
        &ensp;
        <button
        onClick={() => setShowModal(true)}
        type={"button"}
        className={"text-button"}
      >
        {context.labels.current_supplier_info_content}
      </button>
      </label>
      {Array.isArray(suppliers) && (
        <CreatableSelect
          options={suppliers.map((s) => ({ label: s.name, value: s }))}
          onChange={(c) => handleChange(c)}
          onCreateOption={(c) => handleCreate(c)}
          onInputChange={(input, event) => handleInputChange(input, event)}
          className={"styled-search"}
          classNamePrefix={"styled-search"}
          value={formValue}
          placeholder={
            props.placeholder ?? "Enter or select your current supplier"
          }
          formatCreateLabel={(label) => label}
        />
      )}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName="feature-modal"
        centered
      >
        <Modal.Body>
          <button
            type={"button"}
            className={"icon-button"}
            onClick={() => setShowModal(false)}
          >
            <IconClose />
            <span>Close Modal</span>
          </button>
          <div>
            <h2>
              {context.labels.current_supplier_info_content}
            </h2>
            <p>{context.labels.current_supplier_info_content_modal}</p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
