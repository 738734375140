import React, { useContext, useState } from "react";
import styled from "styled-components";
import { AppData } from "../../../providers/AppData";
import { RichText } from "prismic-reactjs";

import { CookieConsentImplementationProps } from "./CookieConsentImplementationProps";
import { ModalBackground, Modal, ButtonBase } from "./CookieConsent.styles";
import IconTick from "../../icons/IconTick";
import { breakpoints } from "../../../lib/breakpoints";

const Content = styled.div`
  display: grid;

  grid-template-columns: 1fr; 
  grid-template-rows: auto 1fr auto auto; 
  gap: 2rem 0rem;
  grid-template-areas: 
    "logo"
    "body"
    "button1"
    "button2"; 

  @media ${breakpoints.medium} { 
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr auto;
    gap: 2rem 1rem;
    grid-template-areas:
      "logo logo"
      "body body"
      "button1 button2";
  }
`;

const Text = styled.div`grid-area: body;`;
const Img = styled.img`
  grid-area: logo;
  max-width: 20rem;
`;

const Form = styled.form`
  grid-area: body;

  & > div {
    margin-bottom: 2rem;
  }
`;

const Button = styled(ButtonBase)`
  grid-area: button1;

  background-color: var(--button-primary__text);
  color: var(--button-primary);
  border: 0.1rem solid var(--button-primary);
  transition: background-color .2s linear, opacity .2s linear;

  &:hover,
  &:focus {
    background-color: var(--button-primary--hover__text);
    color: var(--button-primary--hover);
    border: 0.1rem solid var(--button-primary--hover);
  }
`;

const CallToActionButton = styled(ButtonBase)`
  grid-area: button2;

  background-color: var(--button-primary);
  color: var(--button-primary__text);
  border: 0.2rem solid var(--button-primary);
  transition: background-color .2s linear, opacity .2s linear;

  &:hover,
  &:focus {
    background-color: var(--button-primary--hover);
    color: var(--button-primary--hover__text);
    border: 0.1rem solid var(--button-primary--hover__text);
  }
`;

const Details = styled.details`
  border: 1px solid var(--primary);
  padding: 1rem;

  > summary {
    cursor: pointer;
    list-style: none;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

type ModalState = "default" | "manage-preferences";
export const FullScreenCookieConsent: React.FunctionComponent<CookieConsentImplementationProps> =
  ({ onClose }) => {
    const { labels, branding } = useContext(AppData);
    const [state, setState] = useState<ModalState>("default");
    const [checked, setChecked] = useState<boolean>(true);

    return (
      <>
        <ModalBackground />
        <Modal>
          <Content>
            <Img
              src={branding["logo"].url ?? ""}
              alt={branding["logo"].alt ?? ""}
              id={"cookie-consent-image"}
            />
            {state === "default" && (
              <>
                <Text>{RichText.render(labels.cookie_consent_text)}</Text>
                <Button onClick={() => setState("manage-preferences")}>
                  {labels.cookie_consent_manage_button_text ?? "Manage preferences"}
                </Button>
                <CallToActionButton onClick={() => onClose(true)}>
                  {labels.cookie_consent_accept_button_text ?? "Accept and continue"}
                </CallToActionButton>
              </>
            )}
            {state === "manage-preferences" && (
              <>
                <Form>
                  <Text>{RichText.render(labels.cookie_consent_text)}</Text>
                  <Details>
                    <summary>Essential</summary>
                    <p>{labels.cookie_consent_essential_text}</p>
                  </Details>
                  <Details>
                    <summary>
                      Marketing
                      <TickdCheckBox
                        name={"marketing-check-box"}
                        checked={checked}
                        onChange={(value) => setChecked(value)}
                      />
                    </summary>
                    <p>{labels.cookie_consent_marketing_text}</p>
                  </Details>
                </Form>
                <Button onClick={() => onClose(checked)}>
                  {labels.cookie_consent_save_button_text ?? "Save and continue"}
                </Button>
                <CallToActionButton onClick={() => onClose(true)}>
                  {labels.cookie_consent_accept_all_button_text ?? "Accept all"}
                </CallToActionButton>
              </>
            )}
          </Content>
        </Modal>
      </>
    );
  };

type TickdCheckBoxProps = {
  name: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  children?: React.ReactNode;
};
const TickdCheckBox = ({ name, checked, onChange, children }: TickdCheckBoxProps) => {
  return (
    <div className={"checkbox"}>
      <input
        type={"checkbox"}
        id={name}
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
      />
      <label htmlFor={name}>
        <div className={"dummy"}>
          <IconTick wrapperClass={"dummy__tick"} />
        </div>
        {!!children && children}
      </label>
    </div>
  );
};
