import { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import { AppData } from '../providers/AppData';
import IconClose from './icons/IconClose';

const Paragraph = styled.p`
  text-align: center;
`;

interface props {
  showModal: boolean;
  toggleModal: () => void;
}

const ContactAdvisorModal = ({ showModal, toggleModal }: props) => {
  const { variables } = useContext(AppData);
  const telNumber = variables.contact_advisor_number;
  return (
    <Modal
      show={showModal}
      onHide={() => toggleModal()}
      dialogClassName="feature-modal"
      centered
    >
      <Modal.Body>
        <button
          type={'button'}
          className={'icon-button'}
          onClick={() => toggleModal()}
        >
          <IconClose />
        </button>
        <Paragraph>
          <a href={`tel:${telNumber}`}>{telNumber}</a>
        </Paragraph>
      </Modal.Body>
    </Modal>
  );
};

export default ContactAdvisorModal;
