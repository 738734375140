import styled, { keyframes } from 'styled-components';

interface Props {
    delay: string;
}

const loadingAnimation = keyframes`
    0% {opacity: 1;}
    50% {opacity: 0.4;}
    100% {opacity: 1;}
`;

const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 1rem;
`;

const Letter = styled.span<Props>`
    animation: ${loadingAnimation} 1.9s linear infinite;
    animation-delay: ${props => props.delay};
    color: var(--accent);
    font-weight: 600;
`;

export function Loader() {
    return (
        <LoadingContainer>
            <Letter delay='0s'>V</Letter>
            <Letter delay='0.1s'>a</Letter>
            <Letter delay='0.2s'>l</Letter>
            <Letter delay='0.3s'>i</Letter>
            <Letter delay='0.4s'>d</Letter>
            <Letter delay='0.5s'>a</Letter>
            <Letter delay='0.6s'>t</Letter>
            <Letter delay='0.7s'>i</Letter>
            <Letter delay='0.8s'>n</Letter>
            <Letter delay='0.9s'>g&nbsp;</Letter>
            <Letter delay='1s'>b</Letter>
            <Letter delay='1.1s'>a</Letter>
            <Letter delay='1.2s'>n</Letter>
            <Letter delay='1.3s'>k&nbsp;</Letter>
            <Letter delay='1.4s'>d</Letter>
            <Letter delay='1.5s'>e</Letter>
            <Letter delay='1.6s'>t</Letter>
            <Letter delay='1.7s'>a</Letter>
            <Letter delay='1.8s'>i</Letter>
            <Letter delay='1.9s'>l</Letter>
            <Letter delay='2s'>s</Letter>
            <Letter delay='2.1s'>.</Letter>
            <Letter delay='2.2s'>.</Letter>
            <Letter delay='2.3s'>.</Letter>
        </LoadingContainer>
    )
}