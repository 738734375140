import { SAVE_SUPPLIERS } from "../actions/types";
import { SupplierConfig } from "../../models/supplier-config.model";

export default function Suppliers(state: SupplierConfig[] = [], action: any){
    switch (action.type){
        case SAVE_SUPPLIERS: {
            return {
                ...action.payload
            };
        }

        default:
            return state;
    }
}