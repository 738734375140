import { useContext } from "react";
import styled from "styled-components";
import { AppData } from "../../providers/AppData";
import { RichText } from "prismic-reactjs";

const BannerWrapper = styled.div`
  display: flex;
  min-height: 14rem;
  background: var(--large-banner-background);
  font-family: var(--font-family);
  height: 100%;
  padding: 0;
  margin: 0;
  align-items: center;
  justify-content: center;

  @media (max-width: 886px) {
    display: block;
  }

  @media (max-width: 365px) {
    display: none;
  }
`;

const LeftText = styled.div`
  flex-basis: 30%;
  font-size: 2rem;
  line-height: 2.8rem;
  margin-left: 4rem;
  padding-top: 1rem;
  color: var(--large-banner-text);
  font-weight: bold;
  margin-right: 8rem;

  @media (max-width: 886px) {
    margin-left: 2rem;
  }

  @media (max-width: 525px) {
    display: none;
  }
  @media (max-width: 4100px) {
    flex-basis: 12%;
  }

  @media (max-width: 3000px) {
    flex-basis: 16%;
  }

  @media (max-width: 2000px) {
    flex-basis: 20%;
  }

  @media (max-width: 1520px) {
    flex-basis: 30%;
  }
`;

const BulletPointsWrapper = styled.div`
  flex-basis: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  column-gap: 7rem;
  place-items: center;

  @media (max-width: 886px) {
    padding-top: 3rem;
    padding-left: 0rem;
  }

  @media (max-width: 525px) {
    column-gap: 2rem;
    padding-top: 2rem;
  }

  @media (max-width: 400px) {
    column-gap: 1rem;
  }
`;

const BulletPoints = styled.span`
  text-align: left;
  display: grid;
  grid-template-columns: 0.3fr 1fr;
  column-gap: 1rem;
  color: var(--large-banner-text);
  place-items: center;

  > p {
    font-size: 1.8rem;
  }

  @media (max-width: 440px) {
    > p {
      font-size: 1.5rem;
      line-height: 2.3rem;
    }
  }
`;

const TickSvg = styled.svg`
  background: transparent;
  fill: var(--large-banner-svg);
  border-color: var(--large-banner-svg);
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
  border: solid 1px var(--large-banner-svg);
`;

const LargeBanner = () => {
  const { labels } = useContext(AppData);

  return (
    <>
      <BannerWrapper>
        {labels.large_banner_left_text && (
          <LeftText>
            <span>{labels.large_banner_left_text}</span>
          </LeftText>
        )}
        <BulletPointsWrapper>
          <BulletPoints>
            <TickSvg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 30 30"
            >
              <path
                d="M22.39,9.36a1.19,1.19,0,0,1,0,1.69l0,0-8.81,8.57a1.27,1.27,0,0,1-1.77,0L7.12,15l0,0a1.2,1.2,0,0,1,0-1.69,1.27,1.27,0,0,1,1.77,0L12.74,17l7.89-7.67A1.26,1.26,0,0,1,22.39,9.36Z"
                fill-rule="evenodd"
              ></path>
            </TickSvg>
            {RichText.render(labels.large_banner_bullet_rich_text_1)}
          </BulletPoints>
          <BulletPoints>
            <TickSvg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 30 30"
            >
              <path
                d="M22.39,9.36a1.19,1.19,0,0,1,0,1.69l0,0-8.81,8.57a1.27,1.27,0,0,1-1.77,0L7.12,15l0,0a1.2,1.2,0,0,1,0-1.69,1.27,1.27,0,0,1,1.77,0L12.74,17l7.89-7.67A1.26,1.26,0,0,1,22.39,9.36Z"
                fill-rule="evenodd"
              ></path>
            </TickSvg>
            {RichText.render(labels.large_banner_bullet_rich_text_2)}
          </BulletPoints>
          <BulletPoints>
            <TickSvg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 30 30"
            >
              <path
                d="M22.39,9.36a1.19,1.19,0,0,1,0,1.69l0,0-8.81,8.57a1.27,1.27,0,0,1-1.77,0L7.12,15l0,0a1.2,1.2,0,0,1,0-1.69,1.27,1.27,0,0,1,1.77,0L12.74,17l7.89-7.67A1.26,1.26,0,0,1,22.39,9.36Z"
                fill-rule="evenodd"
              ></path>
            </TickSvg>
            {RichText.render(labels.large_banner_bullet_rich_text_3)}
          </BulletPoints>
        </BulletPointsWrapper>
      </BannerWrapper>
    </>
  );
};

export default LargeBanner;
