import React from "react";
import { components, IndicatorProps } from 'react-select';
import IconChevron from "./IconChevron";

export const DropdownChevron = (props : IndicatorProps<any, boolean>): JSX.Element => {
    return (
        <components.DropdownIndicator {...props}>
            <IconChevron />
        </components.DropdownIndicator>
    );
};
