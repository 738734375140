import React from "react";

import IconProps from "../../interfaces/Icon";

const IconGreenEnergy = (props: IconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" className={ props.wrapperClass || '' }>
            <path d="M15.56,5.05a16.29,16.29,0,0,1-5.09,5L5.93,12.31a4.84,4.84,0,0,1,.34-3.05A5.27,5.27,0,0,1,8.43,7a9.58,9.58,0,0,1,3.81-1A7.66,7.66,0,0,0,15.56,5.05Zm.61.65a19,19,0,0,1-.23,3.43c-.32,2.13-1.09,4.49-2.74,5.54A6,6,0,0,1,7.47,15a.17.17,0,0,0-.18,0c-.66.62-1.39,1.21-2.06,1.81L4.49,16l2-1.55.4-.31,3.74-2.89A16.8,16.8,0,0,0,16.17,5.7Z" fillRule="evenodd"/>
        </svg>
    );
};

export default IconGreenEnergy;
