// Lib
import React from "react";
import { Field, Form, FormikProps, withFormik } from "formik";

// Helpers
import { addressFormat } from "../../lib/addressFormat";
import { getElectricity, getGas } from "../../models/journey.model";

// Components
import Spinner from "../icons/Spinner";

// TS Definitions
import { ContactSalesRequest } from "../../interfaces/HalfHour";
import { ModalFormProps } from "../../interfaces/Modals";

// Yup Schema
import { creditScoreMeter } from "../../schemas/halfHourlyMeter";

class EnterDetailsForm extends React.Component<ModalFormProps & FormikProps<ContactSalesRequest>> {
    public checkKeyInput = (event: React.KeyboardEvent<HTMLInputElement>): void|boolean => {
        const utilKeys = ["Backspace", "Delete", "+", "Tab", "CapsLock", "ArrowRight", "ArrowLeft", "ArrowDown", "ArrowUp"];
        if(!/^\d+$/.test(event.key) && utilKeys.indexOf(event.key) === -1){
            event.preventDefault()
        }

        return true;
    }

    public render = () => {
        return (
            <Form className={"form form--details"}>
                <div className={"grid"}>
                    <div className={"col-12"}>
                        <div className={
                                this.props.errors.companyName && this.props.touched.companyName
                                    ? 'form__group invalid'
                                    : "form__group"
                            }>
                                <label className={"form__label"}>Company name</label>
                                <Field  type="text"
                                        name="companyName"
                                        className={"form__control"}
                                        placeholder={"Company name"}
                                        value={this.props.values.companyName} />
                            {this.props.errors.companyName && this.props.touched.companyName ? (
                                <span className={"form__error"}>{this.props.errors.companyName}</span>
                            ): null}
                        </div>
                    </div>
                    <div className={"col-sm-6"}>
                        <div className={
                            this.props.errors.name && this.props.touched.name
                                ? 'form__group invalid'
                                : "form__group"
                        }>
                            <label className={"form__label"}>Name</label>
                            <Field type="text"
                                   name="name"
                                   className={"form__control"}
                                   placeholder={"Name"} />
                            {this.props.errors.name && this.props.touched.name ? (
                                <span className={"form__error"}>{this.props.errors.name}</span>
                            ): null}
                        </div>
                    </div>
                    <div className={"col-sm-6"}>
                        <div className={
                            this.props.errors.phoneNumber && this.props.touched.phoneNumber
                                ? 'form__group invalid'
                                : "form__group"
                        }>
                            <label className={"form__label"}>Phone number</label>
                            <Field type={"text"}
                                   inputMode={"numeric"}
                                   maxLength="13"
                                   name="phoneNumber"
                                   className={"form__control"}
                                   placeholder={"Phone number"} />
                            {this.props.errors.phoneNumber && this.props.touched.phoneNumber ? (
                                <span className={"form__error"}>{this.props.errors.phoneNumber}</span>
                            ): null}
                        </div>
                    </div>
                    <div className={"col-12"}>
                        <div className={
                            this.props.errors.email && this.props.touched.email
                                ? 'form__group invalid'
                                : "form__group"
                        }>
                            <label className={"form__label"}>Email</label>
                            <Field type="email"
                                   name="email"
                                   className={"form__control"}
                                   placeholder={"Email address"}
                                   value={this.props.values.email} />
                            {this.props.errors.email && this.props.touched.email ? (
                                <span className={"form__error"}>{this.props.errors.email}</span>
                            ): null}
                        </div>
                    </div>
                    <div className={"col-12"}>
                        <div className={
                            this.props.errors.address && this.props.touched.address
                                ? 'form__group invalid'
                                : "form__group"
                        }>
                            <label className={"form__label"}>Address</label>
                            <Field type="text"
                                   name="address"
                                   className={"form__control"}
                                   placeholder={"Address"}
                                   value={this.props.values.address}/>
                            {this.props.errors.address && this.props.touched.address ? (
                                <span className={"form__error"}>{this.props.errors.address}</span>
                            ): null}
                        </div>
                    </div>
                    <div className={"col-sm-6"}>
                        <div className={
                            this.props.errors.postCode && this.props.touched.postCode
                                ? 'form__group invalid'
                                : "form__group"
                        }>
                            <label className={"form__label"}>Postcode</label>
                            <Field type="text"
                                   name="postCode"
                                   className={"form__control"}
                                   placeholder={"Postcode"}
                                   value={this.props.values.postCode}/>
                            {this.props.errors.postCode && this.props.touched.postCode ? (
                                <span className={"form__error"}>{this.props.errors.postCode}</span>
                            ): null}
                        </div>
                    </div>
                </div>
                <div className={"form__actions justified"}>
                    <a href={"/"}>Start a new quote</a>
                    <button type={"submit"}
                            className={"button"}
                            disabled={!this.props.isValid || this.props.isSubmitting}
                            data-loading={(this.props.isSubmitting ? 'true' : '')} >
                        <Spinner wrapperClass={"button__spinner"}/>
                        {this.props.buttonText}
                    </button>
                </div>
            </Form>
        );
    }
}

export const ContactSalesForm = withFormik<ModalFormProps, Omit<ContactSalesRequest, 'type'>>(
    {
        validationSchema: creditScoreMeter,
        mapPropsToValues: props => {
            const ele = getElectricity(props.journey.utilities);
            const gas = getGas(props.journey.utilities);
            const address = addressFormat(props.journey?.supplyAddress);

            return {
                journeyId: props.journey?.uid ?? '',
                name: '',
                email: props.journey?.contactInformation?.email ?? '',
                phoneNumber: '',
                address: address,
                postCode: props.journey?.supplyAddress?.postCode ?? '',
                companyName: props.journey?.supplyCompany?.name ?? '',
                electricityStartDate: ele?.quotePeriod?.start ?? '',
                gasStartDate: gas?.quotePeriod?.start ?? '',
                electricityCurrentSupplier: props.journey?.currentSupplier?.electricity?.name ?? '',
                gasCurrentSupplier: props.journey?.currentSupplier?.gas?.name ?? '',
                MPAN: props.journey?.supplyAddress?.mpxn ?? '',
                MPRN: props.journey?.supplyAddress?.mpxn ?? '',
                stackName: props.journey?.metadata?.stackInformation?.name ?? '',
                affiliate: props.journey?.affiliate ?? '',
                journeyUtilityType: props.journey?.journeyUtilityType ?? '',
                gasEac: gas?.supplyConsumption?.eac,
                electricityEac: ele?.supplyConsumption?.eac
            }
        },
        handleSubmit: (values: ContactSalesRequest, { props, setSubmitting }) => {
            setSubmitting(true);
            if(props.onSubmitted !== null){
                props.onSubmitted(values).then((saved: boolean) => {
                    setSubmitting(false);

                    if(saved && props.onCompleted !== null){
                        props.onCompleted();
                    }
                });
            }
        }
    }
)(EnterDetailsForm);