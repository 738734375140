import { useContext } from "react";
import IconGreenEnergy from "../icons/IconGreenEnergy";
import { Rate } from "../../models/quote.model";
import { QuoteDetailsProps } from "../../interfaces/Quotes";
import { AppData } from "../../providers/AppData";

const QuoteDetailsList = (props: QuoteDetailsProps) => {
  const { toggles } = useContext(AppData);
    return(
        <dl className={toggles.quote_overview_details_bold ? "details details--inline quote-overview" : "details details--inline"}>
        <div>
          <dt>Term Length:</dt>
          <dd>
            {props.quote.termInMonths === 0 ? (
              <>Variable</>
            ) : (
              <>{props.quote.termInMonths} Months</>
            )}
          </dd>
          {props.quote.isGreen &&
          props.listType === "default" ? (
            <span className={"green-energy"}>
              <IconGreenEnergy />
            </span>
          ) : (
            ""
          )}
        </div>
        {props.quote.rates.map((rate: Rate) => {
          if (props.quote.supplier === 'sse') {
            return (
              <div key={rate.name}>
                <dt>{rate.name}:</dt>
                <dd>
                  {rate.value}p/{rate.unit}
                </dd>
              </div>
            )
          }

          return (
            <div key={rate.name}>
              <dt>{rate.name}:</dt>
              <dd>
                {Math.round((rate.value + Number.EPSILON) * 100) / 100}p/{rate.unit}
              </dd>
            </div>
          );
        })}
        {props.children}
      </dl>
    )
}

export default QuoteDetailsList;