import { SAVE_ELECTRICITY_QUOTES, SAVE_GAS_QUOTES, SAVE_ELECTRICITY_ERROR, SAVE_GAS_ERROR} from "./types";
import { Quote } from "../../models/quote.model";
import { QuotesAction } from "../reducers/Quotes";

export const saveElectricityQuotes = (content: Quote[]): QuotesAction => ({
    type: SAVE_ELECTRICITY_QUOTES,
    payload: content
});

export const saveGasQuotes = (content: Quote[]): QuotesAction => ({
    type: SAVE_GAS_QUOTES,
    payload: content
});

export const saveElectricityErrors = (content: string): QuotesAction => ({
    type: SAVE_ELECTRICITY_ERROR,
    payload: content
});

export const saveGasErrors = (content: string): QuotesAction => ({
    type: SAVE_GAS_ERROR,
    payload: content
});
