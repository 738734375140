import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import API from "../../lib/API";
import {
  setLocalStorageItem,
  getLocalStorageItem,
  canStoreLocalStorageItem,
} from "../../lib/local-storage";
import { saveJourneyToState } from "./saveJourneyToState";
import {Journey} from "../../models/journey.model";

type MyThunkDispatch = ThunkDispatch<{}, undefined, Action>;

export const getIpAddress = () => {
  return async function getIpAddressThunk(dispatch: MyThunkDispatch) {
    const ipAddress = await API.getIpAddress();
    dispatch(saveJourneyToState({ ipAddress }));
  };
};

export const getAffilliate = () => {
  return function getAffilliateThunk(dispatch: MyThunkDispatch) {
    const affiliateKey = "affiliate";
    const affiliate = getUrlParam(affiliateKey);

    if (affiliate) setLocalStorageItem(affiliateKey, affiliate);

    dispatch(
      saveJourneyToState({
        affiliate: canStoreLocalStorageItem(affiliateKey)
          ? getLocalStorageItem(affiliateKey)
          : affiliate,
      })
    );

    function getUrlParam(key: string): string | null {
      if (URLSearchParams && window?.location?.search) {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(key);
      }
      return null;
    }
  };
};

export const getCookies = () => {
  return function getCookiesThunk(dispatch: MyThunkDispatch) {
    dispatch(
      saveJourneyToState({
        consent: { acceptedCookies: getLocalStorageItem("acceptCookies") === "true" }
      })
    );
  };
};

export const getPrismicId = (content: Journey, prismicId: string) => {
  return function getPrismicIdThunk(dispatch: MyThunkDispatch) {
    dispatch(
        saveJourneyToState({ 
          metadata: {
            stackInformation: {
              ...content.metadata.stackInformation,
              prismicId: prismicId ?? ""
            }
          }
        })
    );
  };
}