// Lib
import React from "react";
import { RichText } from "prismic-reactjs";
import API from "../lib/API";

// Component
import Header from "../components/header/Header";
import IconTick from "../components/icons/IconTick";

// Providers
import { AppData } from "../providers/AppData";

//TS Definitions
import { PurchaseEventData } from "../models/purchase-event.model";
import { AppLayoutData } from "../interfaces/Journey";
import { CompletedPageParams, CompletedPageState } from "../interfaces/CompletedPage";
import { analyticsPageView, analyticsEcommerce } from "../stores/analytics/actions";
import { connect, ConnectedProps } from "react-redux";
import { AppState } from "../stores/store";

const mapStateToProps = (state: AppState) => state;
const dispatchProps = { analyticsPageView, analyticsEcommerce };
const connector = connect(mapStateToProps, dispatchProps);

class CompletedPage extends React.Component<ConnectedProps<typeof connector>, CompletedPageState>{
    static contextType: React.Context<AppLayoutData> = AppData;

    constructor(props: any) {
        super(props);

        this.state = {
            params : this.parseQueryString()
        }
    }

    public parseQueryString = (): CompletedPageParams  => {
        const searchParam = new URLSearchParams(window.location.search);

        return {
            journey : searchParam.get('journey') ?? '',
            event : searchParam.get('event') ?? null,
            isHalfHour : !!searchParam.get('isHalfHour'),
            isIntegrated : (searchParam.get('integrated') === "true"),
            isEmailDocusign : (searchParam.get('isEmailDocusign') === "true")
        } as CompletedPageParams;
    }

    public purchaseEvent = async () => {
        return await API.getPurchaseData(this.state.params.journey);
    }

    public componentDidMount = () => {
        this.props.analyticsPageView(window.location.pathname);

        if(document.scrollingElement !== null){
            document.scrollingElement.scrollTop = 0;
        }

        if(this.state.params.isHalfHour){
            return false;
        }

        if(this.state.params.journey) {
            API.getPurchaseData(this.state.params.journey).then((purchase: PurchaseEventData) => {
                this.props.analyticsEcommerce(purchase);
            });
        }        
    }

    public getNextSteps = () => {
        if(this.state.params.isEmailDocusign){
            return this.context.labels.email_docusign_completed_body;
        }

        if(this.state.params.isIntegrated){
            return this.context.labels.integrated_completed_body;
        }

        if(this.state.params.isHalfHour){
            return this.context.labels.half_hour_completed_body;
        }

        return this.context.labels.completed_body;
    }

    public render = (): JSX.Element => {
        const content = this.getNextSteps();

        return (
            <>
                <Header />
                <main id={"app-body"} className={"no-progress-bar"}>
                    <div className={"container-full container-full--small"}>
                        <div className={"completed-page"}>
                            <IconTick wrapperClass={"completed-page__icon"} />
                            {RichText.render(content)}
                            <a 
                              href={this.context.labels.completed_return_button} 
                              className={"button button--outline"}
                            >Return to website</a>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}

export default connector(CompletedPage);
