import { Actions } from "./models";
import { AnalyticsState } from "./models";

const initialState: AnalyticsState = {
  initialized: false,
  identifiers: {
    googleAnalyticsId: undefined,
    googleTagMangerId: undefined,
  },
  eventsToProcess: [],
};

export default function Analytics(
  state: AnalyticsState = initialState,
  action: Actions
): AnalyticsState {
  switch (action.type) {
    case "ANALYTICS_INITIALIZED": {
      return {
        ...state,
        initialized: true,
        identifiers: {
          ...state.identifiers,
          googleAnalyticsId: action.payload.googleAnalyticsId,
          googleTagMangerId: action.payload.googleTagMangerId,
        },
      };
    }
    case "ANALYTICS_ADD_EVENT": {
      return {
        ...state,
        eventsToProcess: [...state.eventsToProcess, action.payload],
      };
    }
    case "ANALYTICS_PROCESSED_EVENTS": {
      return {
        ...state,
        eventsToProcess: state.eventsToProcess.filter(
          (e) => !action.payload.includes(e.id)
        ),
      };
    }
    default:
      return state;
  }
}