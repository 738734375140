// Lib
import React from "react";
import CurrencyInput from 'react-currency-input-field';

// Providers
import { AppData } from "../../../providers/AppData";

// TS Definitions
import { EnergyUsageState } from "../../../interfaces/EnergyUsage";
import { ConsumptionPeriods } from "../../../models/consumption-periods";
import { ConsumptionTypes } from "../../../models/consumption-types";
import { JourneyUtilityType, QuoteDetails, Utilities } from "../../../models/journey.model";
import { FuelType, isElectricityUtilityDetail, isGasUtilityDetail, LookupMetadata } from "../../../models/utility-detail";
import { Quote } from "../../../models/quote.model";
import { AppLayoutData } from "../../../interfaces/Journey";
import { FormInput } from "../../../interfaces/Form";
import { addDays, max } from 'date-fns'

// Services
import { ConsumptionService } from "../../../services/consumption.service";

// Icons
import IconRefresh from "../../icons/IconRefresh";

import styled from "styled-components";
import PeriodToggles from "./periodToggles";
import { UsageContainer } from "./periodToggles/style";

class ManualEnergyUsage extends React.Component<FormInput, EnergyUsageState>{
    static contextType: React.Context<AppLayoutData> = AppData;

    //, ConsumptionPeriods.Quarterly <<--- temp removed
    private periods: ConsumptionPeriods[] = [ConsumptionPeriods.Monthly, ConsumptionPeriods.Yearly];
    private periodCount: number = 0;
    private consumptionService: ConsumptionService = new ConsumptionService();

    state: EnergyUsageState = {
        supplyType: this.props.form.values.journeyUtilityType,
        type: typeof this.context.toggles.direct_journey !== 'undefined' && this.context.toggles.direct_journey ? ConsumptionTypes.Energy : ConsumptionTypes.Spend,
        period: ConsumptionPeriods.Monthly,
        gas: "",
        electricity: "",
        utilities: this.props.form.values.utilities || {} as Utilities,
        isDirect: typeof this.context.toggles.direct_journey !== 'undefined' && this.context.toggles.direct_journey ? true : false
    }

    public componentDidMount = () => {
        if (typeof this.props.form.values.utilities.quoteDetails !== 'undefined' && this.props.form.values.utilities.quoteDetails.length) {
            if (this.props.form.values.journeyUtilityType === JourneyUtilityType.DualFuel) {
                this.props.form.values.utilities.quoteDetails.forEach((quoteDetail: QuoteDetails) => {
                    this.setInputState(quoteDetail);
                });
            } else {
                this.setInputState(this.props.form.values.utilities.quoteDetails[0]);
            }
        }
    }

    public componentDidUpdate(prevProps: Readonly<FormInput>, prevState: Readonly<EnergyUsageState>, snapshot?: any): void {
        if (prevProps.form.values.journeyUtilityType !== this.props.form.values.journeyUtilityType) {
            this.setState({
                supplyType: this.props.form.values.journeyUtilityType
            }, () => {
                this.checkGroupState();
            });
        }
    }

    public setInputState = (quoteDetail: QuoteDetails) => {
        const typeValue = (quoteDetail.supplyConsumption.spend !== null)
            ? quoteDetail.supplyConsumption.spend
            : quoteDetail.supplyConsumption.energy;

        // FIXME: Couldn't get computed property names to work with the state interface, should be looked at again.
        if (quoteDetail.quoteType === FuelType.Electricity) {
            this.setState({
                type: (quoteDetail.supplyConsumption.spend !== null) ? ConsumptionTypes.Spend : ConsumptionTypes.Energy,
                period: quoteDetail.supplyConsumption.period as ConsumptionPeriods,
                electricity: `${typeValue}`
            }, this.checkGroupState);
        } else {
            this.setState({
                type: (quoteDetail.supplyConsumption.spend !== null) ? ConsumptionTypes.Spend : ConsumptionTypes.Energy,
                period: quoteDetail.supplyConsumption.period as ConsumptionPeriods,
                gas: `${typeValue}`
            }, this.checkGroupState);
        }
    }

    public changeType = (): void => {
        this.setState({
            ...this.state,
            period: (this.state.period === ConsumptionPeriods.Monthly) ? ConsumptionPeriods.Yearly : ConsumptionPeriods.Monthly,
            type: (this.state.type === ConsumptionTypes.Spend) ? ConsumptionTypes.Energy : ConsumptionTypes.Spend,
            electricity: "",
            gas: ""
        });
    }

    public changeDirectType = (value: ConsumptionPeriods): void => {
        this.setState({
            ...this.state,
            period: value,
            electricity: "",
            gas: ""
        });
    }

    public getMetaData = (quoteType: number): LookupMetadata | {} => {
        let existingQuoteDetails = this.state.utilities.quoteDetails ?? [];
        for (let i = 0; i < existingQuoteDetails.length; i++) {
            if (existingQuoteDetails[i].quoteType === quoteType) {
                return existingQuoteDetails[i].metadata ?? {};
            }
        }
        return {};
    }

    public setConsumptionArray = (): void => {
        let utilitiesArray = { quoteDetails: [] } as Utilities;
        let existingQuoteDetails = this.state.utilities.quoteDetails ?? {};
        let metaData = existingQuoteDetails.length && existingQuoteDetails[0].metadata
            ? existingQuoteDetails[0].metadata : {};
        if (this.props.form.values.journeyUtilityType === JourneyUtilityType.Electricity
            || this.props.form.values.journeyUtilityType === JourneyUtilityType.DualFuel) {
            let quoteDetail = {
                quoteType: FuelType.Electricity,
                quotePeriod: {
                    start: new Date(this.props.form.values.quotePeriod)
                },
                quote: {} as Quote,
                supplyConsumption: {
                    "spend": this.state.type === ConsumptionTypes.Spend ? parseFloat(this.state.electricity) : null,
                    "energy": this.state.type === ConsumptionTypes.Energy ? parseFloat(this.state.electricity) : null,
                    "period": this.state.period as ConsumptionPeriods,
                    "eac": 0
                },
                metadata: this.getMetaData(FuelType.Electricity)
            } as QuoteDetails;

            const eac = this.consumptionService.getElectricityEac(
                quoteDetail.supplyConsumption,
                this.context.variables.electricity_kwh_rate,
                this.context.variables.electricity_daily_rate,
                this.context.variables.electricity_minimum_eac,
                this.context.variables.electricity_low_spend,
                this.context.variables.electricity_low_consumption,
                this.context.variables.electricity_low_daily_rate,
                this.context.variables.electricity_low_kwh_rate,
            );

            quoteDetail.supplyConsumption.eac = Number(eac);

            utilitiesArray.quoteDetails.push(quoteDetail);
        }

        if (this.props.form.values.journeyUtilityType === JourneyUtilityType.Gas
            || this.props.form.values.journeyUtilityType === JourneyUtilityType.DualFuel) {
            let quoteDetail = {
                quoteType: FuelType.Gas,
                quotePeriod: {
                    start: new Date(this.props.form.values.quotePeriod)
                },
                quote: {} as Quote,
                supplyConsumption: {
                    "spend": this.state.type === ConsumptionTypes.Spend ? parseFloat(this.state.gas) : null,
                    "energy": this.state.type === ConsumptionTypes.Energy ? parseFloat(this.state.gas) : null,
                    "period": this.state.period as ConsumptionPeriods,
                    "eac": 0
                },
                metadata: this.getMetaData(FuelType.Gas)
            } as QuoteDetails;

            const eac = this.consumptionService.getGasEac(
                quoteDetail.supplyConsumption,
                this.context.variables.gas_daily_rate,
                this.context.variables.gas_kwh_rate,
                this.context.variables.gas_minimum_eac
            );

            quoteDetail.supplyConsumption.eac = Number(eac);

            utilitiesArray.quoteDetails.push(quoteDetail);
        }

        this.setState({ utilities: utilitiesArray }, () => {
            this.props.form?.setFieldValue(this.props.field?.name || 'utilities', utilitiesArray);
            this.props.form?.setFieldValue('supplyConsumptionType', this.state.type);
            this.checkGroupState();
        });
    }

    public checkGroupState = (): void => {
        let groupState: boolean = false;

        switch (this.props.form.values.journeyUtilityType) {
            case JourneyUtilityType.Electricity: {
                groupState = !!this.state.electricity.length && Number(this.state.electricity) > 0;
                break;
            }
            case JourneyUtilityType.Gas: {
                groupState = !!this.state.gas.length && Number(this.state.gas) > 0;
                break;
            }
            case JourneyUtilityType.DualFuel: {
                groupState = !!this.state.electricity.length && !!this.state.gas.length && Number(this.state.gas) > 0 && Number(this.state.electricity) > 0;
                break;
            }
        }

        this.props.updateGroupStatus(groupState);
    }

    private handleGasInput = (value: string): void => {
        this.setState({ gas: value }, () => this.setConsumptionArray());
    }

    private handleElectricityInput = (value: string): void => {
        this.setState({ electricity: value }, () => this.setConsumptionArray());
    }

    private createInput = (type: JourneyUtilityType): JSX.Element => {
        const typeString = (type === JourneyUtilityType.Electricity) ? 'electricity' : 'gas';

        const showToggle = (
            type === JourneyUtilityType.Electricity ||
            (this.props.form.values.journeyUtilityType === JourneyUtilityType.Gas && type === JourneyUtilityType.Gas)
        );

        const changeFunction = (type === JourneyUtilityType.Electricity)
            ? (value: string | undefined) => this.handleElectricityInput(value as string || '')
            : (value: string | undefined) => this.handleGasInput(value as string || '');

        const GetInputValue = (value: string | null) => {
            if (value === '0' || value === null || value === 'null') {
                return '';
            }

            return value;
        }

        const GetPlaceholder = () => {
            if (this.state.isDirect) {
                return 'kWh'
            } else {
                return (this.state.period === ConsumptionPeriods.Monthly) ? "£" : "kWh"
            }
        }

        let inputArgs: any = {
            name: `${this.props.field?.name}-${typeString}`,
            id: `${this.props.field?.name}-${typeString}`,
            'data-testid': `journeyUsage-${typeString}`,
            className: "form__control",
            value: (type === JourneyUtilityType.Electricity) ? GetInputValue(this.state.electricity) : GetInputValue(this.state.gas),
            defaultValue: '',
            autoComplete: "off",
            placeholder: GetPlaceholder(),
            onChange: changeFunction,
            onBlurValue: changeFunction
        }

        if (this.state.isDirect) {
            inputArgs.prefix = "";
            inputArgs.turnOffAbbreviations = true;
            inputArgs.turnOffSeparators = true;
            inputArgs.allowNegativeValue = false;
        } else {
            if (this.state.period === ConsumptionPeriods.Monthly) {
                inputArgs.prefix = "£";
                inputArgs.maxLength = "6";
                inputArgs.allowNegativeValue = false;
            } else {
                inputArgs.prefix = "";
                inputArgs.turnOffAbbreviations = true;
                inputArgs.turnOffSeparators = true;
                inputArgs.allowNegativeValue = false;
            }
        }

        const RenderLabel = () => {
            if (this.state.isDirect) {
                return (
                    type === JourneyUtilityType.Electricity
                        ? this.context.labels.supply_consumption_kwh_electricity_title
                        : this.context.labels.supply_consumption_kwh_gas_title
                )
            } else {
                return (
                    this.state.period === ConsumptionPeriods.Monthly
                        ? (
                            type === JourneyUtilityType.Electricity
                                ? this.context.labels.supply_consumption_spend_electricity_title
                                : this.context.labels.supply_consumption_spend_gas_title
                        ) : (
                            type === JourneyUtilityType.Electricity
                                ? this.context.labels.supply_consumption_kwh_electricity_title
                                : this.context.labels.supply_consumption_kwh_gas_title
                        )
                )
            }
        }

        return (
            <>
                <label htmlFor={`${this.props.field?.name}-${typeString}`} className={"form__label"}>
                    {RenderLabel()}
                    &ensp;
                    {(showToggle && !this.state.isDirect)
                        ? (
                            <button onClick={() => this.changeType()} type={'button'} className={"text-button"}>
                                {
                                    this.state.period === ConsumptionPeriods.Monthly
                                        ? this.context.labels.supply_consumption_spend_change_to_kwh
                                        : this.context.labels.supply_consumption_kwh_change_to_spend
                                }
                            </button>
                        ) : (<></>)
                    }
                </label>
                <div className={"energy-usage"}>
                    {this.state.isDirect ? (
                        <UsageContainer>
                            <PeriodToggles 
                                selectedPeriod={this.state.period} 
                                handler={(value: ConsumptionPeriods) => this.changeDirectType(value)} 
                                type={type}
                            />
                            <CurrencyInput {...inputArgs} />
                        </UsageContainer>
                    ) : (
                        <>
                            <CurrencyInput {...inputArgs} />
                            <button onClick={() => this.changeType()} type={'button'} className={"energy-usage__button icon-text-button"}>
                                <span>{this.state.period}</span>
                                <IconRefresh />
                            </button>
                        </>
                    )}
                </div>
            </>
        )
    }

    public render = (): JSX.Element => {
        const type = this.props.form.values.journeyUtilityType;

        return (
            <>
                {type === JourneyUtilityType.Electricity || type === JourneyUtilityType.DualFuel
                    ? this.createInput(JourneyUtilityType.Electricity)
                    : (<></>)
                }
                {type === JourneyUtilityType.Gas || type === JourneyUtilityType.DualFuel
                    ? this.createInput(JourneyUtilityType.Gas)
                    : (<></>)
                }
            </>
        );
    }
}

export default ManualEnergyUsage;