/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useContext, useEffect, useState } from "react";

import { Field, FormikHelpers, FormikState } from "formik";
import { FormInput } from "../../interfaces/Form";
import { AppData } from "../../providers/AppData";
import { Journey } from "../../models/journey.model";
import IconTick from "../icons/IconTick";
import { RichText } from "prismic-reactjs";
import { Regexes } from "../../models/regexes.model";
import { FeatureToggle } from "../misc/FeatureToggle";

type ContactDetailsProps = FormInput & {
  form: FormikHelpers<any> & FormikState<Journey>;
};

// TODO: Remove useEffect
// TODO: This could be a nested form, where we push all state managment into formik
export const ContactDetails: FunctionComponent<ContactDetailsProps> = (props) => {
  const { labels, toggles } = useContext(AppData);
  const [email, setEmail] = useState(
    props?.form?.values?.contactInformation?.email ?? ""
  );
  const [phoneNumber, setPhoneNumber] = useState(
    props?.form?.values?.contactInformation?.phoneNumber ?? ""
  );
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    props.form?.setFieldValue("contactInformation.email", email);
    props.form?.setFieldValue("contactInformation.emailCheckBox", checked);
    props.form?.setFieldValue("contactInformation.phoneNumber", phoneNumber);

    if (!validateEmail(email) && !validateCheck(checked) && !validatePhoneNumber(phoneNumber)) {
      props.updateGroupStatus(true);
    } else {
      props.updateGroupStatus(false);
    }
  }, [email, checked, phoneNumber]);

  const validateEmail = (value: string) => {
    if (!value || !Regexes.EMAIL.test(value)) {
      return labels.contact_details_email_error || 'Required';
    }
    return null;
  };

  const validatePhoneNumber = (value: string) => {
    if (!toggles.contact_details_collect_phone_number) {
      return null;
    }

    if (!value || !Regexes.PHONE_NUMBER.test(value)) {
      return labels.contact_details_phone_error || 'Required';
    }

    return null;
  };

  const validateCheck = (value: boolean) => {
    if (!value) {
      return labels.contact_details_email_error || 'Required';
    }
    return null;
  };

  return (
    <>
      <label className={"form__label"}>{labels.contact_details_email_title}</label>
      <div className={"styled-search"} style={{ marginBottom: "1.5em" }}>
        <Field
          type="email"
          name="contactInformation.email"
          className={"form__control form__control--width"}
          value={props.form.values.contactInformation.email}
          placeholder={labels.contact_information_email_placeholder}
          validate={validateEmail}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(e?.target?.value);
          }}
        />
        {props.form.touched.contactInformation?.email &&
          props.form.errors.contactInformation?.email && (
            <span className={"form__error"} style={{ position: "absolute" }}>
              {props.form.errors.contactInformation.email}
            </span>
          )}
      </div>

      <FeatureToggle
        name={"contact_details_collect_phone_number"}
        fallback={false}
        render={<div className={"styled-search"} style={{ marginBottom: "1.5em" }}>
          <Field
            type="tel"
            name="contactInformation.phoneNumber"
            className={"form__control form__control--width"}
            value={props.form.values.contactInformation.phoneNumber}
            placeholder={labels.contact_information_phone_number_placeholder}
            validate={validatePhoneNumber}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setPhoneNumber(e?.target?.value);
            }}
          />
          {props.form.touched.contactInformation?.phoneNumber &&
            props.form.errors.contactInformation?.phoneNumber && (
              <span className={"form__error"} style={{ position: "absolute" }}>
                {props.form.errors.contactInformation.phoneNumber}
              </span>
            )}
        </div>}
        otherwiseRender={<></>}
      />

      <div className={"checkbox"} style={{ marginBottom: "1em" }}>
        <Field
          type={"checkbox"}
          id={"emailCheckBox"}
          name="contactInformation.emailCheckBox"
          validate={validateCheck}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.type === "checkbox") {
              setChecked(event?.target?.checked ?? false);
            }
          }}
        />
        <label htmlFor={"emailCheckBox"}>
          <div className={"dummy"}>
            <IconTick wrapperClass={"dummy__tick"} />
          </div>
          <span>{labels.contact_details_email_checkbox_text}</span>
        </label>
      </div>

      {
        labels.contact_details_paragraph &&
        labels.contact_details_paragraph.length > 0 && (
          <>{RichText.render(labels.contact_details_paragraph)}</>
        )
      }
    </>
  );
};
