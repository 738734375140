import React, { useContext } from "react";
import { AppData } from "../../providers/AppData";
import { RichText } from "prismic-reactjs";

export const Footer: React.FunctionComponent = () => {
  const context = useContext(AppData);
  return (
    <footer className={"footer"}>
      <div className={"container-full"}>
        {context.labels &&
          Array.isArray(context.labels.footer) &&
          RichText.render(context.labels.footer)}
      </div>
    </footer>
  );
};
