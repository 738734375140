import styled from 'styled-components';

export const UsageContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    @media screen and (min-width: 768px){
        width: 66%;
    }
`;

export const RadioToggle = styled.div`
    display: flex;
	margin-bottom: 1rem;
	flex-wrap: wrap;
`;

export const RadioToggleInput = styled.input`
    position: absolute !important;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	width: 1px;
	border: 0;
	overflow: hidden;
    background-color: var(--primary);
    color: var(--accent);

    &:checked + label {
	    background-color: var(--accent);
        color: var(--primary);
    }
`;

export const RadioToggleLabel = styled.label`
    padding: .875rem 1rem;
    min-width: 50%;
	text-align: center;
    font-weight: bold;
	border: 0.3rem solid var(--accent);
    background-color: var(--primary);
	color: var(--accent);
	transition: all 0.1s ease-in-out;
    cursor: pointer;

    @media screen and (min-width: 768px){
        min-width: 25%;

        &:first-of-type {
            border-radius: 0.3rem 0 0 0.3rem;
        }

        &:last-of-type {
            border-radius: 0 0.3rem 0.3rem 0;
        }
    }

    &:hover {
        background-color: var(--accent);
	    color: var(--primary);
    }   
`;