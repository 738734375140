import { VIEWED_TOUR } from "../actions/types";

export default function ViewedTour(state: boolean = false, action: any){
    switch (action.type){
        case VIEWED_TOUR: {
            return action.payload;
        }

        default:
            return state;
    }
}