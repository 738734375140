// Lib
import React from "react";

// Component
import ProgressBar from "../ProgressBar";

// TS Definitions
import { ProcessingSwitchProps } from "../../interfaces/SwitchPage";
import { AppLayoutData, BrandingImageData } from "../../interfaces/Journey";
import { AppData } from "../../providers/AppData";

class ProcessingSwitch extends React.Component<ProcessingSwitchProps> {
  static contextType: React.Context<AppLayoutData> = AppData;

  private progressBar: React.RefObject<ProgressBar> = React.createRef<ProgressBar>();
  private wrapper: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();

  public startProgressBar = () => {
    this.progressBar?.current?.updateProgress(80, "80s");
  };

  public complete = () => {
    this.progressBar?.current?.updateProgress(100, ".4s");
  };

  public render = (): JSX.Element => {
    const logo = this.context.branding["logo"] as BrandingImageData;
    const loadingQuotesImage = this.context.branding["loading_quotes"] as BrandingImageData;
    const loadingImage = loadingQuotesImage?.url ? loadingQuotesImage : logo;

    return (
      <div
        className={
          this.props.loading ? "loading-quotes load-in" : "loading-quotes hidden"
        }
        ref={this.wrapper}
      >
        <div className={"loading-quotes__inner"} onAnimationEnd={this.startProgressBar}>
          {loadingImage.url && (
            <img
              src={loadingImage.url}
              alt={loadingImage.alt ?? "company logo"}
              className={"loading-quotes__logo"}
            />
          )}
          <h2>Finalising the switch</h2>
          <ProgressBar ref={this.progressBar} initialValue={0} />
          {this.props.requiresDocusign ? (
            <>
              <img
                src={`https://images.prismic.io/tickd/cf1ffd5c-c0ac-4ab5-bcce-657c0104bac6_logo_docusign_new.png?auto=compress,format`}
                alt={"Logo"}
                className={"loading-quotes__docusign"}
              />
              <p>Redirecting you to DocuSign. This may take a few moments.</p>
            </>
          ) : null}
        </div>
      </div>
    );
  };
}

export default ProcessingSwitch;
