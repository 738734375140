// Lib
import React from "react";
import { Field } from "formik";
import { RichText } from "prismic-reactjs";

// Icons
import IconTick from "../icons/IconTick";

// Providers
import { AppData } from "../../providers/AppData";

// TS Definitions
import { AppLayoutData, BrandingImageData } from "../../interfaces/Journey";
import { LegalsComponentProps } from "../../interfaces/Form";
import styled from "styled-components";

const TextWrapper = styled.div`
  display: inline-block;
  width: 60%;
  text-align: left;

  @media (max-width: 555px) {
    width: 100%;
  }
`;

const ImageWrapper = styled.div`
  display: inline-block;
  width: 35%;

  > img {
    width: 240px;
    display: block;
    margin: auto;
    padding-left: 2rem;
  }

  @media (max-width: 790px) {
    > img {
    padding-bottom: 2rem;
    }
  }

  @media (max-width: 555px) {
    width: 100%;
    > img {
      padding-top: 1rem;
      padding-left: 0;
      padding-bottom: 0;
      width: 200px;
    }
  }
`

const DescriptionWrapper = styled.div`
    margin-top: 15px;
`

class Legals extends React.Component<LegalsComponentProps>{
    static contextType: React.Context<AppLayoutData> = AppData;
    context!: React.ContextType<typeof AppData>

    public callFieldChangeFromInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const value = (event.target.type === "checkbox") ? event.target.checked : event.target.value;

        this.props.onFieldChange(event.target.getAttribute('name') ?? '', value);
    }

    public render = () => {
        const directDebitLogo: BrandingImageData = this.context.branding["direct_debit_logo"] as BrandingImageData;
        return (
            <>
                <div className={"form__intro"}>
                    <h2>
                        <IconTick wrapperClass={"icon"} />
                        {this.context.labels.legal_title}
                    </h2>
                    <p>{this.context.labels.legal_sub_title}</p>
                    {this.context.labels.legal_script &&
                        RichText.render(this.context.labels.legal_script)}
                </div>

                {this.props.listOptions.mandatory_legal_options.map(option => (
                    <div className={"checkbox"}>
                        <Field type={"checkbox"}
                            id={option.key}
                            name={`mandatory.${option.key}`}
                            onChange={this.callFieldChangeFromInput}
                            data-testid={`journeyMandatoryLegal-${option.key}`} />
                           <label htmlFor={option.key}>
                            <div className={"dummy"}>
                                <IconTick wrapperClass={"dummy__tick"}/>
                            </div>
                            <span>
                                {RichText.render(option.value)}
                            </span>
                        </label>
                        {this.props.errors['mandatory']?.[option.key] && this.props.touched['mandatory']?.[option.key] ? (
                                <span className={"form__error"}>{this.props.errors['mandatory']?.[option.key]}</span>
                        ): null}
                    </div>
                ))}
                {this.props.listOptions.optional_legal_options.map(option => (
                    <div className={"checkbox"}>
                        <Field type={"checkbox"}
                            id={option.key}
                            name={`optional.${option.key}`}
                            onChange={this.callFieldChangeFromInput}
                            data-testid={`journeyOptionalLegal-${option.key}`} />
                        <label htmlFor={option.key}>
                            <div className={"dummy"}>
                                <IconTick wrapperClass={"dummy__tick"}/>
                            </div>
                            <span>
                                {RichText.render(option.value)}
                            </span>
                        </label>
                        {this.props.errors['optional']?.data && this.props.touched['optional']?.data ? (
                                <span className={"form__error"}>{this.props.errors['optional']?.data}</span>
                        ): null}
                    </div>
                ))}

                {this.props.supplierTerms
                    .filter(supplier => supplier.showSupplierTAndCs)
                    .map((supplier, idx: number) => {
                    const errors = (idx === 0) ? (
                        this.props.errors.legals?.supplier_0 && this.props.touched.legals?.supplier_0 ? (
                            <span className={"form__error"}>{this.props.errors.legals?.supplier_0}</span>
                        ): null
                    ) : (
                        this.props.errors.legals?.supplier_1 && this.props.touched.legals?.supplier_1 ? (
                            <span className={"form__error"}>{this.props.errors.legals?.supplier_1}</span>
                        ): null
                    );

                    return (
                        <div className={"checkbox"}>
                            <Field type={"checkbox"}
                                   id={`legals.supplier_${idx}`}
                                   name={`legals.supplier_${idx}`}
                                   onChange={this.callFieldChangeFromInput} 
                                   data-testid={`journeyMandatoryLegal-suplier_${idx}`} />

                            <label htmlFor={`legals.supplier_${idx}`}>
                                <div className={"dummy"}>
                                    <IconTick wrapperClass={"dummy__tick"}/>
                                </div>
                                <span>
                                    I have read and agree to the {supplier.name} <a href={supplier.tAndCsUrl} target={"_blank"} rel="noreferrer">Terms & Conditions</a>
                                </span>
                            </label>
                            {errors}
                        </div>
                    )
                })}
                {this.context.toggles.show_microbusiness_checkbox && 
                    <div className={"checkbox"}>
                        <Field type={"checkbox"}
                                id={`supplyCompany.isMicroBusiness`}
                                name={`supplyCompany.isMicroBusiness`}
                                onChange={this.callFieldChangeFromInput} 
                        />
                        <label htmlFor={"supplyCompany.isMicroBusiness"}>
                            <div className={"dummy"}>
                                <IconTick wrapperClass={"dummy__tick"}/>
                            </div>
                            <span>
                                Do you qualify as microbusiness ?
                            </span>
                        </label>
                        <DescriptionWrapper>
                            {RichText.render(this.context.labels.microbusiness_description)}
                        </DescriptionWrapper> 
                    </div>
                }
                <div className={"notice"}>
                    <TextWrapper>
                        {RichText.render(this.context.labels.legal_confirm_text)}
                    </TextWrapper>
                    {directDebitLogo.url && (
                    <ImageWrapper>
                        <img src={directDebitLogo.url} alt={directDebitLogo.alt ?? ''}/>
                    </ImageWrapper>
                    )}
                </div>
            </>
        )
    }
}

export default Legals;