import {ThunkDispatch} from "redux-thunk";
import {Action} from 'redux';
import API from "../../lib/API";

import {saveJourneyToState} from "./saveJourneyToState";
import {clearQuotes} from "./clearQuotes";

import {AppStages, JourneyForm, QuoteDetails} from "../../models/journey.model";
import {Quote} from "../../models/quote.model";
import {parseJSON} from "date-fns";
import {updateStages} from "./updateStage";


type MyRootState = {};
type MyExtraArg = undefined;
type MyThunkDispatch = ThunkDispatch<MyRootState, MyExtraArg, Action>;

export type TypeOfLoad = 'load-quotes' | 'load-swtich' | 'load-journey'

export const loadJourney = (publicId: string, typeOfLoad: TypeOfLoad) => {
    return (dispatch: MyThunkDispatch) => {
        const loadJourneyFunction = typeOfLoad === 'load-journey' 
            ? API.loadJourney 
            : API.getJourneyByPublicParam;
            
        return loadJourneyFunction(publicId)
            .then((resp: JourneyForm) => {
                resp.utilities.quoteDetails = resp.utilities.quoteDetails.map((quoteDetail: QuoteDetails) => {
                    if(quoteDetail.quote.supplier === null){
                        quoteDetail.quote = {} as Quote;
                    }

                    return quoteDetail;
                });

                if(resp.utilities.quoteDetails.length){
                    resp.quotePeriod = parseJSON(resp.utilities.quoteDetails[0].quotePeriod.start) as Date
                }

                if(resp){
                    const stages = [
                        AppStages.SupplyType,
                        AppStages.StartDate,
                        AppStages.EnergyUsage,
                        AppStages.CompanyType,
                        AppStages.CompanyLookup,
                        AppStages.AddressLookup,
                        AppStages.QuotesPage
                    ];
                    if (typeOfLoad === 'load-swtich') {
                        stages.push(AppStages.DetailsPage)
                    }
                    return Promise.all([
                        dispatch(saveJourneyToState(resp)),
                        dispatch(clearQuotes()),
                        dispatch(updateStages(stages)),
                    ]);
                } else {
                    return Promise.reject('Failed to load journey')
                }
            })
            .catch(err => {
                console.log('err', err);
                return Promise.reject('Failed to load journey')
            });
    }
}