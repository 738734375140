import { StackEnvironments } from "../../aws/configuration/config-types";

const _env: StackEnvironments =
  typeof window !== "undefined" && (window as any)?.__ENVIRONMENT_DATA__
    ? ((window as any)?.__ENVIRONMENT_DATA__ as StackEnvironments)
    : {
        REACT_APP_STACK_NAME: process.env.REACT_APP_STACK_NAME ?? "",
        REACT_APP_API_URL: process.env.REACT_APP_API_URL ?? "",
        REACT_APP_PRISMIC_DOCUMENT_ID: process.env.REACT_APP_PRISMIC_DOCUMENT_ID ?? "",
        REACT_APP_IS_CUSTOMER_PORTAL:
          process.env.REACT_APP_IS_CUSTOMER_PORTAL === "true",
        REACT_APP_IS_SALES_AGENT_PORTAL:
          process.env.REACT_APP_IS_SALES_AGENT_PORTAL === "true",
        REACT_APP_CUSTOMER_PORTAL_URL: process.env.REACT_APP_CUSTOMER_PORTAL_URL ?? "",
        REACT_APP_NAME: process.env.REACT_APP_NAME ?? "",
        REACT_APP_ANALYTICS_ID: process.env.REACT_APP_ANALYTICS_ID ?? "",
        REACT_APP_GTM_ID: process.env.REACT_APP_GTM_ID ?? "",
        REACT_APP_NO_INDEX: process.env.REACT_APP_NO_INDEX === "true",
        REACT_APP_BASE_NAME: process.env.REACT_APP_BASE_NAME ?? '/',
        REACT_APP_CUSTOMER_AFFILIATE_REDIRECTS: process.env.REACT_APP_CUSTOMER_AFFILIATE_REDIRECT?.split(",") || [],
        REACT_APP_USE_VWO: process.env.REACT_APP_USE_VWO === "true",
        REACT_APP_VWO_ID: process.env.REACT_APP_VWO_ID ?? "",
        REACT_APP_TRUSTPILOT_TEMPLATE_ID: process.env.REACT_APP_TRUSTPILOT_TEMPLATE_ID ?? "",
        REACT_APP_TRUSTPILOT_BUSINESSUNIT_ID: process.env.REACT_APP_TRUSTPILOT_BUSINESSUNIT_ID ?? "",
        REACT_APP_TRUSTPILOT_URL: process.env.REACT_APP_TRUSTPILOT_URL ?? ""
      };

export const environment = _env;
