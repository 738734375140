import React, { FunctionComponent, useContext, useEffect } from "react";
import { RichText } from "prismic-reactjs";
import { AppData } from "../providers/AppData";
import { useHistory } from "react-router-dom";
import IconTick from "../components/icons/IconTick";
import { useDispatch } from "react-redux";
import { analyticsPageView } from "../stores/analytics/actions";

export const ErrorPage: FunctionComponent = () => {
  const context = useContext(AppData);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(analyticsPageView(window.location.pathname))
  }, [dispatch]);

  return (
    <main id={"app-body"} className={"no-progress-bar"}>
      <div className={"container-full container-full--small"}>
        <div className={"completed-page"}>
          <IconTick wrapperClass={"completed-page__icon"} />
          {context.labels &&
            Array.isArray(context.labels.error_page_text) &&
            RichText.render(context.labels.error_page_text)}
          <p>
            <button
              onClick={() => history.push("/")}
              className={"button button--outline"}
            >
              Return home
            </button>
          </p>
        </div>
      </div>
    </main>
  );
};
