// Lib
import React from "react";

// TS Definitions
import { CompanyPlateProps } from "../../interfaces/CompanyName";

// Images
import IconEdit from "../icons/IconEdit";

class CompanyPlate extends React.Component<CompanyPlateProps>{
    public clearCompany = (): void => {
        if (this.props.onChangeCompany) {
            this.props.onChangeCompany();
        }
    }

    public render = (): JSX.Element => {
        return (
            <div className={"company-plate"}>
                <h3 className={"company-plate__name"}>{this.props.name}</h3>
                <h5 className={"company-plate__number"}>Company number: {this.props.number}</h5>
                <button className={"icon-button company-plate__edit"} type={"button"} onClick={ this.clearCompany.bind(this) }>
                    <span>Edit</span>
                    <IconEdit />
                </button>
            </div>
        );
    }
}

export default CompanyPlate;