import { useContext } from 'react';
import styled from 'styled-components';
import { AppData } from '../../providers/AppData';

const HeadingsWrapper = styled.div`
  padding-bottom: 2rem;
`;
const QuotesPageSubHeadings: React.FunctionComponent = () => {
  const context = useContext(AppData);
  return (
    <>
      {context.labels.quotes_page_subheading && (
        <HeadingsWrapper>
          <div className={'text-md-center'}>
            {context.labels.quotes_page_subheading}
          </div>
        </HeadingsWrapper>
      )}
    </>
  );
};

export default QuotesPageSubHeadings;
