import React, { FunctionComponent, useContext, useEffect, useState } from "react";

import { FormikHelpers, FormikState } from "formik";
import { FormInput } from "../../interfaces/Form";
import { JourneyForm, JourneyUtilityType } from "../../models/journey.model";
import { Supplier, SupplierConfig } from "../../models/supplier-config.model";
import { CurrentSupplierSelection } from "./CurrentSupplier";
import { AppData } from "../../providers/AppData";
import { AppLabelsInterface } from "../../interfaces/Labels";

const createState = (
  journeyUtilityType: JourneyUtilityType,
  electricity: Supplier | undefined,
  gas: Supplier | undefined,
  labels: AppLabelsInterface
): MultipleCurrentSupplierState => ({
  electricity: {
    enabled:
      journeyUtilityType === JourneyUtilityType.Electricity ||
      journeyUtilityType === JourneyUtilityType.DualFuel,
    value: electricity,
    title: labels.current_supplier_title,
    placeholder: labels.current_supplier_placeholder,
  },
  gas: {
    enabled:
      journeyUtilityType === JourneyUtilityType.Gas ||
      journeyUtilityType === JourneyUtilityType.DualFuel,
    value: gas,
    title: labels.current_supplier_gas_title,
    placeholder: labels.current_supplier_gas_placeholder,
  },
});

const isValidSupplier = ([_, { value }]: [string, FormState]) =>
  value?.name && value.reference;

type MultipleCurrentSupplierProps = FormInput & {
  form: FormikHelpers<JourneyForm> & FormikState<JourneyForm>;
} & { journeyUtilityType: JourneyUtilityType; suppliers: Array<SupplierConfig> };

type FormState = {
  enabled: boolean;
  value: Supplier | undefined;
  title: string;
  placeholder: string;
};
type MultipleCurrentSupplierState = { electricity?: FormState; gas?: FormState };
export const MultipleCurrentSupplier: FunctionComponent<MultipleCurrentSupplierProps> =
  ({ suppliers, form, updateGroupStatus }) => {
    const { labels } = useContext(AppData);

    const journeyUtilityType: JourneyUtilityType = form.values.journeyUtilityType;
    const [state, setState] = useState<MultipleCurrentSupplierState>(
      createState(
        journeyUtilityType,
        form.values?.currentSupplier?.electricity,
        form.values?.currentSupplier?.gas,
        labels
      )
    );

    useEffect(
      () =>
        setState(
          createState(
            journeyUtilityType,
            form.values?.currentSupplier?.electricity,
            form.values?.currentSupplier?.gas,
            labels
          )
        ),
      [
        journeyUtilityType,
        form.values?.currentSupplier?.electricity,
        form.values?.currentSupplier?.gas,
        labels,
      ]
    );

    useEffect(() => {
      if (state) {
        const stateEntries = Object.entries(state).filter(
          (entry): entry is [string, FormState] => {
            const [, value] = entry;
            return !!value && value.enabled && !!value.value;
          }
        );

        if (stateEntries.length > 0 && stateEntries.every(isValidSupplier)) {
          for (const [key, value] of Object.entries(state)) {
            form?.setFieldValue(`currentSupplier.${key}`, value?.value);
          }
          updateGroupStatus(true);
        } else {
          updateGroupStatus(false);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    return (
      <div 
        className="multiple-current-supplier" 
        data-testid="journeyMultipleCurrentSupplier-wrapper"
      >
        {state &&
          Object.entries(state)
            .filter(([_, value]) => value?.enabled)
            .map(([key, value]: [keyof MultipleCurrentSupplierState, FormState]) => (
              <CurrentSupplierSelection
                key={key}
                suppliers={suppliers}
                title={value.title}
                placeholder={value.placeholder}
                defaultSupplier={value?.value}
                onSupplierChange={(supplier) =>
                  setState((prevState) => ({
                    ...prevState,
                    [key]: { ...prevState[key], value: supplier },
                  }))
                }
              />
            ))}
      </div>
    );
  };
