import { CREATE_JOURNEY, SAVE_JOURNEY, RESTART_JOURNEY } from "../actions/types";
import { initialState } from "../initialState";
import { JourneyForm } from "../../models/journey.model";

export default function Journey(state: JourneyForm = initialState, action: any){
    switch (action.type){
        case CREATE_JOURNEY: {
            return {
                ...state,
                uid: action.payload.uid,
                type: action.payload.type,
                created: action.payload.created,
                completed: action.payload.completed,
                journeyState: action.payload.journeyState,
                saveQuote: action.payload.saveQuote,
                metadata: action.payload.metadata
            };
        }
        case SAVE_JOURNEY: {
            return {
                ...state,
                ...action.payload
            };
        }
        case RESTART_JOURNEY: {
            return {
                ...initialState,
                uid: state.uid,
                type: state.type,
                created: state.created,
                completed: state.completed,
                journeyState: state.journeyState,
                saveQuote: state.saveQuote,
                metadata: state.metadata,
                journeyUtilityType: action.payload,
                affiliate: state.affiliate,
                ipAddress: state.ipAddress
            }
        }

        default:
            return state;
    }
}