import React from "react";

import IconProps from "../../interfaces/Icon";

const IconChevron = (props: IconProps) => {
    return (
        <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" className={ props.wrapperClass || '' }>
            <polyline points="17.07 7.96 11 14.04 4.93 7.96" fill="none" stroke="#2e435b" strokeMiterlimit="10" strokeWidth="2"/>
        </svg>
    );
};

export default IconChevron;
