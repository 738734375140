import { FormInput } from '../../../../interfaces/Form';
import { FunctionComponent, useContext, useState } from 'react';
import { Button, Wrapper } from './style';
import { Journey, JourneyUtilityType, QuoteDetails, Utilities } from '../../../../models/journey.model';
import { Quote } from '../../../../models/quote.model';
import { ConsumptionPeriods } from '../../../../models/consumption-periods';
import { FuelType, LookupMetadata } from '../../../../models/utility-detail';
import { AppData } from '../../../../providers/AppData';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../stores/store';

interface EnergyUsageLookupProps {
  handleInput: (value: boolean) => void;
  formikProps: FormInput;
}

const EnergyUsageLookup: FunctionComponent<EnergyUsageLookupProps> = ({
  handleInput,
  formikProps
}: EnergyUsageLookupProps) => {
  const { labels } = useContext(AppData);
  const journey = useSelector<AppState, Journey>(state => state.journey);
  const [active, setActive] = useState<number | undefined>(undefined);

  const SetConsumptionArray = () => {
    let utilitiesArray = { quoteDetails: [] } as Utilities;
    const journeyUtilityType = formikProps.form.values.journeyUtilityType;

    if (journeyUtilityType === JourneyUtilityType.Electricity || journeyUtilityType === JourneyUtilityType.DualFuel) {
      let quoteDetail = {
        quoteType: FuelType.Electricity,
        quotePeriod: {
          start: formikProps.form.values['quotePeriod']
        },
        quote: {} as Quote,
        supplyConsumption: {
          "spend": null,
          "energy": null,
          "period": ConsumptionPeriods.Yearly,
          "eac": 0
        },
        metadata: {} as LookupMetadata
      } as QuoteDetails;

      utilitiesArray.quoteDetails.push(quoteDetail);
    }

    if (journeyUtilityType === JourneyUtilityType.Gas || journeyUtilityType === JourneyUtilityType.DualFuel) {
      let quoteDetail = {
        quoteType: FuelType.Gas,
        quotePeriod: {
          start: formikProps.form.values['quotePeriod']
        },
        quote: {} as Quote,
        supplyConsumption: {
          "spend": null,
          "energy": null,
          "period": ConsumptionPeriods.Yearly,
          "eac": 0
        },
        metadata: {} as LookupMetadata
      } as QuoteDetails;

      utilitiesArray.quoteDetails.push(quoteDetail);
    }

    if (utilitiesArray.quoteDetails.length) {
      formikProps.form?.setFieldValue('utilities', utilitiesArray);
    }

  } 

  const HandleSelection = (e: any, inputValue: boolean, value: number) => {
    e.preventDefault();
    setActive(value)

    if (inputValue) {
      SetConsumptionArray();      
    }

    formikProps.form?.setFieldValue('usageLookup', inputValue);

    handleInput(inputValue);
  }

  return (
    <>
      <label className={'form__label'}>{labels.supply_consumption_lookup_title}</label>
      <Wrapper>
        <Button
          active={active === 1 || journey.usageLookup}
          onClick={(e) => HandleSelection(e, true, 1)}
        >
          Yes
        </Button>
        <Button
          active={active === 2}
          onClick={(e) => HandleSelection(e, false, 2)}
        >
          No
        </Button>
      </Wrapper>
    </>
  );
};

export default EnergyUsageLookup;
