import React, { useContext } from "react";

import IconProps from "../../interfaces/Icon";
import { AppData } from "../../providers/AppData";

const IconGas = (props: IconProps) => {
    const context = useContext(AppData);

    const icon = context.branding.gas_icon;
    if (icon && icon.url) {
      return (
        <img
          width="80"
          height="80"
          className={props.wrapperClass || ""}
          src={icon.url}
          alt={icon.alt ?? ""}
        />
      );
    }
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" className={ props.wrapperClass || '' }>
            <g>
                <path d="M30.55,63.64c-2.88-5.67-1.34-12.85.94-15.13C33.38,58.91,39.05,57,39.05,57c-3.78-6.62-2.83-14.18,4.73-17C41,49.45,48.51,50.4,48.51,56.07a14.15,14.15,0,0,1-2.84,7.57" fill="none" strokeLinejoin="round" strokeWidth="2"/>
                <path d="M23.93,63.64A16.46,16.46,0,0,1,17.31,50.4C17.25,39.94,23.93,38.11,23.93,23c2.83,5.67,7.56,7.57,8.51,17,0-9.45,6.61-9.45,6.61-23.64,0,0,18.91,8.51,9.46,28.37,0,0,8.51-2.84,8.51-13.24,4,6.31,5.67,11.35,5.67,16.07,0,7.71-4.73,13.24-7.56,16.08" fill="none" strokeLinejoin="round" strokeWidth="2"/>
            </g>
        </svg>
    );
};

export default IconGas;