import * as Yup from 'yup';
import {testValidPhone} from "../lib/testValidPhone";

export const halfHourlyMeter = Yup.object().shape({
    name: Yup.string().required("Required"),
    email: Yup.string().required("Required").email("Please enter a valid email"),
    phoneNumber: Yup.string().required("Required").test(
        'phone-number',
        "Please enter a valid UK Phone number",
        testValidPhone
    ),
    address: Yup.string().required("Required"),
    postCode: Yup.string().required("Required"),
    mpan: Yup.string().required("Required"),
});

export const creditScoreMeter = Yup.object().shape({
    name: Yup.string().required("Required"),
    email: Yup.string().required("Required").email("Please enter a valid email"),
    phoneNumber: Yup.string().required("Required").test(
        'phone-number',
        "Please enter a valid UK Phone number",
        testValidPhone
    ),
    address: Yup.string().required("Required"),
    postCode: Yup.string().required("Required"),
    companyName: Yup.string().required("Required")
});

export interface halfHourlyMeterInterface extends Yup.Asserts<typeof halfHourlyMeter> {}