import {Address} from "../models/address.model";

export const addressFormat = (address: Address, linebreaks: boolean = false) => {
    let tempAddress = [
        address.additionalInformation || null,
        address.addressLine1 || null,
        address.addressLine2 || null,
        address.addressLine3 || null,
        address.town || null,
        address.postCode || null
    ]
    tempAddress = tempAddress.filter((value: string | null) => value);

    return tempAddress.join(linebreaks ? '\n' :', ');
}