// Lib
import React from "react";
import API from "../../../lib/API";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import AsyncSelect from "react-select/async";
import {InputActionMeta, ValueType} from "react-select";
import { animateScroll as scroll } from 'react-scroll';

// Components
import CompanyPlate from "../CompanyPlate";
import LoadingPlate from "../LoadingPlate";
import CompanyError from "../CompanyError";
import { Modal } from "react-bootstrap";
import IconClose from "../../icons/IconClose";

// TS Definitions
import { JourneyCompanyType } from "../../../interfaces/CompanyType";
import { Company, SearchCompanies } from "../../../models/company.model";
import { CompanyErrorObject, CompanyNameState } from "../../../interfaces/CompanyName";
import { AppLayoutData } from "../../../interfaces/Journey";

// Providers
import { AppData } from "../../../providers/AppData";

export type CompanyLookUpProps = {
    name: string
    companyType: JourneyCompanyType | undefined
    supplyCompany: Company
    handleSubmit: (value: Company | undefined) => void;
  }

// Debounce consts
const debouncedGetCompanyByName = AwesomeDebouncePromise(API.getCompanyByName, 500);
const debouncedGetSoleTraderByName = AwesomeDebouncePromise(API.getSoleTraderByName, 500);

class CompanyLookup extends React.Component<CompanyLookUpProps>{
    static contextType: React.Context<AppLayoutData> = AppData;
    context!: React.ContextType<typeof AppData>

    private label: React.RefObject<HTMLLabelElement> = React.createRef<HTMLLabelElement>();

    state: CompanyNameState = {
        inputValue: '',
        results : {},
        companySelected: Object.keys(this.props.supplyCompany).length !== 0,
        hasError : false,
        error : {} as CompanyErrorObject,
        selectedCompany: this.props.supplyCompany,
        loading: false,
        defaultOptions: [],
        showModal: false,
    }
    
    private forceScrollIntoVisibility = () => {
        if(this.label.current !== null){
            const offset = this.label.current.getBoundingClientRect(),
                  top = (offset.top + (document.scrollingElement?.scrollTop ?? 0));

            scroll.scrollTo(top);
        }
    }

    private getCompanies = async (companyName: string) : Promise<Array<Company>> => {
        const results : SearchCompanies = (this.props.companyType === JourneyCompanyType.SoleTrader)
                                        ? await debouncedGetSoleTraderByName(companyName)
                                        : await debouncedGetCompanyByName(companyName);

        results.searchedCompanies = results.searchedCompanies.map((result: Company) => {
            result.value = result.name;
            result.label = result.name;

            return result;
        });

        this.setState({defaultOptions: results.searchedCompanies as any}, () => this.forceScrollIntoVisibility());

        return results.searchedCompanies as any;
    }

    private getOptions = (inputValue: string): Promise<Array<Company>> => {
        return new Promise(resolve => resolve(this.getCompanies(inputValue)));
    }

    private setInputValue = (value: string, actionMeta: InputActionMeta): void => {
        if (actionMeta.action === "set-value" ||
            actionMeta.action === "input-blur" ||
            actionMeta.action === "menu-close") {
            return;
        }

        this.setState({ inputValue : value });
    }

    private getNoOptionsMessage = (obj: { inputValue: string }) => {
        if(!obj.inputValue.length){
            return null;
        }

        return "No Options";
    }

    private handleChange = async (value: ValueType<Company, boolean>): Promise<void | boolean> => {
        let selectedCompany = value as Company;

        if(value === null){
            return false;
        }

        this.setState({ loading: true });

        if(selectedCompany.companyStatus === 'dissolved'){
            this.setState({
                hasError : true,
                error: {
                    title : this.context.labels.dissolved_company_error_title,
                    message : this.context.labels.dissolved_company_error_message
                },
                selectedCompany : selectedCompany as Company,
                loading: false
            });
        } else {
            try {
                const fullCompanyDetails = await this.getFullDetails(selectedCompany);

                if(typeof fullCompanyDetails?.creditScore?.score !== 'number' || fullCompanyDetails.creditScore.score === 0){
                    throw new Error("No Credit Score found");
                }

                if(fullCompanyDetails){
                    this.setState({
                        companySelected : true,
                        selectedCompany : fullCompanyDetails as Company,
                        loading: false
                    }, () => {
                        this.props.handleSubmit(fullCompanyDetails);
                    });
                }
            } catch (e) {
                this.setState({
                    hasError : true,
                    error: {
                        title : this.context.labels.error_credit_score_title,
                        message : this.context.labels.error_credit_score_message
                    },
                    selectedCompany : selectedCompany as Company,
                    loading: false
                });
            }
        }
    }

    private getFullDetails = async (company: Company): Promise<Company> => {
        if(this.props.companyType === JourneyCompanyType.SoleTrader){
            const creditDetails = await API.creditCheckNonRegistered(company.number);
            company.creditScore = creditDetails.creditScore;
            company.isMicroBusiness = creditDetails.isMicroBusiness;
            return company;
        } else {
            return await API.getCompanyByNumber(company.number);
        }
    }

    private selectLabel = (data: any): JSX.Element => {
        let string = `
            <span>${data.label}</span>
            <span class="post-code">${data.registeredAddress.postCode ?? ""}</span>
        `;

        return(<span dangerouslySetInnerHTML={{ __html: string }} />);
    }

    private resetCompany = (): void => {
        this.setState({
            hasError : false,
            companySelected : false,
            selectedCompany : {} as Company,
        }, () => {
            this.props.handleSubmit({} as Company);
        });
    }

    private get_placeholder = (): string => {
        let placeholder = this.context.labels.company_name_placeholder_limited;
        switch(this.props.companyType){
            case JourneyCompanyType.Limited:
                placeholder = this.context.labels.company_name_placeholder_limited;
                break;
            case JourneyCompanyType.LLP:
                placeholder = this.context.labels.company_name_placeholder_llp;
                break;
            case JourneyCompanyType.SoleTrader:
                placeholder = this.context.labels.company_name_placeholder_sole_trader;
                break;
        }

        return placeholder;
    }

    private setShowModal = (val: boolean) => {
        this.setState({
            showModal: val
        })
    }

    public render = () : JSX.Element => {

        let fieldMarkup: JSX.Element | null;

        if(this.state.loading){
            fieldMarkup = <LoadingPlate />
        } else if(this.state.hasError) {
            fieldMarkup = <CompanyError {...this.props.supplyCompany}
                                        onChangeCompany={ this.resetCompany }
                                        error={this.state.error}/>
        } else if(this.state.companySelected) {
            fieldMarkup = <CompanyPlate {...this.props.supplyCompany} onChangeCompany={ this.resetCompany }/>
        } else {
            fieldMarkup = <AsyncSelect inputId="company-lookup" loadOptions={this.getOptions}
                                       defaultOptions={this.state.defaultOptions}
                                       onChange={this.handleChange}
                                       isClearable={true}
                                       className={"styled-search"}
                                       formatOptionLabel={this.selectLabel}
                                       placeholder={this.get_placeholder()}
                                       inputValue={this.state.inputValue}
                                       onInputChange={this.setInputValue}
                                       noOptionsMessage={this.getNoOptionsMessage}
                                       classNamePrefix={"styled-search"} />;
        }

        return (
          <>
            <label
              htmlFor="company-lookup"
              className={"form__label"}
              ref={this.label}
            >
              {this.context.labels.company_name_title}
              &ensp;
              <button
                className={"text-button"}
                type={"button"}
                onClick={() => this.setShowModal(true)}
              >
                {this.context.labels.company_name_selection_tooltip_title}
              </button>
            </label>
            {fieldMarkup}
            <Modal
              show={this.state.showModal}
              onHide={() => this.setShowModal(false)}
              dialogClassName="feature-modal"
              centered
            >
              <Modal.Body>
                <button
                  type={"button"}
                  className={"icon-button"}
                  onClick={() => this.setShowModal(false)}
                >
                  <IconClose />
                  <span>Close Modal</span>
                </button>
                <div>
                  <h2>
                    {this.context.labels.business_name_info_content_title}
                  </h2>
                  <p>{this.context.labels.business_name_info_content_text}</p>
                </div>
              </Modal.Body>
            </Modal>
          </>
        );
    }
}

export default CompanyLookup;