import { FormInputType } from "../interfaces/Form";
import { AppStages } from "../models/journey.model";

export const getAppStageByComponentType = (name: FormInputType): AppStages => {
    switch (name) {
        case "supply-type":
        default: // Should we throw on default? This currently will assign unknown to supply type
            return AppStages.SupplyType;
        case "supply-start-date":
            return AppStages.StartDate;
        case "supply-usage":
            return AppStages.EnergyUsage;
        case "company-details":
            return AppStages.CompanyDetails;
        case "company-address":
            return AppStages.AddressLookup;
        case "personal-details":
            return AppStages.ContactInformation;
        case "payment-details":
            return AppStages.DirectDebit;
        case "legals":
            return AppStages.Legal;
        case "contact-details":
            return AppStages.ContactDetails;
        case "current-supplier":
            return AppStages.CurrentSupplier;
        case "gocardless-payment":
            return AppStages.GoCardlessPayment;
    }
}

export const getFormInputTypeByAppStage = (name: AppStages): FormInputType | never => {
    switch (name) {
        case AppStages.SupplyType:
            return "supply-type";
        case AppStages.StartDate:
            return "supply-start-date";
        case AppStages.EnergyUsage:
            return "supply-usage";
        case AppStages.CompanyDetails:
            return "company-details";
        case AppStages.AddressLookup:
            return "company-address";
        case AppStages.ContactInformation:
            return "personal-details";
        case AppStages.DirectDebit:
            return "payment-details";
        case AppStages.Legal:
            return "legals";
        case AppStages.ContactDetails:
            return "contact-details";
        case AppStages.CurrentSupplier:
            return "current-supplier";
        case AppStages.GoCardlessPayment:
            return "gocardless-payment";
        default:
            throw new Error(`Invalid AppStages: '${name}'`);
    }
}