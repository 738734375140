// Lib
import React from "react";
import AccordionItem from "./AccordionItem";

//TS Interfaces
import { AccordionProps, AccordionState } from "../../interfaces/Accordion";

// Icons
import IconChevron from "../icons/IconChevron";

class Accordion extends React.Component<AccordionProps, AccordionState> {
    state: AccordionState = {
        currentIdx: this.props.defaultIndex
    }

    public componentDidUpdate(prevProps: Readonly<AccordionProps>, prevState: Readonly<AccordionState>, snapshot?: any) {
        if(prevProps.defaultIndex !== this.props.defaultIndex){
            this.setState({currentIdx: this.props.defaultIndex});
        }
    }

    public change = (index: number) => {
        this.setState({currentIdx: (index === this.state.currentIdx) ? null : index});
    }

    public render = () => {
        const nodes = React.Children.map(this.props.children, (child: React.ReactElement, index) => {
            const wrapperClass = (index === this.state.currentIdx) ? "accordion__item open" : "accordion__item";

            if(!child){
                return child;
            }

            if(child.type === AccordionItem){
                return (
                    <div className={wrapperClass}>
                        <div className={"accordion__toggle"} onClick={() => this.change(index)}>
                            {child.props.title}
                            <IconChevron wrapperClass={"accordion__chevron"}/>
                        </div>
                        <div className={"accordion__content"}>
                            {child}
                        </div>
                        {typeof child.props.subtotal !== "undefined"
                            ? (
                                <dl className={"details details--inline"}>
                                    <div>
                                        <dt>Monthly Total Cost:</dt>
                                        <dd>
                                            {child.props.subtotal}
                                        </dd>
                                    </div>
                                </dl>
                            ) : null
                        }
                    </div>
                )
            } else {
                return child;
            }
        });

        return <div className={"accordion"}>{nodes}</div>
    }
}

export default Accordion;