// Lib
import React from "react";
import DatePicker from "react-datepicker";
import { addMonths, format, addBusinessDays } from "date-fns";
import { Modal } from "react-bootstrap";

// TS Definitions
import { FormInput } from "../../interfaces/Form";
import { AppLayoutData } from "../../interfaces/Journey";

// Icons
import IconChevron from "../icons/IconChevron";
import IconClose from "../icons/IconClose";

// Providers
import { AppData } from "../../providers/AppData";
import { Journey, JourneyUtilityType } from "../../models/journey.model";
import { FeatureToggle } from "../misc/FeatureToggle";

import NewConnection from "./CompanyLookup/NewConnection";
import styled from "styled-components";

interface StartDateState {
  showModal: boolean;
  showNewConnectionModal: boolean;
  isDateSelected: boolean;
  datesToExclude: Date[];
}

class StartDate extends React.Component<FormInput & { journey: Journey }> {
  static contextType: React.Context<AppLayoutData> = AppData;

  state: StartDateState = {
    showModal: false,
    showNewConnectionModal: false,
    isDateSelected: false,
    datesToExclude: [
      new Date('2022-07-19'),
      new Date('2022-07-20'),
      new Date('2022-07-21'),
    ]
  };

  public getWorkingDays = (daysFromPrismic: number, today: Date) => {
    // We need to add +1 day to prismic value because we need to get next working day after that
    return addBusinessDays(today, daysFromPrismic+1);
  }

  public checkGroupState = (
    name: string,
    value: Date | [Date, Date] | null
  ): void => {
    this.props.form?.setFieldValue(name, value);
    this.props.updateGroupStatus(value !== null);
  };

  public componentDidMount = (): void => {
    setTimeout(
      () => this.props.updateGroupStatus(this.props.field?.value !== null),
      0
    );
  };

  public renderHeader = ({
    date,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }: any): JSX.Element => {
    return (
      <>
        <div className="react-datepicker__current-month">
          {format(date, "LLLL yyyy")}
        </div>
        <button
          onClick={decreaseMonth}
          type={"button"}
          className={
            "react-datepicker__navigation react-datepicker__navigation--previous"
          }
          disabled={prevMonthButtonDisabled}
        >
          <IconChevron />
        </button>
        <button
          onClick={increaseMonth}
          type={"button"}
          className={
            "react-datepicker__navigation react-datepicker__navigation--next"
          }
          disabled={nextMonthButtonDisabled}
        >
          <IconChevron />
        </button>
      </>
    );
  };

  public render = (): JSX.Element => {

    const journeyUtilityType =
      this.props?.form?.values?.journeyUtilityType ??
      this.props?.journey?.journeyUtilityType ??
      JourneyUtilityType.Gas;

    const minimumDays =
      journeyUtilityType === JourneyUtilityType.Electricity
        ? this.context.variables.electricity_minimum_days
        : this.context.variables.gas_minimum_days;
    return (
      <>
        <label className={"form__label"} htmlFor={this.props.field?.name}>
          {this.context.labels.supply_start_date_title}
          &ensp;
          <button
            onClick={() => this.setState({ showModal: true })}
            type={"button"}
            className={"text-button"}
          >
            {this.context.labels.supply_start_date_info_content}
          </button>
        </label>

        <div
          className={
            this.props.field?.value === null
              ? "inline-datepicker"
              : "datepicker"
          }
        >
          <DatePicker
            id={this.props.field?.name}
            name={this.props.field?.name}
            selected={this.props.field?.value}
            className="form__control"
            minDate={this.getWorkingDays(minimumDays, new Date())}
            maxDate={addMonths(this.getWorkingDays(minimumDays, new Date()), 12)}
            dateFormat="do MMMM yyyy"
            autoComplete={"off"}
            wrapperClassName={"test"}
            disabledKeyboardNavigation
            inline={this.props.field?.value === null}
            excludeDates={this.state.datesToExclude}
            onChange={(ev: Date) => {
              const dateWithoutTimezone = new Date(
                Date.UTC(ev.getFullYear(), ev.getMonth(), ev.getDate())
              );
              this.checkGroupState(
                this.props.field?.name || "",
                dateWithoutTimezone
              );
              this.setState({ isDateSelected: true });
            }}
            renderCustomHeader={this.renderHeader}
          />

          <FeatureToggle
            name={"show_asap_start_date"}
            fallback={false}
            render={
              <button
                className={"button react-datepicker-wrapper asap-button"}
                onClick={() => {
                  this.checkGroupState(
                    this.props.field?.name || '',
                    this.getWorkingDays(minimumDays, new Date())
                  );
                  this.setState({ isDateSelected: true });
                }}
                type={"button"}
              >
                {this.context.labels.supply_start_date_asap ??
                  "As soon as possible"}
              </button>
            }
            otherwiseRender={<></>}
          />
          <FeatureToggle
            name={"ask_for_new_connection"}
            fallback={false}
            render={
              <NewConnection
                showNewConnectionComponent={this.state.isDateSelected}
                journey={this.props.form.values}
              />
            }
            otherwiseRender={<></>}
          />
        </div>
        <Modal
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: false })}
          dialogClassName="feature-modal"
          centered
        >
          <Modal.Body>
            <button
              type={"button"}
              className={"icon-button"}
              onClick={() => this.setState({ showModal: false })}
            >
              <IconClose />
              <span>Close Modal</span>
            </button>
            <div>
              <h2>
                {this.context.labels.supply_start_date_info_content ||
                  "Please allow 14 days"}
              </h2>
              <p>{this.context.labels.supply_start_date_info_title}</p>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  };
}

export default StartDate;
