import React, { useContext } from "react";
import { AppData } from "../../providers/AppData";
import IconTick from "./../icons/IconTick";
import { GoCardlessPaymentProps } from "../../interfaces/Form";
import { Field } from "formik";
import styled from "styled-components";

const FormWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  & > :first-child {
    padding-bottom: 1rem;
  }

  @media (min-width: 992px) {
    flex-direction: row;
    align-items: center;

    & > :first-child {
      padding-bottom: unset;
    }
  }
`;

export const GoCardlessPayment: React.FunctionComponent<GoCardlessPaymentProps> = (
  props
) => {
  const { labels } = useContext(AppData);

  const callFieldChangeFromInput = (name: string, value: any): void => {
    props.onFieldChange(name ?? "", value);
  };

  return (
    <>
      <div className={"form__intro"}>
        <h2>
          <IconTick wrapperClass={"icon"} />
          {labels.gocardless_payment_title ?? "Account Management Service"}
        </h2>
        <p>
          {labels.gocardless_payment_sub_title ??
            "Instead of adding commission onto the energy rates you pay we instead have a set-up fee which also gives you access to our account management services, leave the hassle of switching your business energy to us!"}
        </p>
      </div>
      <FormWrapper>
        <div className={"checkbox"}>
          <Field
            type={"checkbox"}
            id={"sign-up-recurring-payments"}
            name={"payments.gocardless.recurringPayment"}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              callFieldChangeFromInput(
                "payments.gocardless.recurringPayment",
                event.target.checked
              )
            }
          />
          <label htmlFor={"sign-up-recurring-payments"}>
            <div className={"dummy"}>
              <IconTick wrapperClass={"dummy__tick"} />
            </div>
            <span>
              {labels.gocardless_payment_recurring_payment_text ??
                "I agree to the set up and management fee"}
            </span>
          </label>
        </div>
      </FormWrapper>
    </>
  );
};
