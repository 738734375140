// Lib
import React from 'react';
import { animateScroll as scroll } from 'react-scroll';
// Components
import AddressPlate from '.././AddressPlate';

// TS Definitions
import { InputActionMeta, ValueType } from 'react-select';
import {
  Address,
  AddressSearchType,
  AddressDetail,
  DualAddressComponentState,
} from '../../../models/address.model';
import { FormInput } from '../../../interfaces/Form';
import {
  getElectricity,
  getGas,
  Journey,
  JourneyUtilityType,
} from '../../../models/journey.model';
import { AppLayoutData } from '../../../interfaces/Journey';

// Providers
import { AppData } from '../../../providers/AppData';
import { hidePartialMpxnString } from '../../../lib/string-replacement';
import { FeatureToggle } from '../../misc/FeatureToggle';
import AddressSelect from './AddressSelect';
import InputTypeBtn from './InputTypeBtn';
class DualFuelAddressLookup extends React.Component<
  FormInput & { journey: Journey },
  DualAddressComponentState
> {
  static contextType: React.Context<AppLayoutData> = AppData;
  context!: React.ContextType<typeof AppData>;

  private label: React.RefObject<HTMLLabelElement> =
    React.createRef<HTMLLabelElement>();

  state: DualAddressComponentState = {
    inputValue: this.props.form.values.supplyAddress.postCode,
    results: {},
    elecAddressSelected: !!(
      this.props.field?.value !== null &&
      Object.values(this.props.field?.value).length
    ),
    gasAddressSelected: false,
    selectedGasAddress:
      this.props.field?.value !== null &&
      Object.values(this.props.field?.value).length
        ? this.props.field?.value
        : ({} as Address),
    selectedElecAddress:
      this.props.field?.value !== null &&
      Object.values(this.props.field?.value).length
        ? this.props.field?.value
        : ({} as Address),
    journeySaved: false,
    elecType: AddressSearchType.Postcode,
    gasType: AddressSearchType.Postcode,
    defaultOptions: [],
    isSmartMeter: false,
    inputData: [
      {
        fuelType: JourneyUtilityType.Electricity,
        show: true,
      },
      {
        fuelType: JourneyUtilityType.Gas,
        show: this.props.form.values.utilities.quoteDetails[0].hasOwnProperty(
          'metadata'
        ),
      },
    ]
  };

  public componentDidMount = (): void => {
    setTimeout(() => {
      this.props.updateGroupStatus(
        this.props.field?.value !== null &&
          !!Object.values(this.props.field?.value).length
      );
    }, 0);
  };

  private forceScrollIntoVisibility = () => {
    if (this.label.current !== null) {
      const offset = this.label.current.getBoundingClientRect(),
        top = offset.top + (document.scrollingElement?.scrollTop ?? 0);

      scroll.scrollTo(top);
    }
  };

  private handleChange = (
    selectedOption: ValueType<AddressDetail, boolean>
  ): void => {
    const selected = selectedOption as AddressDetail;
    if (selected && selected.address.udprn.trim().length === 0) {
      this.setState(
        {
          elecAddressSelected: true,
          selectedElecAddress: selected.address as Address,
          inputData: [
            {
              ...this.state.inputData[0],
              show: true,
            },
            {
              ...this.state.inputData[1],
              show: true,
            },
          ],
        },
        () => {
          this.props.form?.setFieldValue(
            this.props.field?.name || 'supplyAddress',
            selected.address as Address
          );

          const elec = getElectricity(this.props.journey.utilities);
          if (elec) {
            elec.metadata = {
              mpan: selected.address.mpxn,
              addressId: selected.address.addressId,
              mprn: undefined,
            };
          }

          this.updateStatus();
        }
      );
    }

    if (selected && selected.address.udprn.trim().length > 0) {
      this.setState(
        {
          ...this.state,
          gasAddressSelected: true,
          selectedGasAddress: selected.address as Address,
        },
        () => {
          this.props.form?.setFieldValue(
              'gasSupplyAddress',
              selected.address as Address
          );
          
          const gas = getGas(this.props.journey.utilities);
          if (gas) {
            gas.metadata = {
              mpan: undefined,
              addressId: selected.address.addressId,
              mprn: selected.address.mpxn,
            };
          }
          
          this.updateStatus();
        }
      );
    }
  };

  private updateStatus(){
    if(this.state.elecAddressSelected && this.state.gasAddressSelected){
      this.props.updateGroupStatus(true);
    }
  }

  private resetElecAddress = (): void => {
    this.setState(
      {
        elecAddressSelected: false,
        selectedElecAddress: {} as Address,
      },
      () => {
        this.props.form?.setFieldValue(
          this.props.field?.name || 'supplyAddress',
          {} as Address
        );
        this.props.updateGroupStatus(false);
      }
    );
  };

  private resetGasAddress = (): void => {
    this.setState(
      {
        gasAddressSelected: false,
        selectedGasAddress: {} as Address,
      },
      () => {
        this.props.form?.setFieldValue(
          'gasSupplyAddress',
          {} as Address
        );
        this.props.updateGroupStatus(false);
      }
    );
  };

  private selectLabel = (detail: AddressDetail): JSX.Element => {
    if (detail.address.mpxn && detail.showMPAN) {
      return (
        <span>
          <span>{detail.label}</span>
          <span className="post-code">
            {'MPAN: '}
            {hidePartialMpxnString(detail.address.mpxn)}
          </span>
        </span>
      );
    }

    if (detail.address.mpxn && detail.showMPAN) {
      return (
        <span>
          <span>{detail.label}</span>
          <span className="post-code">
            {'MPRN: '}
            {hidePartialMpxnString(detail.address.mpxn)}
          </span>
        </span>
      );
    }

    return (
      <span>
        <span>{detail.label}</span>
      </span>
    );
  };

  private changeType = (
    type: AddressSearchType,
    inputFuelType: number
  ): void => {
    if (inputFuelType === JourneyUtilityType.Electricity) {
      this.setState({
        elecType:
          type === AddressSearchType.MPAN
            ? AddressSearchType.Postcode
            : AddressSearchType.MPAN,
        inputValue: '',
        elecAddressSelected: false,
        defaultOptions: [],
        selectedElecAddress: {} as Address,
      });
      this.props.updateGroupStatus(false);
      this.resetElecAddress();
    }

    if (inputFuelType === JourneyUtilityType.Gas) {
      this.setState({
        gasType:
          type === AddressSearchType.MPAN
            ? AddressSearchType.Postcode
            : AddressSearchType.MPAN,
        inputValue: '',
        gasAddressSelected: false,
        defaultOptions: [],
        selectedGasAddress: {} as Address,
      });
      this.props.updateGroupStatus(false);
      this.resetGasAddress();
    }
  };

  private setInputValue = (
    value: string,
    actionMeta: InputActionMeta
  ): void => {
    if (
      actionMeta.action === 'set-value' ||
      actionMeta.action === 'input-blur' ||
      actionMeta.action === 'menu-close'
    ) {
      return;
    }

    this.setState({ inputValue: value });
  };

  private getMarkup = (fuelType: JourneyUtilityType): any => {
    if (fuelType === JourneyUtilityType.Electricity) {
      const placeholder =
        this.state.elecType === AddressSearchType.Postcode
          ? this.context.labels.supply_address_placeholder_postcode
          : this.context.labels.supply_address_placeholder_mpan ?? 'Enter MPAN';

      const selectPlaceholder = this.state.gasType === AddressSearchType.Postcode
          ? "Select your address"
          : "Select your MPAN";

      if (!this.state.elecAddressSelected) {
        return (
          <>
              <AddressSelect
              fuelType={fuelType}
              searchType={this.state.elecType}
              handleChange={this.handleChange}
              formatOptionLabel={this.selectLabel}
              placeholder={placeholder}
              selectPlaceholder={selectPlaceholder}
              passedInputValue={this.state.inputValue}
              getInputChange={this.setInputValue}
              forceScrollIntoVisibility={this.forceScrollIntoVisibility}
            />
          </>
        );
      } else {
        return (
          <AddressPlate
            {...this.state.selectedElecAddress}
            onChangeAddress={this.resetElecAddress}
          />
        );
      }
    }

    if (fuelType === JourneyUtilityType.Gas) {
      const placeholder =
        this.state.gasType === AddressSearchType.Postcode
          ? this.context.labels.supply_address_placeholder_postcode
          : this.context.labels.supply_address_placeholder_mprn ?? 'Enter MPRN';

      const selectPlaceholder = this.state.gasType === AddressSearchType.Postcode
        ? "Select your address"
        : "Select your MPRN";

      const passedValue =
        this.state.elecType === AddressSearchType.Postcode
          ? this.state.inputValue
          : '';

      if (!this.state.gasAddressSelected) {
        return (
          <AddressSelect
            fuelType={fuelType}
            searchType={this.state.gasType}
            handleChange={this.handleChange}
            formatOptionLabel={this.selectLabel}
            placeholder={placeholder}
            selectPlaceholder={selectPlaceholder}
            passedInputValue={passedValue}
            getInputChange={this.setInputValue}
            forceScrollIntoVisibility={this.forceScrollIntoVisibility}
          />
        );
      } else {
        return (
          <AddressPlate
            {...this.state.selectedGasAddress}
            onChangeAddress={this.resetGasAddress}
          />
        );
      }
    }
  };

  public render = (): JSX.Element => {
    return (
      <>
        {this.state.inputData.map((field: any) => (
          <>
            {field.show && (
              <>
                <label
                  htmlFor="address-lookup"
                  className={'form__label'}
                  ref={this.label}
                >
                  {field.fuelType === JourneyUtilityType.Electricity
                    ? this.context.labels.supply_address_title_electricity
                    : this.context.labels.supply_address_title_gas}
                  &ensp;
                  <InputTypeBtn
                    fuelType={field.fuelType}
                    elecType={this.state.elecType}
                    gasType={this.state.gasType}
                    changeType={this.changeType}
                  />
                </label>
                <FeatureToggle
                  name={'show_smart_meter_text'}
                  fallback={false}
                  render={
                    <>
                      {this.state.isSmartMeter && (
                        <div className={'smart-energy-meter-text'}>
                          {this.context.labels.smart_energy_meter_text}
                        </div>
                      )}
                    </>
                  }
                  otherwiseRender={<></>}
                />
                {this.getMarkup(field.fuelType)}
              </>
            )}
          </>
        ))}
      </>
    );
  };
}

export default DualFuelAddressLookup;
