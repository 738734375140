import { Address } from './address.model';
import { Company } from './company.model';
import { Consumption } from './consumption.model';
import { ContactInformation } from './contact-information.model';
import { DirectDebit } from './direct-debit.model';
import { QuotePeriod } from './quote-period.model';
import { Quote } from './quote.model';
import { FuelType, IUtilityDetail, LookupMetadata } from './utility-detail';
import { ConsumptionTypes } from "./consumption-types";
import { Supplier } from './supplier-config.model';
import { JourneyCompanyType } from '../interfaces/CompanyType';

export interface Journey {
    type: string;
    uid: string | null;
    created: Date | null;
    completed: boolean;
    supplyAddress: Address;
    gasSupplyAddress: Address;
    supplyCompany: Company;
    contactInformation: ContactInformation;
    directDebit: DirectDebit;
    utilities: Utilities;
    journeyUtilityType: JourneyUtilityType | null;
    affiliate: string;
    stage: Stage;
    consent: Consent;
    legals: {
        mandatory: Record<string, boolean>,
        optional: Record<string, boolean>,
    };
    ipAddress: string;
    currentSupplier: CurrentSupplier;
	quotePeriod : Date | null;
    payments: Payments,
    journeyState: JourneyState,
    quotes: JourneyQuotes,
    saveQuote: SaveQuote,
    companyType: JourneyCompanyType | undefined,
    metadata: JourneyMetadata,
    usageLookup: boolean
}

export interface JourneyForm extends Journey{
	quotePeriod : Date | null;
	supplyUsage : Consumption[];
    utilityDetails : IUtilityDetail[];
    supplyConsumptionType : ConsumptionTypes;
}

export interface JourneyHelper {
	quotePeriod : Date;
	supplyUsage : Consumption[];
}


export function getElectricity(utilities: Utilities) {
    return utilities?.quoteDetails?.find(quoteDetail => quoteDetail.quoteType === FuelType.Electricity);
}

export function getGas(utilities: Utilities) {
    return utilities?.quoteDetails?.find(quoteDetail => quoteDetail.quoteType === FuelType.Gas);
}

export function getAddressElectricity(utilities: IUtilityDetail[]){
    return utilities?.find(utilityDetail => utilityDetail.fuelType === FuelType.Electricity);
}

export function getAddressGas(utilities: IUtilityDetail[]) {
    return utilities?.find(utilityDetail => utilityDetail.fuelType === FuelType.Gas);
}

export interface Utilities {
    quoteDetails: Array<QuoteDetails>;
}

export interface QuoteDetails {
    metadata: LookupMetadata;
    quoteType: FuelType;
    quotePeriod: QuotePeriod;
    quote: Quote;
    supplyConsumption: Consumption;
}

// export class ElectricityQuoteDetails implements QuoteDetails {
//     quoteType = FuelType.Electricity;
//     quotePeriod: QuotePeriod;
//     quote: Quote;
//     supplyConsumption: Consumption;
//     utilityDetails: IUtilityDetail;
// }
//
// export class GasQuoteDetails implements QuoteDetails {
//     quoteType = FuelType.Gas;
//     quotePeriod: QuotePeriod;
//     quote: Quote;
//     supplyConsumption: Consumption;
//     utilityDetails: IUtilityDetail;
// }

export interface MeterDetails {
    meterSerialNumber: string;
}

export enum JourneyUtilityType {
    Electricity,
    Gas,
    DualFuel
}

export interface Stage {
    latestStage: string;
}

export interface Consent {
    acceptedCookies: boolean;
}

export enum Stages {
    SupplyType = 0,
    SupplyAddress = 1,
    Usage = 2,
    Company = 3,
    StartDate = 4,
    QuoteSelect = 5,
    ContactInformation = 6, // new stepper
    DirectDebit = 7,
    Legal = 8,
    Summary = 9
}

export enum AppStages {
    SupplyType,
    StartDate,
    EnergyUsage,
    CompanyDetails,
    CompanyType,
    CompanyLookup,
    AddressLookup,
    QuotesPage,
    DetailsPage,
    ContactInformation,
    DirectDebit,
    Legal,
    ContactDetails,
    CurrentSupplier,
    GoCardlessPayment
}
// QuotesPage,
// Details,
// Complete

export type CurrentSupplier = {
    electricity?: Supplier;
    gas?: Supplier;
}

export type GoCardlessPayments = {
    paidStatus: GoCardlessPaidStatus,
    recurringPayment: boolean,
    metadata: {
        billingRequestFlowId: string | undefined
        billingRequestId?: string | undefined
        customerId?: string | undefined
    }
}
export type GoCardlessPaidStatus = "unset" | "paid" | "error"
export type Payments = {
    gocardless: GoCardlessPayments
}

export enum JourneyStates {
    Started,
    Tracked,
    Abandoned
}
export type JourneyState = {
    externalId: string,
    state: JourneyStates,
    sentWebQuote: boolean
}

export type JourneyQuotes = {
    electricityQuotes: Array<Quote>;
    gasQuotes: Array<Quote>;
}

export type SaveQuote = {
    identifier: string | undefined;
    publicUrl: string | undefined;
    salesPublicUrl: string | undefined;
}

export type JourneyMetadata = {
    stackInformation: StackInformation | undefined;
    switchStatus: string | undefined;
    ignitionAccountId?: string | undefined;
}

export type StackInformation = {
    url: string | undefined;
    name: string | undefined;
    prismicId: string;
}