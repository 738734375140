import {client} from "../prismic-configuration";
import Prismic from "prismic-javascript";
import {formatAppData} from "./formatAppData";
import {AppLayoutData} from "../interfaces/Journey";
import ApiSearchResponse from "prismic-javascript/types/ApiSearchResponse";

const cachedIds = new Map<string, AppLayoutData>()
export const getAppLayout = async (prismicId: string): Promise<AppLayoutData | undefined>  => {
    if (!cachedIds.has(prismicId)) {
        const appLayoutData = await get(prismicId);
        cachedIds.set(prismicId, appLayoutData);
    }
    
    return cachedIds.get(prismicId);
}

async function get(id: string) {
    const response = await client.query([
        Prismic.Predicates.at('document.id', id ?? '')
      ],
      { }
    )
    return formatAppData(response);
}