import TagManager from "react-gtm-module";
import ReactGA from "react-ga";
import { ThunkDispatch } from "redux-thunk";
import { AppState } from "../store";
import {
  Actions,
  AnalyticsEvent,
  AnalyticsState,
  EcommerceEvent,
  EventEvent,
  Identifiers,
  PageViewEvent,
} from "./models";

const makeAnalyticsEventThunk =
  <T extends AnalyticsEvent>(type: T["type"]) =>
  (payload: T["payload"]) =>
  (
    dispatch: ThunkDispatch<AnalyticsState, void, Actions>,
    getState: () => AppState
  ) => {
    dispatch({
      type: "ANALYTICS_ADD_EVENT",
      payload: { id: Symbol(), type: type, payload: payload },
      // TODO: We should not need to cast here
    } as Actions);

    CompleteEvents(dispatch, getState());
  };

export const analyticsPageView = makeAnalyticsEventThunk<PageViewEvent>("pageView");
export const analyticsEvent = makeAnalyticsEventThunk<EventEvent>("event");
export const analyticsEcommerce = makeAnalyticsEventThunk<EcommerceEvent>("ecommerce");

export const initializeAnalytics =
  ({ googleAnalyticsId, googleTagMangerId }: Identifiers) =>
  (
    dispatch: ThunkDispatch<AnalyticsState, void, Actions>,
    getState: () => AppState
  ) => {
    if (googleAnalyticsId) {
      ReactGA.initialize(googleAnalyticsId);
    }
    if (googleTagMangerId) {
      TagManager.initialize({ gtmId: googleTagMangerId });
    }

    dispatch({
      type: "ANALYTICS_INITIALIZED",
      payload: { googleAnalyticsId, googleTagMangerId },
    });

    CompleteEvents(dispatch, getState());
  };

function CompleteEvents(
  dispatch: ThunkDispatch<AnalyticsState, void, Actions>,
  state: AppState
): void {
  if (!state.analytics.initialized || !state.analytics.identifiers.googleAnalyticsId) {
    return;
  }

  const processedEventIds = [];
  for (const event of state.analytics.eventsToProcess) {
    switch (event.type) {
      case "pageView": {
        ReactGA.pageview(event.payload);
        processedEventIds.push(event.id);
        break;
      }
      case "event": {
        ReactGA.event({
          category: "Quote Step",
          action: event.payload,
          label: "Forward",
        });
        processedEventIds.push(event.id);
        break;
      }
      case "ecommerce": {
        ReactGA.plugin.require("ecommerce");
        ReactGA.plugin.execute("ecommerce", "addTransaction", {
          id: event.payload.id,
          revenue: event.payload.revenue,
        });
        for (const product of event.payload.products) {
          ReactGA.plugin.execute("ecommerce", "addItem", {
            id: product.id,
            name: product.name,
            price: product.price,
            category: product.category,
            quantity: product.quantity,
          });
        }
        ReactGA.plugin.execute("ecommerce", "send", null);
        ReactGA.plugin.execute("ecommerce", "clear", null);
        processedEventIds.push(event.id);
        break;
      }
    }
  }

  dispatch({ type: "ANALYTICS_PROCESSED_EVENTS", payload: processedEventIds });
}
