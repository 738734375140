import { useState, useEffect } from "react";

export function useCountdown(minutesToCountdownFrom: number) {
  const [minutes, setMinutes] = useState('00');
  const [seconds, setSeconds] = useState('00');
  const [state, setState] = useState<'countdown' | 'finished'>('countdown');

  useEffect(() => {
    const future = new Date(new Date().getTime() + minutesToCountdownFrom * 1000 * 60);

    const interval = setInterval(() => {
      const now = new Date();
      const difference = future.getTime() - now.getTime();

      if (difference <= 0) {
        clearInterval(interval);
        setState('finished');
        return;
      }

      const m = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      setMinutes(`${m}`.padStart(2, '0'));

      const s = Math.floor((difference % (1000 * 60)) / 1000);
      setSeconds(`${s}`.padStart(2, '0'));
    }, 1000);

    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { minutes, seconds, state }
}