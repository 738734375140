// Lib
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Formik, Field, Form, FormikValues } from "formik";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

// Components
import AddressLookup from "../components/journey/AddressLookup";
import SupplyType from "../components/journey/SupplyType";
import StartDate from "../components/journey/StartDate";
import EnergyUsage from "../components/journey/EnergyUsage/EnergyUsage";
import Spinner from "../components/icons/Spinner";
import ProgressBar from "../components/ProgressBar";
import Header from "../components/header/Header";
import IconTick from "../components/icons/IconTick";
import LargeBanner from "../components/banners/LargeBanner";
import CompanyDetails from "../components/journey/CompanyDetails";

// Actions
import { updateJourney } from "../stores/actions/updateJourney";
import { createJourney } from "../stores/actions/createJourney";
import { updateStage } from "../stores/actions/updateStage";
import { analyticsPageView, analyticsEvent } from "../stores/analytics/actions";

// TS Definitions
import { FormInput } from "../interfaces/Form";
import {
  JourneyPageLayout,
  AppLayoutData,
} from "../interfaces/Journey";
import {
  AppStages,
  Journey,
  JourneyForm,
  JourneyStates,
} from "../models/journey.model";
import { ShouldShowModalResult } from "../lib/ShouldShowModal";

// Helpers
import { getAppStageByComponentType } from "../lib/getAppStageByComponentType";

//Providers
import { AppData } from "../providers/AppData";
import { arrayContentsEqual } from "../lib/array-extensions";
import { FeatureToggle } from "../components/misc/FeatureToggle";
import { ContactDetails } from "../components/journey/ContactDetails";
import { MultipleCurrentSupplier } from "../components/journey/MultipleCurrentSupplier";
import { tryGetLastCompletedStageName } from "../lib/tryGetLastCompletedStageName";
import ProgressStepsBar from "../components/ProgressStepsBar";

import styled from "styled-components";

const mapStateToProps = (state: any) => state;
const dispatchProps = {
  updateJourney,
  createJourney,
  updateStage,
  analyticsPageView,
  analyticsEvent,
};
// TODO: Fix any
const connector = connect<any, typeof dispatchProps>(mapStateToProps, dispatchProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type JourneyPageProps = PropsFromRedux & RouteComponentProps & JourneyPageLayout;

class JourneyPage extends React.Component<
  JourneyPageProps & { journey: Journey },
  { components: FormInput[],
    showContactSalesModal: ShouldShowModalResult,
    modalCustomerData: Journey | undefined,
  }
> {
  static contextType: React.Context<AppLayoutData> = AppData;
  context!: React.ContextType<typeof AppData>;

  private progressBar: React.RefObject<ProgressBar> = React.createRef<ProgressBar>();
  private formElement: React.RefObject<HTMLFormElement> =
    React.createRef<HTMLFormElement>();
  private formActions: React.RefObject<HTMLDivElement> =
    React.createRef<HTMLDivElement>();
  private animTimeout: ReturnType<typeof setTimeout> | null = null;

  constructor(props: JourneyPageProps) {
    super(props);
    this.state = {
      components: props.components.map((component: FormInput) => {
       
        const appStage = getAppStageByComponentType(component.type);
        component.updateGroupStatus = (state: boolean) => {
          this.props.updateStage(appStage, state).then(() => {
            if (state) {
              this.checkProgress(component.name);
            }
          });
        };
        return component;
      }),
      showContactSalesModal: {
        show: false,
        type: undefined
      },
      modalCustomerData: undefined,
    };
  }

  public componentDidMount() {
    this.props.analyticsPageView(window.location.pathname);

    if (document.scrollingElement !== null) {
      document.scrollingElement.scrollTop = 0;
    }

    if (this.props.journey.uid === null) {
      this.props.createJourney();
    }
  }

  componentDidUpdate(prevProps: JourneyPageProps) {
    if (!arrayContentsEqual(this.props.completedStages, prevProps.completedStages)) {
      const [success, name] = tryGetLastCompletedStageName(
        this.props as { completedStages: AppStages[] }
      );
      if (success) {
        this.props.analyticsEvent(name);
      }
    }
  }

  private handleSubmit = (values: FormikValues, { setSubmitting }: any): void => {
    const journey = values as Journey;

    if (
      journey.contactInformation.email &&
      journey.contactInformation.emailCheckBox &&
      journey.uid
    ) {
      journey.journeyState.state = JourneyStates.Tracked;
    }

    this.props
      .updateJourney(journey as Journey, true)
      .then(() => {
        setSubmitting(false);

        if (this.props.isLastPage) {
          this.props.updateStage(AppStages.QuotesPage, true);
          this.props.history.push("/results");
        }

        if (typeof this.props.nextPage !== "undefined" && this.props.nextPage.length) {
          this.props.history.push(this.props.nextPage);
        }
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  private checkProgress = (componentName: string): void => {
    this.moveProgressBar(componentName);
    this.triggerScroll();
  };

  private triggerScroll = (): void => {
    if (this.formElement.current !== null) {
      const uncompletedSteps = this.formElement.current.querySelectorAll(
        ".form__group:not(.form__group--completed)"
      );
      const element = !uncompletedSteps.length
        ? this.formActions?.current
        : uncompletedSteps[0];

      if (element !== null) {
        this.animTimeout = setTimeout(() => {
          const offset = element?.getBoundingClientRect(),
            top = (offset?.top ?? 0) + (document.scrollingElement?.scrollTop ?? 0);

          scroll.scrollTo(top);

          const inputs = element?.querySelectorAll(".form__control");
          if (inputs.length) {
            const input = inputs[0] as HTMLElement;

            input.focus();
          }
        }, 10);
      }
    }
  };

  private moveProgressBar = (componentName: string): void => {
    let currentComponent: FormInput | undefined;
    let currentIdx: number;

    const components = this.context.pages.map(
      (page: JourneyPageLayout) => page.components
    );
    const allComponents = ([] as Array<FormInput>).concat(...components);

    currentComponent = allComponents.find(
      (component: FormInput) => component.name === componentName
    );

    if (typeof currentComponent !== undefined) {
      currentIdx = allComponents.indexOf(currentComponent as FormInput);

      this.progressBar?.current?.updateProgress(
        (currentIdx + 1) * (100 / allComponents.length),
        ".3s"
      );
    }
  };

  private renderJourneyStep = (component: FormInput, idx: number): JSX.Element => {
    const appStage = getAppStageByComponentType(component.type);
    const className =
      this.props.completedStages.indexOf(appStage) !== -1
        ? "form__group form__group--completed"
        : "form__group";

    let field = null;

    switch (component.type) {
      case "supply-type": {
        component.name = "journeyUtilityType";
        field = <Field {...component} component={SupplyType} />;
        break;
      }
      case "supply-start-date": {
        component.name = "quotePeriod";
        field = (
          <Field {...component} component={StartDate} journey={this.props.journey} />
        );
        break;
      }
      case "supply-usage": {
        component.name = "utilities";
        field = <Field {...component} component={EnergyUsage} />;
        break;
      }
      case "company-details" : {
        component.name = "companyDetails"
        field = <Field {...component} component={CompanyDetails} />
        break;
      }
      case "company-address": {
        component.name = "supplyAddress";
        component.router = this.props.history;
        field = <Field {...component} component={AddressLookup} journey={this.props.journey} />;
        break;
      }
      case "contact-details": {
        component.name = "contactDetails";
        component.router = this.props.history;
        field = <Field {...component} component={ContactDetails} />;
        break;
      }
      case "current-supplier": {
        component.name = "currentSupplier";
        component.router = this.props.history;
        field = (
          <Field
            {...component}
            component={MultipleCurrentSupplier}
            journeyUtilityType={this.props.journey.journeyUtilityType}
            suppliers={this.props.suppliers}
          />
        );
        break;
      }
    }

    return field !== null ? (
      <div key={`component-${component.type}-${idx}`} className={className}>
        <IconTick wrapperClass={"form__status"} />
        {field}
      </div>
    ) : (
      <></>
    );
  };

  private onKeyDown = (event: React.KeyboardEvent): void => {
    if ((event.charCode || event.keyCode) === 13 || event.code === "Enter") {
      event.preventDefault();
    }
  };

  private getFormClass = (): string => {
    let classes = ["form", "form--journey"];

    const isCompleted = this.props.includedSteps.every((i: AppStages) =>
      this.props.completedStages.includes(i)
    );

    if (this.props.journey.uid !== null) {
      classes.push("form--usable");
    }

    if (isCompleted) {
      classes.push("form--completed");
    }

    return classes.join(" ");
  };

  private getBackLink = (): JSX.Element => {

    return !this.props.isFirstPage ? (
      <Link to={this.props.prevPage || ""} className={"text-button"}>
        {(this.context.labels.journey_go_back_text ?? this.props.prevPageText) || ""}
      </Link>
    ) : (
      <></>
    );
  };

  public render(): JSX.Element {
    const formClass = this.getFormClass();
    const backLink = this.getBackLink();

    return (
      <>
        <Header />
        <FeatureToggle
          name={"use_large_banner"}
          fallback={false}
          render={<LargeBanner />}
          otherwiseRender={<></>}
        />
        <FeatureToggle
          name={"show_stepper_loading_bar"}
          fallback={false}
          render={<ProgressStepsBar />}
          otherwiseRender={<ProgressBar
            ref={this.progressBar}
            initialValue={
              (100 / this.context.pages.length) * this.props.pageIndex
            }
          />}
        />
        <main id={"app-body"}>
          <Formik
            enableReinitialize
            initialValues={this.props?.journey as JourneyForm}
            onSubmit={this.handleSubmit.bind(this)}
          >
              {({ isSubmitting }) => (
              <Form
                className={formClass}
                ref={this.formElement}
                onKeyDown={this.onKeyDown}
              >
                <div className={"container-full container-full--small"}>
                  {(this.state.components as Array<FormInput>).map(
                    this.renderJourneyStep
                  )}
                </div>
                <div className={"form__actions"} ref={this.formActions}>
                  <div className={"container-full container-full--small"}>
                    {backLink}
                    <button
                      id="next-step"
                      data-testid="journeyProgressToNextPage-button"
                      type="submit"
                      className={"button"}
                      data-loading={isSubmitting ? "true" : ""}
                      disabled={isSubmitting}
                    >
                      <Spinner wrapperClass={"button__spinner"} />
                      {!this.props.isLastPage
                        ? this.context.labels.continue_button
                        : this.context.labels.access_quotes_button}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </main>
      </>
    );
  }
}

export default withRouter(connector(JourneyPage));
