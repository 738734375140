// Lib
import React from "react";
import API from "../../../lib/API";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { addressFormat } from "../../../lib/addressFormat";
import { animateScroll as scroll } from 'react-scroll';
import { testValidPostcode } from "../../../lib/testValidPostcode";

// Components
import AddressPlate from ".././AddressPlate";
import LookupFormWithSelectList from "../../forms/LookupFormWithSelectList";

// TS Definitions
import { InputActionMeta, ValueType } from "react-select";
import { Address, AddressComponentState, AddressSearchType, AddressDetail } from "../../../models/address.model";
import { FormInput } from "../../../interfaces/Form";
import {
    getElectricity,
    getGas,
    Journey,
    JourneyUtilityType,
} from "../../../models/journey.model";
import { AppLayoutData } from "../../../interfaces/Journey";

// Providers
import { AppData } from "../../../providers/AppData";
import { hidePartialMpxnString } from "../../../lib/string-replacement";
import { FeatureToggle } from "../../misc/FeatureToggle";
import { FuelType } from "../../../models/utility-detail";
import styled from "styled-components";

const searchByPostcodeDebounced = AwesomeDebouncePromise(API.getAddressByPostCode, 500);
const searchByMPANDebounced = AwesomeDebouncePromise(API.getAddressByMPAN, 500);

class SingleFuelAddressLookup extends React.Component<FormInput & { journey: Journey }, AddressComponentState>{
    static contextType: React.Context<AppLayoutData> = AppData;
    context!: React.ContextType<typeof AppData>

    private label: React.RefObject<HTMLLabelElement> = React.createRef<HTMLLabelElement>();

    state: AddressComponentState = {
        inputValue: '',
        results : {},
        addressSelected: !!(this.props.field?.value !== null && Object.values(this.props.field?.value).length),
        selectedAddress: (this.props.field?.value !== null && Object.values(this.props.field?.value).length) ? this.props.field?.value : {} as Address,
        journeySaved : false,
        type: AddressSearchType.Postcode,
        defaultOptions: [],
        isSmartMeter: false,
        inputData: undefined,
        isError: undefined,
        isLoading: false,
    }

    public componentDidMount = (): void => {
        setTimeout(() => {
            this.props.updateGroupStatus(this.props.field?.value !== null && !!Object.values(this.props.field?.value).length)
        }, 0);
    }

    public componentDidUpdate = (prevProps: Readonly<FormInput>, prevState: Readonly<AddressComponentState>): void => {
        if(this.props.form.values.companyType !== prevProps.form.values.companyType){
            this.setState({
                addressSelected : false,
                selectedAddress : {} as Address,
                inputValue: ''
            }, () => {this.props.updateGroupStatus(false)});
        }

        if(this.props.field?.value !== null && Object.values(this.props.field?.value).length){
            if(prevState.selectedAddress !== this.props.field?.value){
                this.setState({
                    addressSelected : true,
                    selectedAddress: this.props.field?.value
                }, () => this.props.updateGroupStatus(true));
            }
        }
    }

    private forceScrollIntoVisibility = () => {
        if(this.label.current !== null){
            const offset = this.label.current.getBoundingClientRect(),
                  top = (offset.top + (document.scrollingElement?.scrollTop ?? 0));

            scroll.scrollTo(top);
        }
    }

    private getAddresses = async (inputValue: string): Promise<any> => {
        return await Promise.resolve(searchByPostcodeDebounced(inputValue, this.props.form.values.journeyUtilityType))
            .then((responses) => {
                if (responses.length > 0) {
                    const elecArray: Address[] = responses.filter(address => address.meterType === FuelType.Electricity);
                    const showMpan = elecArray.length > 1;
                    const ele = elecArray.map<AddressDetail>(address => ({
                        address,
                        value: addressFormat(address),
                        label: addressFormat(address),
                        showMPAN: showMpan
                    }));

                    const gasArray: Address[] = responses.filter(address => address.meterType === FuelType.Gas);
                    const showMrn = gasArray.length > 1;
                    const gas = gasArray.map<AddressDetail>(address => ({
                        address,
                        value: addressFormat(address),
                        label: addressFormat(address),
                        showMPAN: showMrn
                    }));

                    const options = [...ele, ...gas]
                    this.setState({ defaultOptions: options }, () => this.forceScrollIntoVisibility());

                    return options;
                }
            })
            .catch(() => {
                this.setState({ isLoading: false, isError: 'Please check your postcode is correct.' });
            });        
    }

    private getAddressesByMPAN = async (inputValue: string): Promise<any> => {    
        return await Promise.resolve(searchByMPANDebounced(inputValue, this.props.form.values.journeyUtilityType))
            .then((responses) => {
                if (responses.length > 0) {
                    const addresses = responses.map<AddressDetail>((address) => ({
                        address,
                        value: addressFormat(address),
                        label: addressFormat(address),
                    }));
                    
                    this.setState({ defaultOptions: addresses }, () => this.forceScrollIntoVisibility());
                    
                    return addresses;
                }
            })
            .catch(() => {
                this.setState({ isLoading: false, isError: 'Please check the value you entered is correct.' });
            });
    }

    private getOptions = async (inputValue: string): Promise<any> => {
        if(this.state.type === AddressSearchType.Postcode){
            if ((inputValue.length >= 4 && inputValue.length <= 7)){
                return await this.getAddresses(inputValue);
            }
            else {
                this.setState({isError: "Please check your postcode is correct.", isLoading: false});
            }
        }

        if (this.state.type === AddressSearchType.MPAN) {
            if(inputValue.length >= 6 && (inputValue.length <= 10 || inputValue.length > 12)){
                return await this.getAddressesByMPAN(inputValue);
            }
            else {
                this.setState({isError: "Please check the value you entered is correct.", isLoading: false});
            }
        }       
    }

    private handleChange = (selectedOption: ValueType<AddressDetail, boolean>): void => {
        const selected = selectedOption as AddressDetail;

        if (selected) {
            this.setState({
                addressSelected: true,
                selectedAddress: selected.address as Address,
            }, () => {

                const type = this.props.form.values.journeyUtilityType;

                if(type === JourneyUtilityType.Electricity){

                    const elec = getElectricity(this.props.journey.utilities);
                    if (elec) {
                      elec.metadata = {
                        mpan: selected.address.mpxn,
                        addressId: selected.address.addressId,
                        mprn: undefined,
                      };
                    }
                }

                if(type === JourneyUtilityType.Gas){

                    const gas = getGas(this.props.journey.utilities);
                    if (gas) {
                      gas.metadata = {
                        mpan: undefined,
                        addressId: selected.address.addressId,
                        mprn: selected.address.mpxn,
                      };
                    }
                }
                
                this.props.form?.setFieldValue(this.props.field?.name || 'supplyAddress', selected.address as Address);
                this.props.updateGroupStatus(true);
            });
        }
    }

    private resetAddress = (e: React.MouseEvent<HTMLButtonElement>): void => {
        this.setState({
            addressSelected : false,
            selectedAddress : {} as Address
        }, () => {
            this.props.form?.setFieldValue(this.props.field?.name || 'supplyAddress', {} as Address);
            this.props.updateGroupStatus(false);
        });
    }

    private changeType = (type: AddressSearchType): void => {
        this.setState({
            type : (type === AddressSearchType.MPAN) ? AddressSearchType.Postcode : AddressSearchType.MPAN,
            inputValue: '',
            addressSelected : false,
            defaultOptions: [],
            selectedAddress : {} as Address,
            isError: undefined,
        });
    }

    private handleInputChange = (event: any) => {
        this.setState({inputValue: event.target.value})
    }

    private handleInputSubmit = async () => {
        this.setState({isLoading: true, isError: undefined});
        
        if (this.state.inputValue.length) {
            const response = await this.getOptions(this.state.inputValue.replace(/\s/g, ""));

            if(!response){
                if (!this.state.isError) {
                    this.setState({ isError: "No results found"});
                }
            }
        }
        else {
            this.setState({isError: "Please enter a value."});
        }      
        this.setState({isLoading: false});
    }

    private getMarkup = (): JSX.Element => {
        const placeholder = (this.state.type === AddressSearchType.Postcode)
            ? this.context.labels.supply_address_placeholder_postcode
            : (this.props.form.values.journeyUtilityType === JourneyUtilityType.Gas
                ? this.context.labels.supply_address_placeholder_mprn ?? "Enter MPRN"
                : this.context.labels.supply_address_placeholder_mpan ?? "Select your address");
        
        const selectPlaceholder = (this.state.type === AddressSearchType.Postcode)
            ? "Select your address"
            : (this.props.form.values.journeyUtilityType === JourneyUtilityType.Gas
                ? "Select your MPRN"
                : "Select your MPAN");

        if(!this.state.addressSelected){
            return (
                <>
                    <LookupFormWithSelectList 
                        value={this.state.inputValue} 
                        onChange={this.handleInputChange} 
                        placeholder={placeholder} 
                        onClick={this.handleInputSubmit}
                        isLoading={this.state.isLoading}
                        isError={this.state.isError}
                        defaultOptions={this.state.defaultOptions}
                        selectListOnChange={this.handleChange}
                        selectPlaceholder={selectPlaceholder}
                    />
                </>
            );
        } else {
            return (<AddressPlate {...this.state.selectedAddress}
                          onChangeAddress={ this.resetAddress }/>);
        }
    }

    public render = (): JSX.Element => {
        let markup: JSX.Element = this.getMarkup();
        return (
            <>
                <label htmlFor="address-lookup" className={"form__label"} ref={this.label}>
                    {this.context.labels.supply_address_title}
                    &ensp;
                    {
                        !this.state.addressSelected ? (
                            <button onClick={() => this.changeType(this.state.type)} type={'button'} className={"text-button"}>
                                {this.state.type === AddressSearchType.Postcode
                                    ? (this.props.form.values.journeyUtilityType === JourneyUtilityType.Gas
                                        ? this.context.labels.supply_address_change_to_mprn ?? "Change to MPRN" // Waiting on ensek to supply_address_change_mprn to prismic
                                        : this.context.labels.supply_address_change_to_mpan ?? "Change to MPAN")
                                    : this.context.labels.supply_address_change_to_postcode ?? "Change to Postcode"
                                }
                            </button>
                        ) : null
                    }
                </label>
                <FeatureToggle
                    name={"show_smart_meter_text"}
                    fallback={false}
                    render={<>
                        {this.state.isSmartMeter && 
                            <div className={"smart-energy-meter-text"}>{
                                this.context.labels.smart_energy_meter_text
                            }</div>}
                    </>}
                    otherwiseRender={<></>}
                 />
                { markup }
            </>
        )
    }
}

export default SingleFuelAddressLookup;