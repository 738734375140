import React, { useContext } from "react";
import { Toggles } from "../../interfaces/Journey";
import { AppData } from "../../providers/AppData";

type FeatureToggleProps = {
  name: keyof Toggles;
  fallback: boolean;
  render: JSX.Element;
  otherwiseRender: JSX.Element;
};

export const FeatureToggle: React.FunctionComponent<FeatureToggleProps> = ({
  name,
  fallback,
  render,
  otherwiseRender,
}) => {
  const context = useContext(AppData);

  const shouldRender = context?.toggles?.[name] ?? fallback;
  return shouldRender ? render : otherwiseRender;
};
