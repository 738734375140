import React from "react";

import IconProps from "../../interfaces/Icon";

const IconEdit = (props: IconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" className={ props.wrapperClass || '' }>
            <g>
                <polyline points="18 12.66 18 19.9 2 19.9 2 3.9 8.89 3.9" fill="none" stroke="#008ffd" strokeMiterlimit="10" strokeWidth="2"/>
                <path d="M7.93,11.18l9.43-9.44a2.86,2.86,0,0,1,2.09.71,3,3,0,0,1,.74,2.12L10.76,14c-.63.59-2,1-4.18,1.36C6.87,13.24,7.32,11.84,7.93,11.18Z" fill="none" stroke="#008ffd" strokeMiterlimit="10" strokeWidth="2"/>
            </g>
        </svg>
    );
};

export default IconEdit;