// Lib
import React from "react";
import {Modal} from "react-bootstrap";

// TS Definitions
import {CompanyErrorState, CompanyPlateProps} from "../../interfaces/CompanyName";

// Images
import IconEdit from "../icons/IconEdit";
import IconClose from "../icons/IconClose";

class CompanyError extends React.Component<CompanyPlateProps, CompanyErrorState>{
    state: CompanyErrorState = {
        showModal: true
    }

    public clearCompany = (): void => {
        if (this.props.onChangeCompany) {

            this.props.onChangeCompany();
        }
    }

    private closeModal = () => {
        this.setState({showModal: false}, this.clearCompany);
    }

    public render = (): JSX.Element => {
        return (
            <>
                <div className={"company-plate company-plate--error"}>
                    <h3 className={"company-plate__name"}>{this.props.name}</h3>
                    <h5 className={"company-plate__number"}>
                        {this.props.error?.title}
                    </h5>
                    <button className={"icon-button company-plate__edit"} type={"button"} onClick={ this.clearCompany.bind(this) }>
                        <span>Edit</span>
                        <IconEdit />
                    </button>
                </div>
                <Modal show={this.state.showModal}
                       onHide={this.closeModal}
                       dialogClassName="feature-modal"
                       centered>
                    <Modal.Body>
                        <button type={"button"} className={"icon-button"} onClick={this.closeModal}>
                            <IconClose />
                            <span>Close Modal</span>
                        </button>
                        <div>
                            <h2>{this.props.error?.title}</h2>
                            <p>{this.props.error?.message}</p>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

export default CompanyError;