import { FunctionComponent } from "react";
import { RadioToggle, RadioToggleInput, RadioToggleLabel } from "./style";
import { ConsumptionPeriods } from "../../../../models/consumption-periods";
import { JourneyUtilityType } from "../../../../models/journey.model";

interface Props {
    selectedPeriod: ConsumptionPeriods;
    handler: (value: ConsumptionPeriods) => void;
    type: JourneyUtilityType;
}

const PeriodToggles: FunctionComponent<Props> = ({
    selectedPeriod,
    handler,
    type
}: Props) => {
    return (
        <RadioToggle>
            <RadioToggleInput id={'monthly' + type.toString()} type="radio" name={'quoteType' + type.toString()} value={ConsumptionPeriods.Monthly} checked={(selectedPeriod === ConsumptionPeriods.Monthly)} readOnly />
            <RadioToggleLabel onClick={() => handler(ConsumptionPeriods.Monthly)} htmlFor={'monthly' + type.toString()}>
                Monthly
            </RadioToggleLabel>
            <RadioToggleInput id={'quarterly' + type.toString()} type="radio" name={'quoteType' + type.toString()} value={ConsumptionPeriods.Quarterly} checked={(selectedPeriod === ConsumptionPeriods.Quarterly)} readOnly />
            <RadioToggleLabel onClick={() => handler(ConsumptionPeriods.Quarterly)} htmlFor={'quarterly' + type.toString()}>
                Quarterly
            </RadioToggleLabel>
            <RadioToggleInput id={'biannually' + type.toString()} type="radio" name={'quoteType' + type.toString()} value={ConsumptionPeriods.BiAnnual} checked={(selectedPeriod === ConsumptionPeriods.BiAnnual)} readOnly />
            <RadioToggleLabel onClick={() => handler(ConsumptionPeriods.BiAnnual)} htmlFor={'biannually' + type.toString()}>
                Bi-Annually
            </RadioToggleLabel>
            <RadioToggleInput id={'annually' + type.toString()} type="radio" name={'quoteType' + type.toString()} value={ConsumptionPeriods.Yearly} checked={(selectedPeriod === ConsumptionPeriods.Yearly)} readOnly />
            <RadioToggleLabel onClick={() => handler(ConsumptionPeriods.Yearly)} htmlFor={'annually' + type.toString()}>
                Annually
            </RadioToggleLabel>
        </RadioToggle>
    )
}

export default PeriodToggles;