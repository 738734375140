import { AppStages } from "../models/journey.model";
import { getFormInputTypeByAppStage } from "./getAppStageByComponentType";

export function tryGetLastCompletedStageName(props: {
  completedStages: AppStages[];
}): [boolean, string] {
  try {
    const [last]: AppStages[] = props.completedStages.reverse();
    return [true, getFormInputTypeByAppStage(last)];
  } catch (e) {
    console.error(`Could not find the correct component`, e);
  }
  return [false, ""];
}
