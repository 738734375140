import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

// Stores
import store from "./stores/store";

// Components
import App from "./App";
import AppError from "./AppError";

// Providers
import { AppData } from "./providers/AppData";

// TS Declarations
import { AppLayoutData } from "./interfaces/Journey";

// Helpers
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import { getAppLayout } from "./lib/getAppLayout";
import { CookieConsent } from "./components/misc/CookieConsent/CookieConsent";
import { environment } from "./lib/environment";
import { containsOnlyValidCharacters } from "./lib/containsOnlyValidCharacters";

const render = (appData: AppLayoutData) => {
  const pageTitle = appData.branding.page_title;
  const title =
    typeof pageTitle === "string" && pageTitle.length > 0
      ? pageTitle
      : process.env.REACT_APP_NAME ?? "";
  document.title = title;

  const favicon = document.querySelector("#favicon");
  if (favicon !== null) {
    favicon.setAttribute("href", appData.faviconURL ?? "");
  }

  ReactDOM.hydrate(
    <HelmetProvider>
      <AppData.Provider value={appData}>
        <Provider store={store}>
          <BrowserRouter basename={environment.REACT_APP_BASE_NAME}>
            {typeof appData !== "undefined" ? <App /> : <AppError />}
          </BrowserRouter>
          <CookieConsent />
        </Provider>
      </AppData.Provider>
    </HelmetProvider>,
    document.getElementById("root")
  );
};

let appLayout: AppLayoutData = (window as any).__INITIAL_DATA__ as AppLayoutData;

if (typeof appLayout === "undefined" && process.env.REACT_APP_PRISMIC_DOCUMENT_ID) {
  getAppLayout(process.env.REACT_APP_PRISMIC_DOCUMENT_ID)
    .then(async (appData: AppLayoutData | undefined) => {
      if(appData === undefined){
        console.log("Prismic data not found")
        return
      }
      Object.entries(appData.branding).forEach(([key, value]) => {
        if (key === "font-family") {
          const val = containsOnlyValidCharacters([key, value])
            ? value
            : `"dmsans", Arial, sans-serif`;
          return document.documentElement.style.setProperty(`--${key}`, val);
        } else if (containsOnlyValidCharacters([key, value])) {
          return document.documentElement.style.setProperty(`--${key}`, value);
        } else if (containsOnlyValidCharacters([key, value?.url])) {
          return document.documentElement.style.setProperty(`--${key}`, value.url);
        }
      });

      const fontName = appData.branding["font-name"];
      if (appData.branding && fontName && containsOnlyValidCharacters([fontName])) {
        const lightFont = appData.branding["font-light"]?.url;
        if (lightFont && containsOnlyValidCharacters([lightFont])) {
          loadFonts(fontName, lightFont, "300");
        }

        const regularFont = appData.branding["font-regular"]?.url;
        if (regularFont && containsOnlyValidCharacters([regularFont])) {
          loadFonts(fontName, regularFont, "normal");
        }

        const boldFont = appData.branding["font-bold"]?.url;
        if (boldFont && containsOnlyValidCharacters([boldFont])) {
          loadFonts(fontName, boldFont, "bold");
        }
      }
      return appData;
    })
    .then(render);
} else {
  render(appLayout);
}

// document.body.addEventListener('keyup', ev => {
//     if(ev.code === "Backquote"){
//         debugger;
//     }
// });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorker.unregister();

async function loadFonts(fontName: string, url: string, fontWeight: string) {
  if (
    typeof document === "undefined" ||
    typeof (document as any).fonts === "undefined" ||
    typeof window === "undefined" ||
    typeof (window as any).FontFace === "undefined"
  ) {
    return;
  }

  const win = window as any;
  const doc = document as any;

  const font = new win.FontFace(fontName, `url(${url})`, {
    weight: fontWeight,
  });
  await font.load();
  doc.fonts.add(font);
}
