// Lib
import React, { useContext } from "react";
import { connect, ConnectedProps } from "react-redux";
import { StoreHelpers } from 'react-joyride';
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { animateScroll as scroll } from 'react-scroll';

// Components
import LoadingQuotes from "../components/quotes/LoadingQuotes";
import Header from "../components/header/Header";
import QuoteList from "../components/quotes/QuoteList";
import QuoteOverview from "../components/quotes/QuoteOverview";
import NoResults from "../components/quotes/NoResults";
import Filters from "../components/quotes/Filters";


// Store Actions
import { clearQuotes } from "../stores/actions/clearQuotes";
import { toggleViewedTour } from "../stores/actions/toggleViewedTour";
import { updateJourney } from "../stores/actions/updateJourney";
import { updateStage } from "../stores/actions/updateStage";

// Providers
import { AppData } from "../providers/AppData";

//Interfaces
import { Quote } from "../models/quote.model";
import {
    AppStages,
    getElectricity,
    getGas,
    JourneyForm,
    JourneyUtilityType,
    QuoteDetails
} from "../models/journey.model";
import { FuelType } from "../models/utility-detail";
import { SupplierConfig } from "../models/supplier-config.model";
import { AppLayoutData } from "../interfaces/Journey";
import { FiltersState, QuoteListRefs, QuotesPageProps, QuotesPageState } from "../interfaces/Quotes";

import { TOUR_STYLE, FLOATER_PROPS } from "../lib/tourStyling";
import { FeatureToggle } from "../components/misc/FeatureToggle";
import { SaveQuoteModal } from "../components/SaveQuoteModal";
import { IfCustomerPortal } from "../components/misc/EnvironmentToggle";
import { RichText } from "prismic-reactjs";
import { ConsumptionTypes } from "../models/consumption-types";
import API from "../lib/API";
import { analyticsPageView, analyticsEvent } from "../stores/analytics/actions";
import { Countdown } from "../components/Countdown";
import { ContactSalesModal } from "../components/forms/ContactSalesModal";
import { AppState } from "../stores/store";
import { ShouldShowModal } from "../lib/ShouldShowModal";
import QuotesPageSubHeadings from "../components/quotes/QuotesPageSubHeadings";
import ContactAdvisorModal from "../components/ContactAdvisorModal";
import styled from "styled-components";

const mapStateToProps = (state: AppState) => ({
    electricityQuotes: state.quotes.electricity.quotes,
    gasQuotes: state.quotes.gas.quotes,
    errors: Object.entries(state.quotes).map(([,value]) => value?.error).filter(error => !!error) as Array<string>, 
    journey: state.journey,
    viewedTour: state.viewedTour,
    suppliers: state.suppliers,
    changedFromDualFuel: state.changedFromDualFuel
});
const dispatchProps = { clearQuotes, updateJourney, toggleViewedTour, updateStage, analyticsPageView, analyticsEvent };
const connector = connect(mapStateToProps, dispatchProps);

type ComponentProps = RouteComponentProps & ConnectedProps<typeof connector> & QuotesPageProps;

class QuotesPage extends React.Component<ComponentProps, QuotesPageState> {
    static contextType: React.Context<AppLayoutData> = AppData;
    // context!: React.ContextType<typeof AppData>

    private helpers?: StoreHelpers;

    private overview: React.RefObject<HTMLElement> = React.createRef<HTMLElement>();

    private quoteListRefs: QuoteListRefs = {
        electricity : React.createRef<QuoteList>(),
        gas : React.createRef<QuoteList>()
    };

    state: QuotesPageState = {
        showEditLink: true,
        journey : this.props.journey,
        quotesLoaded: false,
        isFastSwitching: false,
        isGreen: false,
        termLength: null,
        docusignRef: null,
        isTourOpen: false,
        isSaveQuotesModalOpen: false,
        isSaveQuotesModalEmail: false,
        steps: [
            {
                content: (
                    <p>{this.context.labels.quotes_tour_save_quotes_tooltip}</p>
                ),
                floaterProps: FLOATER_PROPS,
                disableBeacon: true,
                spotlightPadding: 10,
                placement: "left",
                target: '#save-quotes'
            },
        ],
        foreceReloadQuotes: 0, // Used as a key on the LoadingQuotes component
        showContactSalesModal: {
            show: false,
            type: undefined
          },
          modalCustomerData: undefined,
        showContactAdvisorModal: false
    };

    public componentDidUpdate = (prevProps: any) => {

        // this is to be fixed, probably move the logic to contactsalesmodal
        if(prevProps.electricityQuotes.length !==
            this.props.electricityQuotes.length){
                this.setModal();
                return;
            }
        
        if(prevProps.gasQuotes.length !== this.props.gasQuotes.length){
            this.setModal();
            return;
        }

        if(prevProps.errors.length !== this.props.errors.length){
            this.setModal();
            return;
        }
    };
    
    public setModal = () => {
        const showModal = ShouldShowModal(
          this.props.journey,
          this.context,
          this.props.errors
        );
    
        this.setState({
          modalCustomerData: this.props.journey,
          showContactSalesModal: showModal,
        });
    };
    

    public componentWillUnmount(): void {
        sessionStorage.removeItem("savedJourney");
    }

    public componentDidMount = () => {
        this.props.analyticsPageView(window.location.pathname);

        if(document.scrollingElement !== null){
            document.scrollingElement.scrollTop = 0;
        }

        if (
            (this.props.electricityQuotes.length ||
                this.props.gasQuotes.length) &&
            !this.props.viewedTour
          ) {
            this.setState({ isTourOpen: true, quotesLoaded: true });
          }
    }

    private handleQuotes = () => {
        this.setState({ isTourOpen: true, quotesLoaded : true });
    }

    private updateFilters = (state : FiltersState) => {
        this.setState({...state});
    }

    private setSelectedDocusignSupplier = (): void => {
        const selectedQuotes : Array<QuoteDetails> = this.state.journey.utilities.quoteDetails.filter((quoteDetails: QuoteDetails) => {
            return (Object.keys(quoteDetails.quote)).length;
        });

        let selectedSuppliers : Array<SupplierConfig> = selectedQuotes.map((quoteDetails: QuoteDetails) => {
            return (Object.values(this.props.suppliers) as Array<SupplierConfig>).find((supplier: SupplierConfig) => supplier.reference === quoteDetails.quote.supplier) as SupplierConfig;
        });

        selectedSuppliers = selectedSuppliers.filter((supplier: SupplierConfig) => supplier.captureDocumentSignature);

        this.setState({
            docusignRef: (selectedSuppliers.length) ? selectedSuppliers[0].reference : null
        });
    }

    private scrollToOverview = () => {
        if(this.overview.current !== null){
            const offset = this.overview.current.getBoundingClientRect(),
                  top = (offset.top + (document.scrollingElement?.scrollTop ?? 0));

            scroll.scrollTo(top);
        }
    }

    private handleQuoteSelected = (utilityType: FuelType, quote : Quote = {} as Quote) => {
        const updatedUtils : Array<QuoteDetails> = this.state.journey.utilities.quoteDetails.map((quoteDetails: QuoteDetails) => {
            if(quoteDetails.quoteType === utilityType){
                quoteDetails.quote = quote;
            }

            return quoteDetails;
        });

        this.setState({
            journey: {
                ...this.state.journey,
                utilities: {
                    quoteDetails: updatedUtils
                }
            }
        }, () => {
            this.setSelectedDocusignSupplier();

            const journeyUtilityType = this.props.journey.journeyUtilityType;
            let selectedQuotes = this.props.journey.utilities.quoteDetails.map((quoteDetails: QuoteDetails) => {
                return quoteDetails.quote;
            });
            selectedQuotes = selectedQuotes.filter((quote: Quote) => Object.values(quote).length);

            if(journeyUtilityType === JourneyUtilityType.DualFuel){
                if(selectedQuotes.length === 2){
                    this.scrollToOverview();
                }

                if(utilityType === FuelType.Electricity && selectedQuotes.length === 1){
                    if(this.quoteListRefs.gas.current){
                        this.quoteListRefs.gas.current.scrollTo()
                    }
                }

                if(utilityType === FuelType.Gas && selectedQuotes.length === 1){
                    if(this.quoteListRefs.electricity.current){
                        this.quoteListRefs.electricity.current.scrollTo()
                    }
                }
            } else {
                if(selectedQuotes.length === 1){
                    this.scrollToOverview()
                }
            }
        })
    }

    private showSubmitButton = (): boolean => {
        if(typeof this.state.journey.utilities.quoteDetails === "undefined")
            return false;

        let hasQuotes : boolean[] = this.state.journey.utilities.quoteDetails.map((quoteDetails : QuoteDetails) => {
            return !!Object.keys(quoteDetails.quote).length;
        });

        hasQuotes = hasQuotes.filter(hasQuote => hasQuote);

        return (
            (this.state.journey.journeyUtilityType === JourneyUtilityType.DualFuel && hasQuotes.length === 2) ||
            (this.state.journey.journeyUtilityType === JourneyUtilityType.Electricity && hasQuotes.length === 1) ||
            (this.state.journey.journeyUtilityType === JourneyUtilityType.Gas && hasQuotes.length === 1)
        );
    }

    private submitJourney = async (): Promise<boolean> => {
        return await this.props.updateJourney({
            utilities: this.state.journey.utilities
        } as JourneyForm, false).then(() => true);
    }

    private completed = (): void => {
        this.props.analyticsEvent('quote-select');
        this.props.updateStage(AppStages.DetailsPage, true).then(() => {
            this.props.history.push("/switch");
        });
    }

    private renderQuoteList = (): Array<JSX.Element> => {
        const showModal = ShouldShowModal(
            this.props.journey,
            this.context,
            this.props.errors
          );

        if(showModal.show){
            return this.state.quotesLoaded ? [<NoResults />] : [<></>];
        }
        let ref: React.RefObject<QuoteList> | undefined;

        let jsx: Array<JSX.Element> = [];
        if(this.props.electricityQuotes.length){
            let quoteDetails: QuoteDetails | undefined
            quoteDetails = getElectricity(this.props.journey.utilities)
            jsx.push(
                <QuoteList key={`quote-list-electricity`}
                           quotes={this.props.electricityQuotes}
                           quoteDetails={quoteDetails as QuoteDetails}
                           changedFromDualFuel={this.props.changedFromDualFuel}
                           isGreen={this.state.isGreen}
                           isFastSwitching={this.state.isFastSwitching}
                           docusignRef={this.state.docusignRef}
                           suppliers={this.props.suppliers}
                           termLength={this.state.termLength}
                           onQuoteSelected={this.handleQuoteSelected}
                           onQuoteCleared={this.handleQuoteSelected}
                           ref={ref} />
            )
        }
        if(this.props.gasQuotes.length){
            let quoteDetails: QuoteDetails | undefined
            quoteDetails = getGas(this.props.journey.utilities)
            jsx.push(
                <QuoteList key={`quote-list-gas`}
                           quotes={this.props.gasQuotes}
                           quoteDetails={quoteDetails as QuoteDetails}
                           changedFromDualFuel={this.props.changedFromDualFuel}
                           isGreen={this.state.isGreen}
                           isFastSwitching={this.state.isFastSwitching}
                           docusignRef={this.state.docusignRef}
                           suppliers={this.props.suppliers}
                           termLength={this.state.termLength}
                           onQuoteSelected={this.handleQuoteSelected}
                           onQuoteCleared={this.handleQuoteSelected}
                           ref={ref} />
            )
        }
        if(jsx.length) {
            return jsx;
          }else{
            return this.state.quotesLoaded ? [<NoResults />] : [<></>];
          }
          
    }

    private handleModalClick = () => {
        let emailRegex = /\S+@\S+\.\S+/;
        let isEmailValid = emailRegex.test(this.state.journey.contactInformation.email)

        if(isEmailValid) {
            this.setState({isSaveQuotesModalOpen: true, isSaveQuotesModalEmail: true})
        } else {
            this.setState({isSaveQuotesModalOpen: true, isSaveQuotesModalEmail: false})
        }
    }

    public render = (): JSX.Element => {
        return (
            <>
                <Header />
                <main id={"app-body"} className={"quote-page"}>
                    <section id={"filters"}>
                        <div className={"container-full"}>
                            <FeatureToggle
                                name={"show_quote_filters"}
                                fallback={true}
                                render={<Filters filtersUpdated={this.updateFilters} 
                                electricityQuotes={this.props.electricityQuotes} 
                                gasQuotes={this.props.gasQuotes}/>}
                                otherwiseRender={<></>}
                            />
                            <FeatureToggle
                                name={"show_save_quotes_btn_quotes_page"}
                                fallback={false}
                                render={
                                    <div className={"save-quotes"}>
                                        <div className={"save-quotes__text"}>
                                            <span>
                                                {this.context.labels.quotes_contact_us_title}
                                            </span>
                                            <span className={"heading-4"}>
                                                {this.context.labels.quotes_contact_us_number}
                                            </span>
                                        </div>
                                        <div className={"save-quotes__actions"}>
                                            <button
                                                id={"save-quotes"}
                                                type={"button"}
                                                className={"button button--save-quotes"}
                                                onClick={() => this.handleModalClick()}
                                                disabled={this.state.isSaveQuotesModalEmail}
                                            >
                                                {!this.state.isSaveQuotesModalEmail
                                                    ? this.context.labels.quotes_save_quotes_button
                                                    : this.context.labels.quotes_save_quotes_button_sent}
                                            </button>
                                        </div>
                                    </div>
                                }
                                otherwiseRender={<></>}
                            />
                            <FeatureToggle
                                name={"show_countdown"}
                                fallback={false}
                                render={<Countdown 
                                    key={this.state.foreceReloadQuotes} 
                                    onReset={() => {
                                        this.props.clearQuotes();
                                        this.setState((prev) => ({foreceReloadQuotes: prev.foreceReloadQuotes + 1}));
                                    }}/>
                                }
                                otherwiseRender={<></>}
                            />
                        </div>
                    </section>
                    <div className={"container-full"}>
                        <QuotesPageSubHeadings/>
                        <div className={"grid"}>
                            <section className={"col-md-8"}>
                                {this.renderQuoteList()}
                            </section>
                            <aside className={"col-md-4"} ref={this.overview}>
                                <QuoteOverview forceDetailsOpen={false}
                                               showButton={this.showSubmitButton()}
                                               suppliers={this.props.suppliers}
                                               onSubmit={this.submitJourney}
                                               onCompleted={this.completed} />
                            </aside>
                        </div>
                    </div>
                    <div className={"form__actions"}>
                        <div className={"container-full"}>
                            <IfCustomerPortal 
                                render={<></>} 
                                otherwiseRender={<Link to={this.props.editLink} className={"text-button"}>{
                                    this.context.labels.journey_go_back_text ?? this.props.editText
                                }</Link>}
                            />
                        </div>
                    </div>
                </main>
                <LoadingQuotes key={this.state.foreceReloadQuotes} {...{
                    onQuotesLoaded: () => this.handleQuotes()
                }} />
                {this.state.isSaveQuotesModalOpen && <SaveQuoteModal 
                    journey={this.state.journey}
                    typeOfSave={"save-quotes"}
                    isSaveQuotesModalOpen={this.state.isSaveQuotesModalOpen}
                    isSaveQuotesModalEmail={this.state.isSaveQuotesModalEmail}
                    onClose={() => this.setState({isSaveQuotesModalOpen: false})}
                />}
                {this.state.showContactSalesModal.show &&
                    this.state.modalCustomerData &&
                    this.state.showContactSalesModal.type && (
                        <ContactSalesModal
                            type={this.state.showContactSalesModal.type}
                            journey={this.state.modalCustomerData}
                            show={this.state.showContactSalesModal.show}
                            onClose={() => this.setState(state => (state.showContactSalesModal.show = false, state))}
                        />
                )}
            </>
        )
    }
}
export default withRouter(connector(QuotesPage));
