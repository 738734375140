import { ThunkDispatch } from "redux-thunk";
import { Action } from 'redux';
import API from "../../lib/API";

import { saveJourneyToState } from "./saveJourneyToState";
import { clearQuotes } from "./clearQuotes";
import { getElectricity, getGas, Journey, JourneyForm, JourneyUtilityType, QuoteDetails } from "../../models/journey.model";
import { Quote } from "../../models/quote.model";
import { AppState } from "../store";
import { FuelType } from "../../models/utility-detail";
import { addDays, max, addBusinessDays } from "date-fns";

type MyRootState = {};
type MyExtraArg = undefined;
type MyThunkDispatch = ThunkDispatch<MyRootState, MyExtraArg, Action>;

export const updateJourney = (updatedJourney: JourneyForm, resetQuotes: boolean = false) => {
    return (dispatch: MyThunkDispatch, getState: () => AppState) => {
        const { journey: currentJourney } = getState();
        const journey = {
            ...currentJourney,
            ...updatedJourney
        };
        if (resetQuotes && typeof journey.utilities.quoteDetails !== 'undefined') {
            journey.utilities.quoteDetails = journey.utilities.quoteDetails.map((quoteDetail: QuoteDetails) => {
                quoteDetail.quote = {} as Quote;

                return quoteDetail;
            });
        }

        const correctJourneyFuncs = [ensureOnlyCorrectUtilitiesExist, ensureStartDateIsCorrect]
        const journeyToSave = correctJourneyFuncs.reduce((newJourney, func) => func(newJourney), journey);
        return API.updateJourney(journeyToSave as JourneyForm)
            .then(resp => {
                if (resetQuotes) {
                    dispatch(clearQuotes());
                }

                return resp;
            })
            .then(resp => {
                if (resp) {
                    return Promise.all([
                        dispatch(saveJourneyToState(journeyToSave)),
                    ]);
                } else {
                    console.warn('Failed to update: resp false');
                    return Promise.reject('Failed to update')
                }
            })
            .catch(err => {
                console.warn(err);
                return Promise.reject(err)
            })
    }
}

const ensureStartDateIsCorrect = (journey: Journey): Journey => {
    const amendedQuoteDetails: QuoteDetails[] = journey?.utilities?.quoteDetails?.map(qd => ({
        ...qd,
        quotePeriod: {
            start: max([
                new Date(journey?.quotePeriod ?? new Date()),
                addBusinessDays(new Date(), 5)
            ])
        }
    })) ?? []

    return {
        ...journey,
        utilities: {
            ...journey.utilities,
            quoteDetails: amendedQuoteDetails
        }
    };
}

const ensureOnlyCorrectUtilitiesExist = (journey: Journey): Journey => {
    const quoteDetails: Array<QuoteDetails> = [];
    if (journey.journeyUtilityType === JourneyUtilityType.Electricity
        || journey.journeyUtilityType === JourneyUtilityType.DualFuel) {
        const quoteDetail = getElectricity(journey.utilities);
        if (quoteDetail) {
            quoteDetails.push(quoteDetail)
        }
    }
    if (journey.journeyUtilityType === JourneyUtilityType.Gas
        || journey.journeyUtilityType === JourneyUtilityType.DualFuel) {
        const quoteDetail = getGas(journey.utilities);
        if (quoteDetail) {
            quoteDetails.push(quoteDetail)
        }
    }
    return {
        ...journey,
        utilities: {
            ...journey.utilities,
            quoteDetails
        }
    }
}
