import React, { useContext } from "react";

import IconProps from "../../interfaces/Icon";
import { AppData } from "../../providers/AppData";

const IconBoth = (props: IconProps) => {
    const context = useContext(AppData);
    
    const icon = context.branding.electricy_gas_icon;
    if (icon && icon.url) {
      return (
        <img
          width="80"
          height="80"
          className={props.wrapperClass || ""}
          src={icon.url}
          alt={icon.alt ?? ""}
        />
      );
    }
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" className={ props.wrapperClass || '' }>
            <g>
                <path d="M39.86,63.09c-2.32-4.58-1.07-10.38.77-12.22,1.52,8.4,6.11,6.88,6.11,6.88C43.68,52.4,44.45,46.29,50.55,44c-2.29,7.64,3.82,8.4,3.82,13a11.36,11.36,0,0,1-2.29,6.11" fill="none" strokeLinejoin="round" strokeWidth="2"/>
                <path d="M34.52,63.09A13.27,13.27,0,0,1,29.17,52.4c0-8.45,5.35-9.93,5.35-22.15,2.29,4.59,6.11,6.11,6.87,13.75,0-7.64,5.35-7.64,5.35-19.09,0,0,15.27,6.87,7.63,22.91a11,11,0,0,0,6.88-10.69c3.26,5.09,4.58,9.16,4.58,13,0,6.22-3.82,10.69-6.11,13" fill="none" strokeLinejoin="round" strokeWidth="2"/>
                <polygon points="14.17 54.91 19.86 35.3 14.17 35.3 19.42 16.91 25.55 16.91 22.05 29.17 28.17 29.17 15.05 54.91 14.17 54.91" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
            </g>
        </svg>
    );
};

export default IconBoth;