import React from "react";

import IconProps from "../../interfaces/Icon";

const IconRefresh = (props: IconProps) => {
    return (
        <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" className={ props.wrapperClass || '' }>
            <g>
                <path d="M12.29,3.46a7.83,7.83,0,0,1,1.1,15.13" fill="none" stroke="#008ffd" strokeMiterlimit="10" strokeWidth="2"/>
                <polyline points="13.74 7.25 11.86 3.23 15.87 1.35" fill="none" stroke="#008ffd" strokeMiterlimit="10" strokeWidth="2"/>
                <path d="M9.45,19A7.83,7.83,0,0,1,8.35,3.85" fill="none" stroke="#008ffd" strokeMiterlimit="10" strokeWidth="2"/>
                <polyline points="8 15.2 9.88 19.21 5.87 21.09" fill="none" stroke="#008ffd" strokeMiterlimit="10" strokeWidth="2"/>
            </g>
        </svg>
    );
};

export default IconRefresh;