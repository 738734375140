import { useContext, useState } from 'react';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { BrandingImageData } from '../../interfaces/Journey';
import { AppData } from '../../providers/AppData';
import IconClose from '../icons/IconClose';

const Span = styled.span`
  background: var(--primary);
  padding: 8px;
  border-radius: 0 0 0 0.4rem;
  gap: 1rem;
  display: flex;
  font-weight: bold;
`;

const Img = styled.img`
  height: 25px;
  z-index: 99;
  cursor: pointer;
`;

const LogoImg = styled.img`
  height: 6rem;
`;

const RecommendedBadge = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const { branding, labels } = useContext(AppData);

  const infoIcon: BrandingImageData = branding[
    'info_icon'
  ] as BrandingImageData;

  const Logo: BrandingImageData =
    JSON.stringify(branding['loading_quotes'] as BrandingImageData) === '{}'
      ? (branding['logo'] as BrandingImageData)
      : (branding['loading_quotes'] as BrandingImageData)

  return (
    <div>
      <Span>
        {labels.recommended_flag_text}
        {infoIcon.url && (
          <Img
            src={infoIcon.url}
            alt={infoIcon.alt ?? ''}
            onClick={() => setShowModal(true)}
          />
        )}
      </Span>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName="feature-modal"
        centered
      >
        <Modal.Body>
          <button
            type={'button'}
            className={'icon-button'}
            onClick={() => setShowModal(false)}
          >
            <IconClose />
            <span>Close Modal</span>
          </button>
          <div>
            {Logo.url && <LogoImg src={Logo.url} alt={Logo.alt ?? ''} />}
            <h2>{labels.recommended_flag_modal_text_1}</h2>
            <p>{labels.recommended_flag_modal_text_2}</p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RecommendedBadge;
