// Lib
import React from "react";
import IconTick from "../icons/IconTick";
import { Field, FormikErrors, FormikTouched } from "formik";

// Provider
import { AppData } from "../../providers/AppData";

// TS Definitions
import { Address } from "../../models/address.model";
import { AppLayoutData } from "../../interfaces/Journey";
import { SwitchFormComponentProps } from "../../interfaces/Form";
import { Loader } from "./Loader";

class PaymentDetails extends React.Component<SwitchFormComponentProps>{
    static contextType: React.Context<AppLayoutData> = AppData;

    public checkKeyInput = (event: React.KeyboardEvent<HTMLInputElement>): void|boolean => {

        const utilKeys = [
          'Backspace',
          'Delete',
          'Tab',
          'CapsLock',
          'ArrowRight',
          'ArrowLeft',
          'ArrowDown',
          'ArrowUp',
          'Control',
          'v',
          'c',
          'a',
        ];

        if(!/^\d+$/.test(event.key) && utilKeys.indexOf(event.key) === -1){
            event.preventDefault()
        }

        return true;
    }

    public callFieldChangeFromInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const value = (event.target.type === "checkbox") ? event.target.checked : event.target.value;

        this.props.onFieldChange(event.target.getAttribute('name') ?? '', value);
    }

    public render = () => {
        // Weird fix for error reporting on billing address, for some reason typescript thinks directDebit is a string
        const billingErrors = this.props.errors.directDebit?.billingAddress as FormikErrors<Address>;
        const billingTouched = this.props.touched.directDebit?.billingAddress as FormikTouched<Address>;

        return (
            <>
                <div className={"form__intro"}>
                    <h2>
                        <IconTick wrapperClass={"icon"} />
                        {this.context.labels.payment_title}
                    </h2>
                    <p>{this.context.labels.payment_sub_title}</p>
                    {this.props.showNoPaymentText && (
                        <p>{this.context.labels.no_payment_sub_title}</p>
                    )}
                </div>
                {this.props.touched.directDebit?.accountNumber}
                {this.props.touched.directDebit?.sortCode}
                <div className={"grid"}>
                    <div className={"col-sm-4"}>
                        <div className={
                            this.props.errors.directDebit?.accountHolderName && this.props.touched.directDebit?.accountHolderName
                                ? 'form__group invalid'
                                : "form__group"
                        }>
                            <label className={"form__label"}>{this.context.labels.payment_account_holder_name_placeholder}</label>
                            <Field type="text"
                                   name="directDebit.accountHolderName"
                                   className={"form__control"}
                                   value={this.props.values?.directDebit.accountHolderName}
                                   placeholder={this.context.labels.payment_account_holder_name_placeholder}
                                   onChange={this.callFieldChangeFromInput}
                                   data-testid={"journeyPaymentInformation-account_holder_name"} />
                            {this.props.errors.directDebit?.accountHolderName && this.props.touched.directDebit?.accountHolderName ? (
                                <span className={"form__error"}>{this.props.errors.directDebit?.accountHolderName}</span>
                            ): null}
                        </div>
                    </div>
                    <div className={"col-sm-4"}>
                        <div className={
                            (this.props.errors.directDebit?.accountNumber && this.props.touched.directDebit?.accountNumber) 
                            || this.props.validDetails?.isValid === false
                                ? 'form__group invalid'
                                : "form__group"
                        }>
                            <label className={"form__label"}>{this.context.labels.payment_account_number_placeholder}</label>
                            <Field type={"text"}
                                   inputMode={"numeric"}
                                   maxLength="8"
                                   value={this.props.values?.directDebit.accountNumber}
                                   name="directDebit.accountNumber"
                                   className={"form__control"}
                                   placeholder={this.context.labels.payment_account_number_placeholder}
                                   onKeyDown={this.checkKeyInput}
                                   onChange={this.callFieldChangeFromInput}
                                   data-testid={"journeyPaymentInformation-account_number"} />
                            {(this.props.errors.directDebit?.accountNumber && this.props.touched.directDebit?.accountNumber) 
                            || this.props.validDetails?.isValid === false ? (
                                <span className={"form__error"}>
                                    {this.props.errors.directDebit?.accountNumber ?? this.props.validDetails?.error}
                                </span>
                            ): null}
                        </div>
                    </div>
                    <div className={"col-sm-4"}>
                        <div className={
                            (this.props.errors.directDebit?.sortCode && this.props.touched.directDebit?.sortCode) 
                            || this.props.validDetails?.isValid === false
                                ? 'form__group invalid'
                                : "form__group"
                        }>
                            <label className={"form__label"}>{this.context.labels.payment_sort_code_placeholder}</label>
                            <Field type={"text"}
                                   inputMode={"numeric"}
                                   maxLength="6"
                                   name="directDebit.sortCode"
                                   className={"form__control"}
                                   value={this.props.values?.directDebit.sortCode}
                                   placeholder={this.context.labels.payment_sort_code_placeholder}
                                   onKeyDown={this.checkKeyInput}
                                   onChange={this.callFieldChangeFromInput}
                                   data-testid={"journeyPaymentInformation-account_sort_code"} />
                            {(this.props.errors.directDebit?.sortCode && this.props.touched.directDebit?.sortCode) 
                            || this.props.validDetails?.isValid === false ? (
                                <span className={"form__error"}>
                                    {this.props.errors.directDebit?.sortCode ?? this.props.validDetails?.error}
                                </span>
                            ): null}
                        </div>
                    </div>
                    {this.props.isValidating &&
                        <div className={"col-12"}>
                            <Loader />
                        </div>
                    }
                    <div className={"col-12"}>
                        <div className={
                            this.props.errors.directDebit?.authorisation && this.props.touched.directDebit?.authorisation
                                ? 'form__group invalid'
                                : "form__group"
                        }>
                            <div className={"checkbox"}>
                                <Field type={"checkbox"}
                                       id={"authorisation"}
                                       name="directDebit.authorisation"
                                       onChange={this.callFieldChangeFromInput} />
                                <label htmlFor={"authorisation"}>
                                    <div className={"dummy"}>
                                        <IconTick wrapperClass={"dummy__tick"}/>
                                    </div>
                                    {this.context.labels.payment_confirmation}
                                </label>
                            </div>
                            {this.props.errors.directDebit?.authorisation && this.props.touched.directDebit?.authorisation ? (
                                <span className={"form__error"}>{this.props.errors.directDebit?.authorisation}</span>
                            ): null}

                            <div className={"checkbox"}>
                                <Field type={"checkbox"}
                                       id={"different-billing-address"}
                                       name="directDebit.sameBillingAddress"
                                       onChange={this.callFieldChangeFromInput} />
                                <label htmlFor={"different-billing-address"}>
                                    <div className={"dummy"}>
                                        <IconTick wrapperClass={"dummy__tick"}/>
                                    </div>
                                    {this.context.labels.payment_same_billing_address}
                                </label>
                            </div>
                        </div>
                        {!this.props.values.directDebit.sameBillingAddress
                            ? (
                                <div className={"billing-address"}>
                                    <div className={"grid"}>
                                        <div className={"col-12"}>
                                            <div className={
                                                billingErrors?.addressLine1 && billingTouched?.addressLine1
                                                    ? 'form__group invalid'
                                                    : "form__group"
                                            }>
                                                <label className={"form__label"}>
                                                    {this.context.labels.payment_billing_address_line_1_placeholder}
                                                </label>
                                                <Field name="directDebit.billingAddress.addressLine1"
                                                       type={"text"}
                                                       className={"form__control"}
                                                       placeholder={this.context.labels.payment_billing_address_line_1_placeholder}
                                                       onChange={this.callFieldChangeFromInput} />
                                                {billingErrors?.addressLine1 && billingTouched?.addressLine1 ? (
                                                    <span className={"form__error"}>{billingErrors?.addressLine1}</span>
                                                ): null}
                                            </div>
                                        </div>
                                        <div className={"col-12"}>
                                            <div className={
                                                billingErrors?.addressLine2 && billingTouched?.addressLine2
                                                    ? 'form__group invalid'
                                                    : "form__group"
                                            }>
                                                <label className={"form__label"}>
                                                    {this.context.labels.payment_billing_address_line_2_placeholder}
                                                </label>
                                                <Field name="directDebit.billingAddress.addressLine2"
                                                       type={"text"}
                                                       className={"form__control"}
                                                       placholder={this.context.labels.payment_billing_address_line_2_placeholder}
                                                       onChange={this.callFieldChangeFromInput} />
                                                {billingErrors?.addressLine2 && billingTouched?.addressLine2 ? (
                                                    <span className={"form__error"}>{billingErrors?.addressLine2}</span>
                                                ): null}
                                            </div>
                                        </div>
                                        <div className={"col-12"}>
                                            <div className={
                                                billingErrors?.addressLine3 && billingTouched?.addressLine3
                                                    ? 'form__group invalid'
                                                    : "form__group"
                                            }>
                                                <label className={"form__label"}>
                                                    {this.context.labels.payment_billing_address_line_3_placeholder}
                                                </label>
                                                <Field name="directDebit.billingAddress.addressLine3"
                                                       type={"text"}
                                                       className={"form__control"}
                                                       placeholder={this.context.labels.payment_billing_address_line_3_placeholder}
                                                       onChange={this.callFieldChangeFromInput} />
                                                {billingErrors?.addressLine3 && billingTouched?.addressLine3 ? (
                                                    <span className={"form__error"}>{billingErrors?.addressLine3}</span>
                                                ): null}
                                            </div>
                                        </div>
                                        <div className={"col-sm-4"}>
                                            <div className={
                                                billingErrors?.town && billingTouched?.town
                                                    ? 'form__group invalid'
                                                    : "form__group"
                                            }>
                                                <label className={"form__label"}>
                                                    {this.context.labels.payment_billing_town_placeholder}
                                                </label>
                                                <Field name="directDebit.billingAddress.town"
                                                       type={"text"}
                                                       className={"form__control"}
                                                       placeholder={this.context.labels.payment_billing_town_placeholder}
                                                       onChange={this.callFieldChangeFromInput} />
                                                {billingErrors?.town && billingTouched?.town ? (
                                                    <span className={"form__error"}>{billingErrors?.town}</span>
                                                ): null}
                                            </div>
                                        </div>
                                        <div className={"col-sm-4"}>
                                            <div className={
                                                billingErrors?.county && billingTouched?.county
                                                    ? 'form__group invalid'
                                                    : "form__group"
                                            }>
                                                <label className={"form__label"}>
                                                    {this.context.labels.payment_billing_county_placeholder}
                                                </label>
                                                <Field name="directDebit.billingAddress.county"
                                                       type={"text"}
                                                       className={"form__control"}
                                                       placeholder={this.context.labels.payment_billing_county_placeholder}
                                                       onChange={this.callFieldChangeFromInput} />
                                                {billingErrors?.county && billingTouched?.county ? (
                                                    <span className={"form__error"}>{billingErrors?.county}</span>
                                                ): null}
                                            </div>
                                        </div>
                                        <div className={"col-sm-4"}>
                                            <div className={
                                                billingErrors?.postCode && billingTouched?.postCode
                                                    ? 'form__group invalid'
                                                    : "form__group"
                                            }>
                                                <label className={"form__label"}>
                                                    {this.context.labels.payment_billing_postcode_placeholder}
                                                </label>
                                                <Field name="directDebit.billingAddress.postCode"
                                                       type={"text"}
                                                       className={"form__control"}
                                                       placeholder={this.context.labels.payment_billing_postcode_placeholder}
                                                       onChange={this.callFieldChangeFromInput} />
                                                {billingErrors?.postCode && billingTouched?.postCode ? (
                                                    <span className={"form__error"}>{billingErrors?.postCode}</span>
                                                ): null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (<></>)
                        }
                    </div>
                </div>
            </>
        );
    }
}

export default PaymentDetails;