import React, { useContext } from "react";
import { AppData } from "../../providers/AppData";
import IconTick from "../icons/IconTick";

type NoPaymentDetailsProps = {};

export const NoPaymentDetails: React.FunctionComponent<NoPaymentDetailsProps> = () => {
  const context = useContext(AppData);

  return (
    <div className={"form__intro"}>
      <h2>
        <IconTick wrapperClass={"icon"} />
        {context.labels.no_payment_title}
      </h2>
      <p>{context.labels.no_payment_sub_title}</p>
    </div>
  );
};
