import styled from "styled-components";
import { breakpoints } from '../../../lib/breakpoints';

const StyledButton = styled.button`
  cursor: pointer;

  background-color: var(--button-primary);
  color: var(--button-primary__text);

  appearance: none;
  box-shadow: none;

  border: none;
  border-radius: 2.3rem;

  line-height: 2.1rem;
  font-family: var(--font-family);
  font-size: 1.5rem;
  padding: 1rem 1rem;

  transition: background-color 0.2s linear, opacity 0.2s linear;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  &:hover,
  &:focus {
    background-color: var(--button-primary--hover);
    color: var(--button-primary--hover__text);
  }

  @media ${breakpoints.medium} {
    padding: 1.7rem 2rem;
    font-size: 2rem;
    min-width: 21rem;
    width: 100%;
  }
`;

export type ButtonProps = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
  children?: React.ReactNode;
};

export default function Button(
  { onClick, disabled, children }: ButtonProps = { onClick: () => { }, disabled: false }
) {
  return (
    <StyledButton type="button" onClick={onClick} disabled={disabled}>
      {children && children}
    </StyledButton>
  );
}
