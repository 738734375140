import React, { useContext } from 'react';
import IconProps from '../../interfaces/Icon';
import { AppData } from '../../providers/AppData';

const IconTick = (props: IconProps) => {
  const context = useContext(AppData);
  const image = context.branding.tick_icon;
  return (
    <>
      {image && image.url ? (
        <>
            <img src={image.url} alt="" className={props.wrapperClass || ''} style={{backgroundColor: "transparent"}}/>
        </>
      ) : (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
            className={props.wrapperClass || ''}
          >
            <path
              d="M22.39,9.36a1.19,1.19,0,0,1,0,1.69l0,0-8.81,8.57a1.27,1.27,0,0,1-1.77,0L7.12,15l0,0a1.2,1.2,0,0,1,0-1.69,1.27,1.27,0,0,1,1.77,0L12.74,17l7.89-7.67A1.26,1.26,0,0,1,22.39,9.36Z"
              fillRule="evenodd"
            />
          </svg>
        </>
      )}
    </>
  );
};

export default IconTick;
