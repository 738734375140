// Lib
import React from "react";
import { animateScroll as scroll } from 'react-scroll';
import styled from "styled-components";

// Components
import QuoteItem from "./QuoteItem";

// Icons
import IconElectricity from "../icons/IconElectricity";
import IconGas from "../icons/IconGas";
import IconTick from "../icons/IconTick";

// TS Definitions
import { Quote } from "../../models/quote.model";
import { FuelType } from "../../models/utility-detail";
import { SupplierConfig } from "../../models/supplier-config.model";
import { QuoteListProps, QuoteListState } from "../../interfaces/Quotes";
import { AppLayoutData } from "../../interfaces/Journey";
import { AppData } from "../../providers/AppData";
import RecommendedBadge from "../misc/RecommendedBadge";

type DisplayableQuote = {
    isRecommended: boolean;
    quote: Quote;
  };

const RecommendedWrapper = styled.div`
  outline: solid var(--primary) 2px;
  border: solid var(--primary) 4px;
  border-radius: 3px;
  position: relative;

  > div:first-child {
    position: absolute;
    right: 0;
  }

  > .quote {
    padding-top: 5rem;
  }

  @media (max-width: 1200px) {
    > .quote {
      padding-top: 6rem;
    }
  }
`;
class QuoteList extends React.Component<QuoteListProps, QuoteListState> {
    static contextType: React.Context<AppLayoutData> = AppData;
    context!: React.ContextType<typeof AppData>;
    private wrapper : React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();

    state : QuoteListState = {
        isSelected : this.props.quoteDetails?.quote && !!(Object.keys(this.props.quoteDetails.quote)?.length),
        selectedQuote: this.props.quoteDetails?.quote && (Object.keys(this.props.quoteDetails?.quote)?.length) ? this.props.quoteDetails.quote : {} as Quote
    }

    public quoteSelected = (quote: Quote, fuelType: string | number | FuelType): void => {
        if(this.context.toggles.use_octopus_journey){
            if(quote.supplier === "dyce"){
                this.setState({
                    isSelected: true,
                    selectedQuote : quote
                }, () => {
                    if(typeof this.props.onQuoteSelected !== 'undefined'){
                        switch(fuelType){
                            case FuelType.Electricity:
                            case FuelType[FuelType.Electricity]:
                                return this.props.onQuoteSelected(0, quote);
                            case FuelType.Gas:
                            case FuelType[FuelType.Gas]:
                                return this.props.onQuoteSelected(1, quote);
                        }
                    }
                });
                return;
            } else {
                window.open("https://quote.tickd.co.uk/saved-journey?id=l_KNsVvEi74xqJq_LWDBqArf12ZLHdOjNMYypLAVclBj8PHTCLTzLCnRY29tPlU3Uq3i6uqkO85ZUAUKSOmEcOnmoUauhHGOI4QwBY1nZ0fnxg0YTspjeT98ckp1WiFm")
                return;
            }
        }

        this.setState({
            isSelected: true,
            selectedQuote : quote
        }, () => {
            if(typeof this.props.onQuoteSelected !== 'undefined'){
                switch(fuelType){
                    case FuelType.Electricity:
                    case FuelType[FuelType.Electricity]:
                        return this.props.onQuoteSelected(0, quote);
                    case FuelType.Gas:
                    case FuelType[FuelType.Gas]:
                        return this.props.onQuoteSelected(1, quote);
                }
            }
        });
    }

    public clearSelected = (fuelType: string | number | FuelType): void => {
        this.setState({
            isSelected: false,
            selectedQuote : {} as Quote
        }, () => {
            if(typeof this.props.onQuoteCleared !== 'undefined'){
                switch(fuelType){
                    case FuelType.Electricity:
                    case FuelType[FuelType.Electricity]:
                        return this.props.onQuoteCleared(0);
                    case FuelType.Gas:
                    case FuelType[FuelType.Gas]:
                        return this.props.onQuoteCleared(1);
                }
            }
        });
    }

    public scrollTo = ():void => {
        if(this.wrapper.current){
            if(typeof this.wrapper?.current !== "undefined"){
                const offset = this.wrapper?.current?.getBoundingClientRect(),
                      top = ((offset?.top ?? 0) + (document.scrollingElement?.scrollTop ?? 0));

                scroll.scrollTo(top);
            }
        }
    }

    public renderLabel = (serviceType: number | string | FuelType): JSX.Element => {
        switch(serviceType){
            case FuelType.Electricity:
            case FuelType[FuelType.Electricity]: 
                return (
                    <h2 className={"quote-list__title"}>
                        {
                            this.state.isSelected
                                ? <IconTick wrapperClass={"quote-list__icon"} />
                                : <IconElectricity wrapperClass={"quote-list__icon"} />
                        }
                        <span>{"Electricity"}</span>
                    </h2>
                );
            case FuelType.Gas:
            case FuelType[FuelType.Gas]:
                return (
                    <h2 className={"quote-list__title"}>
                        {
                            this.state.isSelected
                                ? <IconTick wrapperClass={"quote-list__icon"} />
                                : <IconGas wrapperClass={"quote-list__icon"} />
                        }
                        <span>{"Gas"}</span>
                    </h2>
                );
        }

        return (<></>);
    }

    public getSupplier = (quote: Quote) : SupplierConfig => {
        const supplier = Object.values(this.props.suppliers as Array<SupplierConfig>).filter((supplier: SupplierConfig) => {
            return quote.supplier === supplier.reference;
        });

        return supplier[0] || {} as SupplierConfig;
    }
    
      public sortResults = (results: Array<Quote>) => {
    
        if (!this.context.toggles.show_recommended_flag) {
          return results.map<DisplayableQuote>((quote) => ({
            quote: quote,
            isRecommended: false,
          }));
        }
        
        const termLengthInMonths = this.context.variables.recommended_flag_term_length ?? 3;

        const firstId = results.find((quote) => quote.termInMonths === termLengthInMonths)?.id;
        if (!firstId) {
          return results.map<DisplayableQuote>((quote) => ({
            quote: quote,
            isRecommended: false,
          }));
        }

        return [{
            quote: results.find((quote) => quote.id === firstId),
            isRecommended: true,
        },
        ...results
            .filter((quote) => quote.id !== firstId)
            .map<DisplayableQuote>((quote) => ({
              quote: quote,
              isRecommended: false,
            })),
        ];
      };

    public getQuoteLists = (): JSX.Element => {
        let markup: JSX.Element;

        if(this.state.isSelected){
            markup = (
                <ul className={"quote-list__results"}>
                    <li>
                         <QuoteItem quote={this.state.selectedQuote}
                                    fuelType={this.props.quotes[0].fuelType}
                                    supplier={this.getSupplier(this.state.selectedQuote)}
                                    identifier={`quote-${this.props.quotes[0].fuelType}-1`}
                                    onClearSelection={ this.clearSelected }
                                    selected={this.state.isSelected} />
                    </li>
                </ul>
            )
        } else {
            let results: Array<Quote> = this.props.quotes;

            if(this.props.isGreen){
                results = results.filter((quote: Quote) => quote.isGreen);
            }

            if(this.props.isFastSwitching){
                results = results.filter((quote: Quote) => quote.isFastSwitching);
            }

            if(this.props.termLength !== null){
                results = results.filter((quote: Quote) => quote.termInMonths === this.props.termLength);
            }

            if(this.props.docusignRef !== null){

                results = results.filter((quote: Quote) => {
                    const supplier = (Object.values(this.props.suppliers) as Array<SupplierConfig>).find((supplier: SupplierConfig) => {
                        return supplier.reference === quote.supplier;
                    });

                    if(!supplier)
                        return false;

                    if(!supplier.captureDocumentSignature)
                        return true;

                    return quote.supplier === this.props.docusignRef;
                });
            }

            const sortedResult = this.sortResults(results);

            // if(this.context.toggles.use_octopus_journey){
            //     sortedResult.map()
            // }

            if(sortedResult){
                markup = (
                    <ul className={"quote-list__results"}>
                        {Object.values(sortedResult as Array<DisplayableQuote>).map((displayableQuote: DisplayableQuote, idx: number): JSX.Element => {
                            if(displayableQuote.isRecommended){
                            return(
                            <li key={`quote-${this.props.quotes[0].fuelType}-${idx}`} >
                                <RecommendedWrapper>
                                    <RecommendedBadge />
                                    <QuoteItem
                                        quote={displayableQuote.quote}
                                        fuelType={displayableQuote.quote.fuelType}
                                        supplier={this.getSupplier(displayableQuote.quote)}
                                        identifier={`quote-${idx}`}
                                        onSelected={this.quoteSelected}
                                        selected={this.state.isSelected}
                                    />
                                </RecommendedWrapper>
                            </li>
                            )
                            }
                            return (
                                <li key={`quote-${this.props.quotes[0].fuelType}-${idx}`} >
                                  <QuoteItem
                                    quote={displayableQuote.quote}
                                    fuelType={displayableQuote.quote.fuelType}
                                    supplier={this.getSupplier(displayableQuote.quote)}
                                    identifier={`quote-${idx}`}
                                    onSelected={this.quoteSelected}
                                    selected={this.state.isSelected}
                                  />
                                </li>
                            );
                        })}
                    </ul>
                );
            } else {
                markup = (
                  <div className={'notice'}>
                    {this.props.changedFromDualFuel ? (
                      <p>
                        {
                          this.context.labels
                            .quote_list_continue_dual_fuel_message
                        }
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                );
            }
        }

        return markup;
    }

    public render = (): JSX.Element => {
        let quoteLists : JSX.Element = this.getQuoteLists();

        return (
            <div className={(this.state.isSelected) ? "quote-list completed" : "quote-list"} ref={this.wrapper}>
                { this.renderLabel(this.props.quotes[0].fuelType)}
                { quoteLists }
            </div>
        );
    }
}

export default QuoteList;