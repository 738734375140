export const SAVE_JOURNEY = "SAVE_JOURNEY";
export const CREATE_JOURNEY = "CREATE_JOURNEY";
export const SAVE_QUOTES = "SAVE_QUOTES";
export const CLEAR_QUOTES = "CLEAR_QUOTES";
export const SAVE_SUPPLIERS = "SAVE_SUPPLIERS";
export const VIEWED_TOUR = "VIEWED_TOUR";
export const UPDATE_STAGE = "UPDATE_STAGE";
export const UPDATE_STAGES = "UPDATE_STAGES";
export const REMOVE_STAGE = "REMOVE_STAGE";
export const CHANGED_FROM_DUAL_FUEL = "CHANGED_FROM_DUAL_FUEL";
export const RESTART_JOURNEY = "RESTART_JOURNEY";
export const SHOW_MODAL = "SHOW_MODAL";
export const SAVE_ELECTRICITY_QUOTES = "SAVE_ELECTRICITY_QUOTES";
export const SAVE_GAS_QUOTES = "SAVE_GAS_QUOTES";
export const SAVE_ELECTRICITY_ERROR = "SAVE_ELECTRICITY_ERROR";
export const SAVE_GAS_ERROR = "SAVE_GAS_ERROR";