import { combineReducers } from "redux";
import Journey from "./Journey";
import Quotes from "./Quotes";
import Suppliers from "./Suppliers";
import ViewedTour from "./ViewedTour";
import CompletedStages from "./CompletedStages";
import ChangedFromDualFuel from "./ChangedFromDualFuel";
import Analytics from "../analytics/reducer";
import MetadataReducer from "./Metadata";

export default combineReducers({
    journey: Journey,
    quotes: Quotes,
    suppliers : Suppliers,
    viewedTour : ViewedTour,
    changedFromDualFuel : ChangedFromDualFuel,
    completedStages : CompletedStages,
    analytics: Analytics,
    metadata: MetadataReducer
});