// Lib
import React, { FunctionComponent, useState, useContext } from "react";
import { format } from "date-fns";

// Helpers
import { addressFormat } from "../../lib/addressFormat";

// Components
import Accordion from "../misc/Accordion";
import AccordionItem from "../misc/AccordionItem";
import SupplyOverview from "./SupplyOverview";

// Icons
import Spinner from "../icons/Spinner";

// TS Definitions
import { AppData } from "../../providers/AppData";
import {
  Journey,
  JourneyUtilityType,
  QuoteDetails,
} from "../../models/journey.model";
import { SupplierConfig } from "../../models/supplier-config.model";
import { FuelType } from "../../models/utility-detail";
import styled from "styled-components";
import { AppState } from "../../stores/store";
import { useSelector } from "react-redux";
import ContactAdvisorModal from "../ContactAdvisorModal";
import { FeatureToggle } from "../misc/FeatureToggle";
import { MetadataState } from "../../stores/reducers/Metadata";

const Button = styled.button`
  background-color: var(--border);
  color: var(--button-primary);
`

const ErrorText = styled.span`
  color: var(--error);
`;

interface Props {
  forceDetailsOpen: boolean;
  showButton: boolean;
  suppliers: SupplierConfig[];
  onSubmit: () => Promise<boolean>;
  onCompleted: () => void;
}                                               
const QuoteOverview: FunctionComponent<Props> = ({
  forceDetailsOpen,
  showButton,
  suppliers,
  onSubmit,
  onCompleted
}: Props) => {
  const journey = useSelector<AppState, Journey>(state => state.journey);
  const metadata = useSelector<AppState, MetadataState>(state => state.metadata);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [hasErrorSubmitting, setHasErrorSubmitting] = useState<boolean>(false);
  const [showContactAdvisorModal, setShowContactAdvisorModal] = useState<boolean>(false);
  const { labels } = useContext(AppData);

  const GetSubtotal = (): number => {
    if (!journey.utilities.quoteDetails.length) return 0;

    const totals: number[] = journey.utilities.quoteDetails.map(
      (quoteDetails: QuoteDetails) => {
        if (typeof quoteDetails.quote.estimatedAnnualCost === "undefined") return 0;

        return quoteDetails.quote.estimatedAnnualCost / 12;
      }
    );

    return totals.reduce(
      (accumulator: number, currentValue: number) => accumulator + currentValue
    );
  };

  const StartSubmit = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.preventDefault();

    setHasErrorSubmitting(false);
    setIsSubmitting(true);

    onSubmit().then((success) => {
      setIsSubmitting(false);
      if (success) {
        onCompleted();
      } else {
        setHasErrorSubmitting(true);
      };
    });
  };

  const GetSupplyOverview = (
    quoteDetails: QuoteDetails,
    idx: number
  ): JSX.Element | boolean => {
    if (!Object.keys(quoteDetails.quote).length) {
      return false;
    }

    const supplierArray: Array<SupplierConfig> = Object.values(suppliers);
    const filteredSuppliers: SupplierConfig[] = supplierArray.filter(
      (supplier: SupplierConfig) => {
        return supplier.reference === quoteDetails.quote.supplier;
      }
    );
    const supplier: SupplierConfig = filteredSuppliers.length
      ? filteredSuppliers[0]
      : ({} as SupplierConfig);
    const subtotal: string = new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
    }).format(quoteDetails.quote?.estimatedAnnualCost / 12);

    return (
      <AccordionItem
        key={`quotes-overview-${quoteDetails.quoteType}`}
        title={quoteDetails.quoteType === FuelType.Electricity ? "Electricity" : "Gas"}
        subtotal={subtotal}
      >
        <SupplyOverview
          mpxn={journey.supplyAddress.mpxn}
          quoteDetails={quoteDetails}
          eventKeyIdx={idx + 1}
          supplier={supplier}
        />
      </AccordionItem>
    );
  };

  const GetSubtotalMarkup = (): JSX.Element => {
    const subtotal = GetSubtotal();
    const formattedSubtotal = new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
    }).format(subtotal);

    if (subtotal !== 0) {
      return (
        <div className={"quote-sidebar__subtotal"}>
          <dl className={"details details--inline"}>
            <div>
              <dt>Monthly Total Cost:</dt>
              <dd className={"heading-3"}>{formattedSubtotal}</dd>
            </div>
          </dl>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const GetMpxn = (type: FuelType) => {
    if (type === FuelType.Electricity && metadata.partialMpan) {
      return metadata.partialMpan
    }
    if (type === FuelType.Gas && metadata.partialMprn) {
      return metadata.partialMprn
    }
    
    return 'N/A';
  }

  const GetJourneyOverview = (): JSX.Element => {
    let mpan: JSX.Element = <></>;
    let mprn: JSX.Element = <></>;

    if (
      journey.journeyUtilityType === JourneyUtilityType.DualFuel ||
      journey.journeyUtilityType === JourneyUtilityType.Electricity
    ) {
      mpan = (
        <div>
          <dt>{labels.quote_overview_mpan ?? "MPAN:"}</dt>
          <dd>
            {GetMpxn(FuelType.Electricity)}
          </dd>
        </div>
      );
    }

    if (
      journey.journeyUtilityType === JourneyUtilityType.DualFuel ||
      journey.journeyUtilityType === JourneyUtilityType.Gas
    ) {
      mprn = (
        <div>
          <dt>{labels.quote_overview_mprn ?? "MPRN:"}</dt>
          <dd>
            {GetMpxn(FuelType.Gas)}
          </dd>
        </div>
      );
    }

    const quoteDetails = journey?.utilities?.quoteDetails ?? [];
    return (
      <dl className={"details"}>
        <div>
          <dt>{labels.quote_overview_company_name ?? "Company Name:"}</dt>
          <dd>{journey.supplyCompany?.name || ""}</dd>
        </div>
        <div>
          <dt>{labels.quote_overview_supply_address ?? "Supply Address:"}</dt>
          <dd>{addressFormat(journey.supplyAddress)}</dd>
        </div>
        {mpan}
        {mprn}
        {quoteDetails.map((detail, idx) => (
          <div key={`quote-detail-${idx}`}>
            <dt>
              {detail.quoteType === 0
                ? labels.quote_overview_electricity_eac ?? "Electricity EAC:"
                : labels.quote_overview_gas_eac ?? "Gas EAC:"}
            </dt>
            <dd>{(detail.supplyConsumption.eac && detail.supplyConsumption.eac > 0) ? `${detail.supplyConsumption.eac}kWh` : 'N/A'}</dd>
          </div>
        ))}
        <div>
          <dt>{labels.quote_overview_start_date ?? "Start Date:"}</dt>
          {journey.quotePeriod !== null ? (
            <dd>{format(journey.quotePeriod as Date, "EEE do LLL yyyy")}</dd>
          ) : (
            <dd>N/A</dd>
          )}
        </div>
        <div>
          <dt>{labels.quote_overview_end_date ?? "End Date:"}</dt>
          {journey?.utilities?.quoteDetails[0]?.quote?.end ? (
            <dd>
              {format(
                new Date(journey.utilities.quoteDetails[0].quote.end),
                "EEE do LLL yyyy"
              )}
            </dd>
          ) : (
            <dd>N/A</dd>
          )}
        </div>
      </dl>
    );
  };

  const HasSupplierChosen = (): boolean => {
    const quotesSelected = journey.utilities.quoteDetails.filter(
      (quoteDetails) => Object.values(quoteDetails.quote).length
    );

    return !!quotesSelected.length;
  };

  return (
    <div className={'quote-sidebar'}>
      <Accordion
        defaultIndex={
          forceDetailsOpen ? 0 : HasSupplierChosen() ? null : 0
        }
      >
        <AccordionItem key={'quotes-overview-details'} title={'Your Details'}>
          {GetJourneyOverview()}
        </AccordionItem>
        {journey.utilities.quoteDetails.map(
          GetSupplyOverview
        )}
        {GetSubtotalMarkup()}
      </Accordion>
      {showButton && (
        <>
          <button
            type={'button'}
            className={'button quote-sidebar__submit'}
            data-loading={isSubmitting ? 'true' : ''}
            disabled={isSubmitting}
            onClick={StartSubmit}
            data-testid={'journeyProgressToContactInformation-btn'}
          >
            <Spinner wrapperClass={'button__spinner'} />
            {labels.quotes_switch_button}
          </button>
          {!window.location.pathname.includes('/switch') && (
            <FeatureToggle
              name={'show_contact_advisor_button'}
              fallback={false}
              render={
                <Button
                  type={'button'}
                  className={'button quote-sidebar__submit'}
                  onClick={() => setShowContactAdvisorModal(true)}
                >
                  Speak to an advisor
                </Button>
              }
              otherwiseRender={<></>}
            />
          )}
        </>
      )}
      {hasErrorSubmitting && (
        <ErrorText>An Error occurred, please try again.</ErrorText>
      )}
      <ContactAdvisorModal
        showModal={showContactAdvisorModal}
        toggleModal={() => setShowContactAdvisorModal(!showContactAdvisorModal)}
      />
    </div>
  )
}

export default QuoteOverview;
