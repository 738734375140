import styled from "styled-components";
import { breakpoints } from "../../../lib/breakpoints";

export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: grey;
  opacity: 70%;
`;

export const Modal = styled.div`
  opacity: 100%;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 2rem);
  max-height: calc(100vh - 1rem);
  overflow-y: scroll;

  @media ${breakpoints.medium} { 
    width: 100%;
    max-width: 600px;
  }

  @media ${breakpoints.large} {
    overflow-y: unset; 
  }
  
  z-index: 9999;

  background-color: var(--body-background);
  border: 1px solid var(--primary);
  padding: 20px;
`;

export const ButtonBase = styled.button`
  border-radius: 0.4rem;
  line-height: 2.1rem;
  font-family: var(--font-family);
  font-size: 1.5rem;
  padding: 1rem 2rem;
  cursor: pointer;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
