// Lib
import React from "react";
import ProgressBar from "../ProgressBar";
import { connect, ConnectedProps } from "react-redux";

// Store Actions
import { loadQuotes } from "../../stores/actions/loadQuotes";

// Helpers
import { addressFormat } from "../../lib/addressFormat";

// TS Definitions
import { JourneyUtilityType, QuoteDetails } from "../../models/journey.model";
import { AppLayoutData, BrandingImageData } from "../../interfaces/Journey";
import { LoadingQuotesProps, QuoteDetailsItem } from "../../interfaces/Quotes";
import { ConsumptionPeriods } from "../../models/consumption-periods";

// Components
import IconTick from "../icons/IconTick";

// Providers
import { AppData } from "../../providers/AppData";
import { FuelType } from "../../models/utility-detail";
import { AppState } from "../../stores/store";
import QuotesLoadMessage from "./QuotesLoadMessage";


const mapStateToProps = (state: AppState) => {
  const shouldLoadElectricity =
    (state.journey.journeyUtilityType === JourneyUtilityType.Electricity ||
      state.journey.journeyUtilityType === JourneyUtilityType.DualFuel) &&
    !state.quotes.electricity.quotes.length;
  const shouldLoadGas =
    (state.journey.journeyUtilityType === JourneyUtilityType.Gas ||
      state.journey.journeyUtilityType === JourneyUtilityType.DualFuel) &&
    !state.quotes.gas.quotes.length;
  return {
    journey: state.journey,
    shouldLoadQuotes: shouldLoadElectricity || shouldLoadGas,
  };
};

const connector = connect(mapStateToProps, { loadQuotes });

class LoadingQuotes extends React.Component<ConnectedProps<typeof connector> & LoadingQuotesProps>{
    static contextType: React.Context<AppLayoutData> = AppData;
    context!: React.ContextType<typeof AppData>;

    private progressBar: React.RefObject<ProgressBar> = React.createRef<ProgressBar>();
    private wrapper: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();

    public componentDidMount = () => {
        requestAnimationFrame(() => {
            this.progressBar?.current?.updateProgress(80, '30s');
        });

        if(this.props.shouldLoadQuotes){
            this.props.loadQuotes()
                .then(() => {
                    this.progressBar?.current?.updateProgress(100, '2s');
                });
        } else {
            this.wrapper?.current?.classList.add('hidden');
        }
    }

    private triggerCloseAnimation = (): void => {
        if(this.wrapper !== null && this.wrapper.current !== null){
            this.wrapper.current.classList.add('loaded');
        }
    }

    private hideWrapper = (ev : React.AnimationEvent<HTMLDivElement>): void => {
        if(ev.target === this.wrapper?.current){
            if(this.wrapper.current !== null){
                this.wrapper.current.classList.add('hidden');
                this.props.onQuotesLoaded();
            }
        }
    }

    private getSpendInterval = () => {
        if(this.props.journey.utilities.quoteDetails.length){
            switch (this.props.journey.utilities.quoteDetails[0].supplyConsumption.period as ConsumptionPeriods){
                case ConsumptionPeriods.Monthly:
                    return this.context.labels.supply_consumption_period_month;
                case ConsumptionPeriods.Yearly:
                    return this.context.labels.supply_consumption_period_year;
                case ConsumptionPeriods.Quarterly:
                    return this.context.labels.supply_consumption_period_quarter;
                default:
                    return "";
            }
        } else {
            return '';
        }
    }

    private generateQuoteDetailsList = (): Array<QuoteDetailsItem> => {
        let quoteDetails: Array<QuoteDetailsItem> = [];

        const spendInterval = this.getSpendInterval();

        if(this.props.journey.journeyUtilityType !== null){
            let content = '';

            switch (this.props.journey.journeyUtilityType) {
                case JourneyUtilityType.Electricity:
                    content = "Electricity";
                    break;
                case JourneyUtilityType.Gas:
                    content = "Gas";
                    break;
                case JourneyUtilityType.DualFuel:
                    content = "Electricity & Gas";
                    break;
            }


            quoteDetails.push({
                title : "Service Type",
                content : content
            });
        }

        if(this.props.journey.journeyUtilityType === JourneyUtilityType.DualFuel){
            const detailsToDisplay = this.props.journey.utilities.quoteDetails.map<QuoteDetailsItem>((quoteDetail : QuoteDetails) => {
                const fuel = quoteDetail.quoteType === FuelType.Electricity ? 'Electricity' : 'Gas'
                if(quoteDetail.supplyConsumption.spend !== null && quoteDetail.supplyConsumption.spend > 0){
                    return {
                        title: `Current ${fuel} Spend`,
                        content: `£${quoteDetail.supplyConsumption.spend} ${spendInterval}`
                    }
                } else {
                    return {
                        title: `Current ${fuel} Usage`,
                        content: `${quoteDetail.supplyConsumption.energy}kWh ${spendInterval}`
                    }
                }
            });
            quoteDetails.push(...detailsToDisplay);
        } else {
            if(typeof this.props.journey.utilities.quoteDetails !== "undefined" && this.props.journey.utilities.quoteDetails.length){
                let spend = this.props.journey.utilities.quoteDetails[0].supplyConsumption.spend;
                let energy = this.props.journey.utilities.quoteDetails[0].supplyConsumption.energy;

                if (spend || energy) {
                    const type = (spend !== null && spend > 0) ? "spend" : "usage";

                    quoteDetails.push({
                        title : (type === 'spend') ? "Current Spend" : "Current Usage",
                        content : (type === 'spend')
                            ? `£${spend} ${spendInterval}`
                            : `${energy}kWh ${spendInterval}`
                    });
                }
                else {
                    let eac = this.props.journey.utilities.quoteDetails[0].supplyConsumption.eac;
                    
                    if (eac) {
                        let monthlyUsage = Math.round(Number(eac) / 12);
                        
                        quoteDetails.push({
                            title : "Current Usage",
                            content : `${monthlyUsage}kWh ${spendInterval}`
                        });
                    }
                }                
            }
        }

        if( Object.values(this.props.journey.supplyAddress).length ){
            quoteDetails.push({
                title : "Supply Address",
                content : addressFormat(this.props.journey.supplyAddress)
            });
        }

        return quoteDetails;
    }

    public render = (): JSX.Element => {
        const quoteDetailsList = this.generateQuoteDetailsList();
        const logo = this.context.branding["logo"] as BrandingImageData;
        const loadingQuotesImage = this.context.branding["loading_quotes"] as BrandingImageData;
        const loadingImage = loadingQuotesImage?.url ? loadingQuotesImage : logo;
        
        return(
            <div className={"loading-quotes"} ref={this.wrapper} onAnimationEnd={this.hideWrapper}>
                <div className={"loading-quotes__inner"}>
                    {
                        loadingImage.url && <img src={loadingImage.url} alt={loadingImage.alt ?? 'company logo'} className={"loading-quotes__logo"}/>
                    } 
                    <h2>{this.context.labels.gathering_quotes_title}</h2>
                    <QuotesLoadMessage />
                    <ProgressBar ref={this.progressBar} initialValue={0} onComplete={this.triggerCloseAnimation} />
                    <ul className={"quote-details"}>
                        {quoteDetailsList.map((element: QuoteDetailsItem) => {
                            return (
                                <li key={element.title}>
                                    <IconTick wrapperClass={"quote-details__icon"}/>
                                    <h4>{ element.title }</h4>
                                    <p>{ element.content }</p>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        )
    }
}

export default connector(LoadingQuotes);