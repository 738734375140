import { JourneyCompanyType } from "../interfaces/CompanyType";
import { ContactSalesRequestTypes } from "../interfaces/HalfHour";
import { AppLayoutData } from "../interfaces/Journey";
import {
  getGas,
  getElectricity,
  JourneyUtilityType,
  Journey,
} from "../models/journey.model";

export type ShouldShowModalResult =
    | { show: boolean; type: ContactSalesRequestTypes }
    | { show: false; type: undefined };

export function ShouldShowModal(
    journey: Journey,
    context: AppLayoutData,
    errors: Array<string>
): ShouldShowModalResult {

  const showHHmodal = shouldShowHalfHourModal(errors);
  const showMeterNotFoundModal = shouldShowMeterNotFoundModal(errors);
  const showDomesticMeterModal = shouldShowDomesticMeterModal(errors);
  const showDeenergisedMeterModal = shouldShowDeenergisedMeterModal(errors);
  const showEacModal = ShouldShowEacModal(errors);
  const showUsageNotFoundModal = ShouldShowUsageNotFoundModal(errors);

  if (showHHmodal?.show) {
    return showHHmodal;
  }

  if (showEacModal?.show) {
    return showEacModal;
  }

  if(showMeterNotFoundModal?.show){
    return showMeterNotFoundModal;
  }

  if(showDomesticMeterModal?.show){
    return showDomesticMeterModal;
  }

  if(showDeenergisedMeterModal?.show){
    return showDeenergisedMeterModal;
  }

  if (showUsageNotFoundModal?.show) {
    return showUsageNotFoundModal;
  }

  return ShouldShowCreditScoreModal(errors);
}

export function ShouldShowUsageNotFoundModal(
  errors: Array<string>
): ShouldShowModalResult {
if (errors.includes('usage_not_found')) {
  return {
    show: true,
    type: 'usage_not_found',
  };
}
return {
  show: false,
  type: undefined,
};
}

export function ShouldShowEacModal(
    errors: Array<string>
): ShouldShowModalResult {
  if (errors.includes('eac_limit')) {
    return {
      show: true,
      type: 'eac_limit',
    };
  }
  return {
    show: false,
    type: undefined,
  };
}

export function shouldShowDeenergisedMeterModal(
    errors: Array<string>
): ShouldShowModalResult {
  if (errors.includes('deenergised_meter')) {
    return {
      show: true,
      type: 'deenergised_meter',
    };
  }
  return {
    show: false,
    type: undefined,
  };
}

export function shouldShowDomesticMeterModal(
    errors: Array<string>
): ShouldShowModalResult {
  if (errors.includes('domestic_meter')) {
    return {
      show: true,
      type: 'domestic_meter',
    };
  }
  return {
    show: false,
    type: undefined,
  };
}

export function shouldShowMeterNotFoundModal(
    errors: Array<string>
): ShouldShowModalResult {
  if (errors.includes('meter_not_found')) {
    return {
      show: true,
      type: 'meter_not_found',
    };
  }
  return {
    show: false,
    type: undefined,
  };
}

export function shouldShowHalfHourModal(
    errors: Array<string>
): ShouldShowModalResult {
  if (errors.includes('half_hour_meter')) {
    return {
      show: true,
      type: 'half_hour_meter',
    };
  }
  return {
    show: false,
    type: undefined,
  };
}

export function ShouldShowCreditScoreModal(
    errors: Array<string>
): ShouldShowModalResult {
  if (errors.includes('credit_score_threshold')) {
    return {
      show: true,
      type: 'credit_score_threshold',
    };
  }
  return {
    show: false,
    type: undefined,
  };
}
