// Lib
import React from "react";

// TS Definitions
import { ProgressBarProps, ProgressBarState } from "../interfaces/ProgressBar";

class ProgressBar extends React.Component<ProgressBarProps, ProgressBarState> {
    state: ProgressBarState = {
        progress: 0,
        styles : {
            width : `${this.props.initialValue || 0}%`,
            transition : "all .2s linear"
        }
    }

    public updateProgress(progress: number, speed: string = '.2s'){
        this.setState({
            progress : progress,
            styles : {
                width : `${progress}%`,
                transition : `all ${speed} linear`
            }
        })
    }

    public isComplete = () => {
        if(this.state.progress === 100 && typeof this.props.onComplete !== 'undefined'){
            this.props.onComplete();
        }
    }

    public render(): JSX.Element {
        return (
            <div className={"progress-bar"}>
                <span style={this.state.styles} onTransitionEnd={this.isComplete}/>
            </div>
        );
    }
}

export default ProgressBar;