// Lib
import React from "react";

// TS Interfaces
import { AlertProps } from "../../interfaces/Alert";

class Alert extends React.Component<AlertProps>{
    private getClassName = () => {
        return `alert alert--${this.props.type}`;
    }

    public render = () => {
        return (
            <div className={this.getClassName()}>
                {this.props.message}
            </div>
        )
    }
}

export default Alert;