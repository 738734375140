// Lib
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';

// Components
import QuoteDetails from './QuoteDetails';
import QuotePrice from './QuotePrice';
import SupplierFeatures from './SupplierFeatures';

// Icons
import IconClose from '../icons/IconClose';
import IconGreenEnergy from '../icons/IconGreenEnergy';

//TS Definitions
import { QuoteItemProps, QuoteItemState } from '../../interfaces/Quotes';
import { AppLayoutData } from '../../interfaces/Journey';

// Providers
import { AppData } from '../../providers/AppData';
import { FeatureToggle } from '../misc/FeatureToggle';

const Button = styled.button`
  &[title] {
    position: relative;
    border: 5px solid var(-accent);

  }
  &[title]:hover:before {
    opacity: 100;
    background: #fff;
    border: 2px solid var(--accent);
    height: 7.5rem;
    top: 100%;
    right: -34px;
    border-radius: 5px;
    color: var(--button-primary--hover__text);
    content: attr(title);
    padding: 5px;
    position: absolute;
    z-index: 98;
    width: 180px;
    margin-top: 14px;
    font-size: 1.6rem;
  }
  &[title]:hover:after {
    width: 1rem;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 10px solid var(--accent);
    content: ' ';
    position: absolute;
    top: 110%;
    left: 49%;
  }
`;

class QuoteItem extends React.Component<QuoteItemProps, QuoteItemState> {
  static contextType: React.Context<AppLayoutData> = AppData;

  state: QuoteItemState = {
    modalShown: false,
    showCommisionData: false,
  };

  public selectQuote = (ev: React.MouseEvent<HTMLButtonElement>): void => {
    if (this.props.onSelected) {
      this.props.onSelected(this.props.quote, this.props.quote.fuelType);
    }
  };

  public clearQuote = (ev: React.MouseEvent<HTMLButtonElement>): void => {
    if (this.props.onClearSelection) {
      this.props.onClearSelection(this.props.quote.fuelType);
    }
  };

  public getTooltipContent = (): JSX.Element => {
    return (
      this.props?.quote?.quoteInformation && (
        <>{this.props.quote.quoteInformation.description}</>
      )
    );
  };

  public calculateTotalEstCommission = (): JSX.Element => {
    const RoundUpCommision = (
      commissionAmount: number,
      termLengthInMonths: number
    ) => {
      return (
        Math.round(
          ((commissionAmount * termLengthInMonths) / 12) * 100 + Number.EPSILON
        ) / 100
      ).toFixed(2);
    };

    return (
      <>
        <p className="heading-4">
          {this.context.labels.annual_commission_text} = £
          {RoundUpCommision(this.props?.quote?.commission, 12)}
        </p>
        <p className="heading-4">
          {this.context.labels.total_commission_text} = £
          {RoundUpCommision(
            this.props?.quote?.commission,
            this.props?.quote?.termInMonths
          )}
        </p>
      </>
    );
  };

  public render = (): JSX.Element => {
    const tooltipContent: JSX.Element = this.getTooltipContent();

    return (
      <div className={'quote'}>
        {this.props.quote.isGreen && (
          <div className={'green-energy quote__green-energy'}>
            <IconGreenEnergy />
          </div>
        )}
        <div className={'quote__company'}>
          <div className={'quote__image'}>
            <img
              src={this.props.supplier.logo}
              alt={this.props.quote.supplier}
            />
          </div>
        </div>
        <div className={'quote__cost'}>
          <QuotePrice price={this.props.quote.estimatedAnnualCost} />
        </div>
        <div className={'quote__details'}>
          <QuoteDetails listType={'default'} quote={this.props.quote}>
            <div>
              <dt>Features:</dt>
              <dd>
                <button
                  type={'button'}
                  className={'text-button'}
                  onClick={() => this.setState({ modalShown: true })}
                >
                  Learn More
                </button>
              </dd>
            </div>
            <FeatureToggle
              name={'show_annual_commission_message'}
              fallback={false}
              render={
                <div>
                  <dt>Commission:</dt>
                  <dd>
                    <button
                      type={'button'}
                      className={'text-button'}
                      onClick={() =>
                        this.setState({
                          modalShown: true,
                          showCommisionData: true,
                        })
                      }
                    >
                      Breakdown
                    </button>
                  </dd>
                </div>
              }
              otherwiseRender={<></>}
            />
          </QuoteDetails>
        </div>
        <div className={'quote__actions'}>
          {this.props.selected ? (
            <button
              type={'button'}
              className={'button'}
              onClick={this.clearQuote}
            >
              {this.context.labels.quotes_page_change_button_text ?? 'Change'}
            </button>
          ) : (
            <Button
              type={'button'}
              className={'button'}
              onClick={this.selectQuote}
              data-testid={'journeySelectQuote-btn'}
              title={
                this.context.toggles.use_octopus_journey &&
                !(this.props.quote.supplier === 'dyce')
                  ? 'By selecting this quote a new tab will open to a different website'
                  : undefined
              }
              style={
                this.context.toggles.use_octopus_journey &&
                !(this.props.quote.supplier === 'dyce')
                  ? {
                      backgroundColor: 'lightgray',
                      minWidth: '115px',
                    }
                  : {
                      backgroundColor: 'var(--button-primary)',
                      minWidth: '115px',
                    }
              }
            >
              {this.context.toggles.use_octopus_journey
                ? this.props.quote.supplier === 'dyce'
                  ? 'Proceed'
                  : 'Select'
                : this.context.labels.quotes_page_select_button_text ??
                  'Select'}
            </Button>
          )}
        </div>
        <Modal
          show={this.state.modalShown}
          onHide={() =>
            this.setState({ modalShown: false, showCommisionData: false })
          }
          dialogClassName="feature-modal"
          centered
        >
          <Modal.Body>
            <button
              type={'button'}
              className={'icon-button'}
              onClick={() =>
                this.setState({ modalShown: false, showCommisionData: false })
              }
            >
              <IconClose />
              <span>Close Modal</span>
            </button>
            <div className={'quote-overview'}>
              {this.state.showCommisionData ? (
                <>
                  <div className={'quote-overview__description'}>
                    <p>{this.context.labels.annual_commission_message}</p>
                    {this.calculateTotalEstCommission()}
                  </div>
                </>
              ) : (
                <>
                  <div className={'quote-overview__description'}>
                    {tooltipContent}
                  </div>
                  {typeof this.props.quote.quoteInformation !== 'undefined' ? (
                    <SupplierFeatures
                      features={this.props.quote.quoteInformation.features}
                    />
                  ) : (
                    <></>
                  )}
                  {this.props.quote.quoteInformation.learnMore && (
                    <div className={'quote-overview__click-here'}>
                      <a
                        href={this.props.quote.quoteInformation.learnMore.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {this.props.quote.quoteInformation.learnMore.text}
                      </a>
                    </div>
                  )}
                </>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  };
}

export default QuoteItem;
