// Lib
import React from "react";

// TS Definitions
import {QuotePriceProps} from "../../interfaces/Quotes";

class QuotePrice extends React.Component<QuotePriceProps>{
    public render = () => {
        return (
            <div className={"cost"}>
                <h2 className={"cost__amount"}>
                    {
                        new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' })
                                .format(this.props.price / 12)
                    }
                </h2>
                <p className={"cost__timing"}>Monthly Cost</p>
                <span className={"cost__smallprint"}>(Estimated)</span>
            </div>
        );
    }
}

export default QuotePrice;