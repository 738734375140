import { ConsumptionPeriods } from '../models/consumption-periods';
import { Consumption } from '../models/consumption.model';

export class ConsumptionService {
    readonly ELECTRICITY_KWH_RATE = 35;
    readonly ELECTRICITY_DAILY_RATE = 45.00;
    readonly ELECTRICITY_MINIMUM_EAC = 900;

    readonly ELECTRICITY_LOW_SPEND = 45;
    readonly ELECTRICITY_LOW_CONSUMPTION = 228;
    readonly ELECTRICITY_LOW_DAILY_RATE = 90;
    readonly ELECTRICITY_LOW_KWH_RATE = 16.5;

    readonly GAS_KWH_RATE = 12;
    readonly GAS_DAILY_RATE = 45;
    readonly GAS_MINIMUM_EAC = 2400

    getGasEac(
        consumption: Consumption,
        gas_daily_rate_from_prismic: number,
        gas_kwh_rate_from_prismic: number,
        gas_minimum_eac_from_prismic: number
      ): string {
        const gas_daily_rate = gas_daily_rate_from_prismic || this.GAS_DAILY_RATE;
        const gas_kwh_rate = gas_kwh_rate_from_prismic || this.GAS_KWH_RATE;
        const gas_minimum_eac = gas_minimum_eac_from_prismic || this.GAS_MINIMUM_EAC;

        return Math.max(
          this.getEac(consumption, gas_daily_rate, gas_kwh_rate),
          gas_minimum_eac
        ).toFixed();
      }

    getElectricityEac(
        consumption: Consumption,
        elec_kwh_rate_from_prismic: number,
        elec_daily_rate_from_prismic: number,
        elec_minimum_eac_from_prismic: number,
        elec_low_spend_from_prismic: number,
        elec_low_consumption_from_prismic: number,
        elec_low_daily_rate_from_prismic: number,
        elec_low_kwh_rate_from_prismic: number
         ): string {
        const elec_kwh_rate = elec_kwh_rate_from_prismic || this.ELECTRICITY_KWH_RATE;
        const elec_daily_rate = elec_daily_rate_from_prismic || this.ELECTRICITY_DAILY_RATE;
        const elec_minimum_eac = elec_minimum_eac_from_prismic || this.ELECTRICITY_MINIMUM_EAC;
        const elec_low_spend = elec_low_spend_from_prismic || this.ELECTRICITY_LOW_SPEND;
        const elec_low_consumption = elec_low_consumption_from_prismic || this.ELECTRICITY_LOW_CONSUMPTION;
        const elec_low_daily_rate = elec_low_daily_rate_from_prismic || this.ELECTRICITY_LOW_DAILY_RATE
        const elec_low_kwh_rate = elec_low_kwh_rate_from_prismic || this.ELECTRICITY_LOW_KWH_RATE;
        
        if (this.isLowEnergy(consumption, elec_low_spend)) {
            return this.getEac(
                consumption,
                elec_low_daily_rate,
                elec_low_kwh_rate
            ).toFixed();
        } else if (this.isLowSpend(consumption, elec_low_consumption)) {
            const calculatedEac = this.getEac(
                consumption,
                elec_low_daily_rate,
                elec_low_kwh_rate
            );
            return Math.max(calculatedEac, elec_minimum_eac).toFixed();
        } else {
            const calculatedEac = this.getEac(
                consumption,
                elec_daily_rate,
                elec_kwh_rate
            );
            return Math.max(calculatedEac, elec_minimum_eac).toFixed();
        }
    }

    private getEac(consumption: Consumption, dayRate: number, kwhRate: number): number {
        let eac: number = 0;

        if (consumption.energy) {
            if (consumption.period === ConsumptionPeriods.Monthly) {
                eac = +consumption.energy * 12;
            } else if (consumption.period === ConsumptionPeriods.Quarterly) {
                eac = +consumption.energy * 4;
            } else if (consumption.period === ConsumptionPeriods.BiAnnual) {
                eac = +consumption.energy * 6;
            } else {
                eac = +consumption.energy;
            }
        } else if (consumption.spend) {
            const spendInPence = +consumption.spend * 100;
            if (consumption.period === ConsumptionPeriods.Monthly) {
                eac = 12 * ((spendInPence - (30 * dayRate)) / kwhRate);
            } else if (consumption.period === ConsumptionPeriods.Quarterly) {
                eac = 4 * ((spendInPence - (30 * dayRate)) / kwhRate);
            } else {
                eac = (spendInPence - (365 * dayRate)) / kwhRate;
            }
        }
        // EAC must be non-negative
        return Math.max(eac, 0);
    }

    private isLowSpend(consumption: Consumption, electricity_low_spend: number): boolean {
        const monthlySpend = consumption.period === ConsumptionPeriods.Monthly ? +(consumption.spend ?? 0) : +(consumption.spend ?? 0) / 12;
        return !!consumption.spend && monthlySpend <= electricity_low_spend;
    }

    private isLowEnergy(consumption: Consumption, electricity_low_consumption: number): boolean {
        const monthlyEnergy = consumption.period === ConsumptionPeriods.Monthly ? +(consumption.energy ?? 0) : +(consumption.energy ?? 0) / 12;
        return !!consumption.energy  && monthlyEnergy < electricity_low_consumption;
    }
}
