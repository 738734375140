import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";
import API from "../../lib/API";

import { saveInitialJourneyToState } from "./saveJourneyToState";
import {getIpAddress, getAffilliate, getCookies, getPrismicId} from "./thunks";
import { environment } from "../../lib/environment";

type MyRootState = {};
type MyExtraArg = undefined;
type MyThunkDispatch = ThunkDispatch<MyRootState, MyExtraArg, Action>;

export const createJourney = () => {
  return (dispatch: MyThunkDispatch) => {
    return API.createJourney(environment.REACT_APP_STACK_NAME)
      .then((resp) => {
        return Promise.all([
          dispatch(saveInitialJourneyToState(resp)),
          dispatch(getIpAddress()),
          dispatch(getAffilliate()),
          dispatch(getCookies()),
          dispatch(getPrismicId(resp, environment.REACT_APP_PRISMIC_DOCUMENT_ID))
        ]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const setCookies = () => {
  return (dispatch: MyThunkDispatch) => {
    return Promise.all([
      dispatch(getAffilliate()),
      dispatch(getCookies())
    ]).catch((err) => {
      console.log(err);
    });
  };
};
