import { Field } from "formik";
import { useContext } from "react";
import { SwitchFormComponentProps } from "../../../interfaces/Form";
import { AppData } from "../../../providers/AppData";
import IconTick from "../../icons/IconTick";
import styled from "styled-components";
import { FieldWrapper } from "./FieldWrapper";
import SoleTraderAddressLookup from "./SoleTraderAddressLookup";

const Container = styled.div`
  > div > div > input {
    height: 50px;
  }
`;
export default function SoleTraderDetails(props: SwitchFormComponentProps) {
  const { labels } = useContext(AppData);

  const callFieldChangeFromInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    props.onFieldChange(event.target.getAttribute("name") ?? "", event.target.value);
  };

  const onFieldChange = (fieldName: string, value: unknown) => {
    props.onFieldChange(fieldName, value as any);
  };

  return (
    <>
      <div className={"form__intro"}>
        <h2>
          <IconTick wrapperClass={"icon"} />
          {"Personal details" ?? labels.contact_information_title}
        </h2>
        <p>{labels.contact_information_sub_title}</p>
      </div>
      <Container className={"grid"}>
        <div className={"col-sm-4"}>
          <FieldWrapper
            name="contactInformation.yearsActive"
            type="number"
            label={labels.contact_information_years_active}
            placeholder={labels.contact_information_years_active_placeholder ?? "Years active"}
            onChange={callFieldChangeFromInput}
          />
        </div>
        <div className={"col-sm-4"}>
          <FieldWrapper
            name="contactInformation.businessActivity"
            type="text"
            label={labels.contact_information_business_activity}
            placeholder={labels.contact_information_business_activity_placeholder ?? "Business activity"}
            onChange={callFieldChangeFromInput}
          />
        </div>
        <div className={"col-sm-4"}>
          <FieldWrapper
            name={"contactInformation.dateOfBirth"}
            type={"text"}
            label={labels.contact_information_dob}
            placeholder={labels.contact_information_dob_placeholder ?? "Date of birth"}
            onChange={callFieldChangeFromInput}
          />
        </div>
        <div className={"col-xs-4"}>
          <Field
            name={"contactInformation.personalAddress"}
            component={SoleTraderAddressLookup}
            onChange={onFieldChange}
            label={labels.contact_information_personal_address}
            placeholder={labels.contact_information_personal_address_placeholder ?? "Enter postcode"}
          />
        </div>
      </Container>
    </>
  );
}
