import { useContext, useReducer, useState } from "react";

import AwesomeDebouncePromise from "awesome-debounce-promise";
import { Field } from "formik";
import { FormatOptionLabelMeta } from "react-select";
import styled from "styled-components";

import API from "../../../lib/API";
import {
  Company,
  CompanyByNumber,
  SearchedCompany,
} from "../../../models/company.model";
import { AppData } from "../../../providers/AppData";
import Button from "../../library/Button";
import TextButton from "../../library/TextButton";
import CompanyPlate from "../CompanyPlate";
import LoadingPlate from "../LoadingPlate";
import { isNullOrWhitespace } from "../../../lib/string-extensions";
import { createFreeTypedSoleTrader, FreeTypedSoleTrader } from "./FreeTypedSoleTrader";
import LookupFormWithSelectList from "../../forms/LookupFormWithSelectList";

const FormContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.5fr 1fr 1fr;
  gap: 5% 5%;
  grid-template-areas:
    "."
    "."
    ".";

  > div {
    width: 100%;
    max-width: 38.5rem;

    > label {
      display: block;
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 1.5rem;
      color: var(--accent);
    }

    > input {
      height: fit-content;
    }
  }
`;

type CompanyOption = {
  value: string;
  label: string;
  company: SearchedCompany;
};

const debouncedGetSoleTraderByName = AwesomeDebouncePromise(
  API.getSoleTraderByName,
  500
);

const formOptionLabel = (
  { company }: CompanyOption,
  { context: formContext }: FormatOptionLabelMeta<CompanyOption, false>
) => {
  if (formContext === "value") {
    return <span>{company.name}</span>;
  }
  return (
    <>
      <span>{company.name}</span>
      <span className="post-code">{company.registeredAddress.postCode}</span>
    </>
  );
};

type States =
  | "idle"
  | "selectedSoleTrader"
  | "searchWithPostcode"
  | "freeTypeSoleTrader"
  | "loading";

type State = {
  status: States;
  inputValue: string;
  company: CompanyByNumber | FreeTypedSoleTrader | undefined;
  postcode: string | undefined;
  options: CompanyOption[];
  isLoadingOptions: boolean;
};

type Action =
  | {
      type: "updateInputValue";
      payload: string;
    }
  | {
      type: "setSoleTrader";
      payload: CompanyByNumber | FreeTypedSoleTrader;
    }
  | {
      type:
        | "resetSoleTrader"
        | "loading"
        | "cannotFindSoleTrader"
        | "searchingForOptions"
        | "freeTypeSoleTrader";
    }
  | {
      type: "cannotFindSoleTrader";
    }
  | {
      type: "updatePostcode";
      payload: string;
    }
  | {
      type: "updateOptions";
      payload: CompanyOption[];
    }
  | {
      type: "freeTypedSoleTrader";
      payload: FreeTypedSoleTrader;
    };

const initialState: State = {
  status: "searchWithPostcode",
  inputValue: "",
  company: undefined,
  postcode: undefined,
  options: [],
  isLoadingOptions: false,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "updateInputValue":
      return { ...state, inputValue: action.payload };
    case "updatePostcode":
      return { ...state, postcode: action.payload };
    case "updateOptions":
      return { ...state, options: action.payload, isLoadingOptions: false };
    case "setSoleTrader":
      return { ...state, status: "selectedSoleTrader", company: action.payload };
    case "resetSoleTrader":
      return initialState;
    case "loading":
      return { ...state, status: "loading" };
    case "cannotFindSoleTrader":
      return { ...state, status: "searchWithPostcode" };
    case "searchingForOptions":
      return { ...state, isLoadingOptions: true };
    case "freeTypeSoleTrader":
      return { ...state, status: "freeTypeSoleTrader" };
    case "freeTypedSoleTrader":
      return {
        ...state,
        status: "selectedSoleTrader",
        company: action.payload,
      };
    default:
      return state;
  }
};

type SoleTraderLookupProps = {
  onSelectedCompany: (company: Company | FreeTypedSoleTrader) => void;
  onDeselectedCompany: () => void;
};
export default function SoleTraderLookup({
  onSelectedCompany,
  onDeselectedCompany,
}: SoleTraderLookupProps) {
  const { labels } = useContext(AppData);
  const [state, dispatch] = useReducer(reducer, initialState);

  const [soleTraderName, setSoleTraderName] = useState<string>('')
  const [postcode, setPostcode] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isError, setIsError] = useState<string | undefined>(undefined)
  const [options, setOptions] = useState<any[]>([])

  const handleChange = async (
    option: CompanyOption | null,
  ) => {
    if (!option) {
      return;
    }

    try {
      dispatch({ type: "loading" });

      const companyDetails = await API.creditCheckNonRegistered(option.company.number);

      const company: CompanyByNumber = {
        ...option.company,
        creditScore: companyDetails.creditScore,
        isMicroBusiness: companyDetails.isMicroBusiness,
      } as CompanyByNumber;

      dispatch({ type: "setSoleTrader", payload: company });
      onSelectedCompany(company);
    } catch (e) {}
  };

  const handleFreeTypedSoleTrader = () => {
    const newFreeTypedSoleTrader = createFreeTypedSoleTrader(
      state.inputValue,
      state.postcode as string
    );

    onSelectedCompany(newFreeTypedSoleTrader);

    dispatch({
      type: 'freeTypedSoleTrader',
      payload: newFreeTypedSoleTrader,
    });
  };

  const handleSoleTraderNameChange = (e: any) => {
    setSoleTraderName(e.target.value);
  }

  const handlePostcodeChange = (e: any) => {
    setPostcode(e.target.value)
  }

  const handleSubmit = async () => {
    setIsLoading(true);
    setIsError(undefined);
    if (soleTraderName.length) {
      const response = await debouncedGetSoleTraderByName(
        soleTraderName,
        postcode
      );
      if (response.searchedCompanies.length === 0) {
        setIsError('No results found');
      }
      const result =  response.searchedCompanies.map((company) => {
        return {
          value: company.name,
          label: company.name,
          company,
        };
      });
      setOptions(result);
    } else {
      setIsError('Please enter a value');
    }
    setIsLoading(false);
  };

  const header = (
    <label htmlFor="company-lookup" className={"form__label"}>
      {labels.company_name_title}
    </label>
  );

  if (state.status === 'searchWithPostcode') {
    return (
      <>
        <FormContainer>
          <div>
            <label htmlFor={'postcode'} className={'form__label'}>
              {labels.sole_trader_postcode_heading ??
                'Try entering your postcode.'}{' '}
              <TextButton
                type={'button'}
                onClick={() => dispatch({ type: 'freeTypeSoleTrader' })}
              >
                {labels.sole_trader_still_cannot_find_sole_trader_button ??
                  'Still cannot find what you are looking for?'}
              </TextButton>
            </label>
            <Field
              type="text"
              name="postcode"
              className={'form__control form__control--width'}
              value={postcode}
              placeholder={
                labels.sole_trader_placeholder_postcode ?? 'Postcode'
              }
              onChange={handlePostcodeChange}
            />
          </div>
          <div>
            {header}
            <LookupFormWithSelectList
              value={soleTraderName}
              onChange={handleSoleTraderNameChange}
              placeholder={labels.company_name_placeholder_sole_trader}
              onClick={handleSubmit}
              isLoading={isLoading}
              defaultOptions={options}
              isError={isError}
              selectListOnChange={handleChange}
              selectPlaceholder={'Please select your sole trader'}
            />
          </div>
        </FormContainer>
      </>
    );
  }

  if (state.status === "freeTypeSoleTrader") {
    return (
      <>
        {header}
        <FormContainer>
          <div>
            <label htmlFor={"inputValue"}>
              {labels.sole_trader_freetype_name_heading ??
                "Enter your sole trader name"}
            </label>
            <Field
              type="text"
              name="inputValue"
              className={"form__control form__control--width"}
              value={state.inputValue}
              placeholder={
                labels.sole_trader_freetype_name_placeholder ?? "Sole Trader Name"
              }
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                dispatch({ type: "updateInputValue", payload: e.currentTarget.value });
              }}
            />
          </div>
          <div>
            <label htmlFor={"postcode"}>
              {labels.sole_trader_freetype_postcode_heading ?? "Enter your postcode"}
            </label>
            <Field
              type="text"
              name="postcode"
              className={"form__control form__control--width"}
              value={state.postcode}
              placeholder={
                labels.sole_trader_freetype_postcode_placeholder ?? "Postcode"
              }
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                dispatch({ type: "updatePostcode", payload: e.currentTarget.value });
              }}
            />
          </div>
          <div>
            <p>
              {labels.sole_trader_freetype_confirmation_text ??
                "I believe my sole trader details are correct and wish to proceed under the basis I pass credit."}
            </p>
            <Button
              disabled={
                isNullOrWhitespace(state.inputValue) ||
                isNullOrWhitespace(state.postcode)
              }
              onClick={() => handleFreeTypedSoleTrader()}
            >
              {labels.sole_trader_freetype_confirmation_button ?? "Confirm"}
            </Button>
          </div>
        </FormContainer>
      </>
    );
  }

  if (state.status === "selectedSoleTrader") {
    return (
      <>
        {header}
        <CompanyPlate
          {...(state.company as Company)}
          onChangeCompany={() => {
            dispatch({ type: "resetSoleTrader" })
            onDeselectedCompany();
          }}
        />
      </>
    );
  }

  if (state.status === "loading") {
    return (
      <>
        {header}
        <LoadingPlate />
      </>
    );
  }

  return <>{header}</>;
}
