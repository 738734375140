import React, { useContext } from "react";
import { AppData } from "../../../providers/AppData";
import { RichText } from "prismic-reactjs";
import { CookieConsentImplementationProps } from "./CookieConsentImplementationProps";

export const CornerCookieConsent: React.FunctionComponent<CookieConsentImplementationProps> =
  ({ onClose }) => {
    const context = useContext(AppData);

    return (
      <div id="cookie-consent" className={"cookiealert show"}>
        {RichText.render(context.labels.cookie_consent_text)}
        <div className={"buttons"}>
          <button
            id="accept-cookies"
            className={"button button-theme"}
            onClick={() => onClose(true)}
          >
            Accept cookies
          </button>
          <button
            id="reject-cookies"
            className={"button button-theme"}
            onClick={() => onClose(false)}
          >
            Decline cookies
          </button>
        </div>
      </div>
    );
  };
