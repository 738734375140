import React, { useState, useContext, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Journey } from "../models/journey.model";
import { AppData } from "../providers/AppData";
import SaveQuoteForm from "./forms/SaveQuoteForm";
import IconClose from "./icons/IconClose";

import API from "../lib/API";
import { environment } from "../lib/environment";
import { RichText } from "prismic-reactjs";
import { TypeOfSave } from "../models/typeOfSave";

export const SaveQuoteModal: React.FunctionComponent<{
  journey: Journey;
  typeOfSave: TypeOfSave;
  isSaveQuotesModalOpen: boolean;
  isSaveQuotesModalEmail: boolean;
  onClose: () => void;
}> = ({ journey, typeOfSave, isSaveQuotesModalOpen, isSaveQuotesModalEmail, onClose }) => {
  const [isModalOpen, setIsSaveQuotesModalOpen] = useState(false);
  const [quotesSaved, setQuotesSaved] = useState({
    saved: false,
    savedQuotesId: journey.saveQuote.identifier,
  });
  const context = useContext(AppData);

  const handleSubmit = async ({ uid, contactInformation }: Journey): Promise<string> => {
      if (quotesSaved.savedQuotesId) {
        await API.saveQuoteEmail({ uid, contactInformation, typeOfSave });
        return quotesSaved.savedQuotesId;
      } else {
        return API.saveQuotes({ uid, contactInformation });
      }
  }

  useEffect(() => {
    setIsSaveQuotesModalOpen(isSaveQuotesModalOpen);
  }, [isSaveQuotesModalOpen]);

  useEffect(() => {
    if(isSaveQuotesModalEmail) {
      setIsSaveQuotesModalOpen(false);
      handleSubmit(journey);
    }
  },[])
  
  const makeUrl = () => {
    const basename =
      environment.REACT_APP_BASE_NAME === "/" ? "" : environment.REACT_APP_BASE_NAME;
    const location = `${window.location.origin}${basename}`;
    const origin = environment.REACT_APP_IS_SALES_AGENT_PORTAL
      ? environment.REACT_APP_CUSTOMER_PORTAL_URL
      : location;
    const urlPath =
      typeOfSave === "save-quotes" ? "saved-journey" : "sales-saved-journey";
    return `${origin}/${urlPath}`;
  };
  const url = makeUrl();

  return (
    <Modal
      show={isModalOpen}
      onHide={() => {
        onClose();
        setIsSaveQuotesModalOpen(false);
      }}
      dialogClassName="feature-modal"
      centered
    >
      <Modal.Body>
        <button
          type={"button"}
          className={"icon-button"}
          onClick={() => {
            onClose();
            setIsSaveQuotesModalOpen(false);
          }}
        >
          <IconClose />
          <span>Close Modal</span>
        </button>
        {!quotesSaved.saved ? (
          <>
            <h2>{context.labels.save_quotes_modal_title}</h2>
            {context.labels.save_quotes_modal_content_rtf &&
              context.labels.save_quotes_modal_content_rtf.length > 0 ? (
                RichText.render(context.labels.save_quotes_modal_content_rtf)
              ) : (
                <p>{context.labels.save_quotes_modal_content}</p>
              )}
            <SaveQuoteForm
              onSubmitted={handleSubmit}
              onCompleted={(id) => setQuotesSaved({ saved: true, savedQuotesId: id })}
              journey={journey}
            />
          </>
        ) : (
            <>
              <h2>{context.labels.save_quotes_modal_complete_title}</h2>
              <p>{context.labels.save_quotes_modal_complete_content}</p>
              <a href={`${url}?id=${quotesSaved.savedQuotesId}`}>
                {`${url}?id=${quotesSaved.savedQuotesId}`}
              </a>
            </>
          )}
      </Modal.Body>
    </Modal>
  );
};
