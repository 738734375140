// Lib
import React from "react";
import { format, parseJSON } from "date-fns";

// TS Definitions
import { FuelType } from "../../models/utility-detail";
import { Rate } from "../../models/quote.model";
import { SupplyOverviewProps } from "../../interfaces/Supplier";
import { hidePartialMpxnString } from "../../lib/string-replacement";

class SupplyOverview extends React.Component<SupplyOverviewProps> {

    public getMPAN = (): string => {
        if (this.props.mpxn) {
            return hidePartialMpxnString(this.props.mpxn);
        }

        return 'TBC';
    }

    public render = (): JSX.Element => {
        return (
            <dl className={"details details--inline"}>
                <div className={"force-block"}>
                    <dt>Current Term:</dt>
                    <dd>{this.props.quoteDetails.quote.tariff}</dd>
                </div>
                <div>
                    <dt>Supplier:</dt>
                    <dd>
                        {this.props.supplier.name}
                    </dd>
                </div>
                {this.props.quoteDetails.quote.rates.map((rate: Rate) => {
                    return (
                        <div key={rate.name}>
                            <dt>{rate.name}:</dt>
                            <dd>{rate.value}p/{rate.unit}</dd>
                        </div>
                    );
                })}
                <div>
                    <dt>Current Term:</dt>
                    <dd>
                        {this.props.quoteDetails.quote.termInMonths} Months
                    </dd>
                </div>
                <div>
                    <dt>Consumption:</dt>
                    <dd>
                        {`${this.props.quoteDetails.supplyConsumption.eac}kWh`}
                    </dd>
                </div>
                <div>
                    <dt>{this.props.quoteDetails.quoteType === FuelType.Electricity ? "MPAN" : "MPRN"}:</dt>
                    <dd>
                        {
                            this.getMPAN()
                        }
                    </dd>
                </div>
                <div>
                    <dt>Starting:</dt>
                    {
                        this.props.quoteDetails.quotePeriod.start !== null
                            ? <dd>{format(parseJSON(this.props.quoteDetails.quotePeriod.start) as Date, "dd/MM/yyyy")}</dd>
                            : <dd>N/A</dd>
                    }
                </div>
            </dl>
        );
    }
}

export default SupplyOverview;