import React from "react";

class AppError extends React.Component {
    public render = (): JSX.Element => {
        return (
            <main>
                <section>
                    <h2>App Config failed to load</h2>
                </section>
            </main>
        )
    }
}

export default AppError;