import React from "react";

import IconProps from "../../interfaces/Icon";

const IconClose = (props: IconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" className={ props.wrapperClass || '' }>
            <g>
                <line x1="4.5" y1="4.5" x2="17.5" y2="17.5" fill="none" strokeMiterlimit="10" strokeWidth="2"/>
                <line x1="17.5" y1="4.5" x2="4.5" y2="17.5" fill="none" strokeMiterlimit="10" strokeWidth="2"/>
            </g>
        </svg>
    );
};

export default IconClose;