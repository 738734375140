// Lib
import React from "react";

// TS Interfaces
import { AccordionItemProps } from "../../interfaces/Accordion";

class AccordionItem extends React.Component<AccordionItemProps>{
    public render = () => {
        return (
            <>
                {this.props.children}
            </>
        )
    }
}

export default AccordionItem;