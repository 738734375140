import { JourneyUtilityType } from "./journey.model";
import { FuelType } from "./utility-detail";

export interface AddressComponentState{
    inputValue: string;
    addressSelected: boolean;
    results : any;
    selectedAddress: Address;
    journeySaved : boolean;
    type: AddressSearchType;
    defaultOptions: Array<AddressDetail>;
    isSmartMeter: boolean;
    inputData: any;
    isError: string | undefined;
    isLoading: boolean;
}

export interface DualAddressComponentState {
    inputValue: string;
    elecAddressSelected: boolean;
    gasAddressSelected: boolean;
    results : any;
    selectedElecAddress: Address;
    selectedGasAddress: Address;
    journeySaved : boolean;
    elecType: AddressSearchType;
    gasType: AddressSearchType;
    defaultOptions: Array<AddressDetail>;
    isSmartMeter: boolean;
    inputData: Array<InputDetail>
}

export interface InputDetail {
    fuelType: JourneyUtilityType;
    show: boolean;
}

export interface SearchAddresses{
    searchedCompanies: Address[]
}

export interface AddressDetail {
    label?: string;
    value?: string;
    showMPAN?: boolean;
    address: Address;
}


export interface Address {
    udprn: string;
    id: string;
    additionalInformation: string;
    addressId: string;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    town: string;
    county: string;
    postCode: string;
    value ?: string;
    label ?: string;
    mpxn : string | "";
    meterType : FuelType;
}

export interface AddressPlateFunctions {
    onChangeAddress : (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export enum AddressSearchType {
    Postcode,
    MPAN
}