import * as Yup from 'yup';
import { testValidPhone } from "../lib/testValidPhone";

export const saveQuotesSchema = Yup.object().shape({
   contactInformation : Yup.object().shape({
        email: Yup.string().required("Required").email("Please enter a valid email"),
        phoneNumber: Yup.string().optional().test(
            'phone-number',
            "Please enter a valid UK Phone number",
            testValidPhone
        )
    })
});

export interface saveQuotesInterface extends Yup.Asserts<typeof saveQuotesSchema> {}