import { useState, useEffect, useContext } from "react";
import { AppData } from "../../../providers/AppData";
import { ContactSalesModal } from "../../forms/ContactSalesModal";
import styled from "styled-components";
import { Journey } from "../../../models/journey.model";

const ElementWrapper = styled.div`
  display: block;
  margin-bottom: 1rem;
  margin-top: 2rem;
`;

const ButtonsWrapper = styled.div`
  display: inline-block;
`;

const Button = styled.button`
  position: relative;
  background-color: var(--button-primary);
  color: var(--button-primary__text);
  appearance: none;
  box-shadow: none;
  border-radius: 0.4rem;
  line-height: 2.1rem;
  font-family: var(--font-family);
  font-size: 1.5rem;
  border: none;
  padding: 0.5rem 0.5rem;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  min-width: 4rem;
  margin-left: 0.5rem;
`;

const NewConnection = ({
  showNewConnectionComponent,
  journey,
}: {
  showNewConnectionComponent: boolean;
  journey: Journey;
}) => {
  const [showModal, setShowModal] = useState(false);
  const [shouldShowComponent, setShouldShowComponent] = useState(true);

  useEffect(() => {
    setShouldShowComponent(showNewConnectionComponent);
  }, [showNewConnectionComponent]);

  const { labels } = useContext(AppData);

  return (
    <>
      {shouldShowComponent && (
        <ElementWrapper>
          <span>{labels.new_meter_text_content}</span>
          <ButtonsWrapper>
            <Button
              type="button"
              onClick={() => setShowModal(true)}
            >
              Yes
            </Button>
            <Button type="button" onClick={() => setShouldShowComponent(false)}>
              No
            </Button>
          </ButtonsWrapper>
        </ElementWrapper>
      )}
      <ContactSalesModal
        type={"new_meter"}
        journey={journey}
        show={showModal}
        onClose={() => setShowModal(false)}
      />
    </>
  );
};

export default NewConnection;
