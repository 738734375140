import { environment } from "../../lib/environment";

const TrustpilotWidget = () => {
  const templateId = environment.REACT_APP_TRUSTPILOT_TEMPLATE_ID;
  const businessunitId = environment.REACT_APP_TRUSTPILOT_BUSINESSUNIT_ID;
  const trustpilotUrl = environment.REACT_APP_TRUSTPILOT_URL;

  return (
    <>
    <div
      className="trustpilot-widget"
      data-locale="en-GB"
      data-template-id={templateId}
      data-businessunit-id={businessunitId}
      data-style-height="24px"
      data-style-width="100%"
      data-theme="light"
      data-min-review-count="10"
    >
      <a
        href={trustpilotUrl}
        target="_blank" 
        rel="noreferrer"
      >
        Trustpilot
      </a>
    </div>
    </>
  );
};

export default TrustpilotWidget;
