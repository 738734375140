import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 2rem;

  .radio-button {
    width: calc(33% - 1rem);
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;

    .radio-button {
      width: 100%;

      + .radio-button {
        margin-top: 2rem;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .radio-button + .radio-button {
      margin-top: 1.5rem;
    }
  }
`;

interface Props {
  active: boolean;
}

export const Button = styled.button<Props>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem 2rem 3rem;
  align-items: center;
  border: 0.2rem solid var(--radio-button--border-colour);
  background-color: var(--radio-button--background);
  border-radius: 0.6rem;
  transition: all 0.3s linear;
  width: 100%;
  height: 100%;
  color: var(--radio-button--font-colour);
  font-family: var(--font-family);
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 3rem;
  text-align: center;
  cursor: pointer;

  &:hover,
  &:focus {
    border: 0.3rem solid var(--radio-button--border-colour--active);
    background-color: var(--radio-button--background--active);
    color: var(--radio-button--font-colour--active);
  }

  ${({ active }) => (active === true) && css`    
    border: 0.3rem solid var(--radio-button--border-colour--active);
    background-color: var(--radio-button--background--active);
    color: var(--radio-button--font-colour--active);
  `}
`;