import React, { useEffect, useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { ContactSalesForm } from "./ContactSalesForm";
import { AppData } from "../../providers/AppData";
import { AppLayoutData } from "../../interfaces/Journey";
import { Journey } from "../../models/journey.model";
import { ContactSalesRequestTypes } from "../../interfaces/HalfHour";
import ContactSalesEnding from "../misc/ContactSalesEnding";
import IconClose from "../icons/IconClose";
import API from "../../lib/API";
import { analyticsEvent } from "../../stores/analytics/actions";
import styled from "styled-components";
import Button from "../../components/library/Button";
import { Link } from "react-router-dom";

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  button {
    width: fit-content;
    max-width: 300px;
  }
` ;

export type ContactSalesModalProps = {
  type: ContactSalesRequestTypes;
  journey: Journey;
  show: boolean;
  onClose: () => void;
};

const replaceUnderscore = (typeOfModal: string): string => {
  return typeOfModal.replace('_', '-')
}

function setModalContent(
  context: AppLayoutData,
  type: ContactSalesRequestTypes
) {
  if (type === "eac_limit") {
    return {
      title: context.labels.eac_limit_modal_title,
      content: context.labels.eac_limit_modal_content,
      buttonText: context.labels.eac_limit_modal_submit_button
    };
  }

  if (type === "half_hour_meter") {
    return {
      title: context.labels.half_hourly_modal_title,
      content: context.labels.half_hourly_modal_content,
      buttonText: "Send"
    };
  }

  if (type === "credit_score_threshold") {
    return {
      title: context.labels.credit_score_threshold_modal_title,
      content: context.labels.credit_score_threshold_modal_content,
      buttonText: "Send"
    };
  }

  if (type === "new_meter") {
    return {
      title: context.labels.new_meter_modal_title,
      content: context.labels.new_meter_modal_content,
      buttonText: "Send"
    };
  }

  if (type === "domestic_meter") {
    return {
      title: context.labels.domestic_meter_modal_title,
      content: context.labels.domestic_meter_modal_content,
      buttonText: "Send"
    };
  }

  if (type === "deenergised_meter") {
    return {
      title: context.labels.deenergised_meter_modal_title,
      content: context.labels.deenergised_meter_modal_content,
      buttonText: "Send"
    };
  }

  if (type === "usage_not_found") {
    return {
      title: context.labels.usage_not_found_modal_title,
      content: context.labels.usage_not_found_modal_content,
      buttonText: "Send"
    };
  }

  return {
    title: context.labels.contact_modal_generic_error_title,
    content: context.labels.contact_modal_generic_error_content,
    buttonText: "Send"
  };
}

export const ContactSalesModal: React.FunctionComponent<
  ContactSalesModalProps
> = ({ onClose, ...props }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(props.show);
  const [isModalFinished, setIsModalFinished] = useState(false);

  const handleShowAnalytics = (typeOfModal: string) => {
    dispatch(analyticsEvent(`${replaceUnderscore(typeOfModal)}-modal-shown`));
  };

  const handleSendAnalytics = (typeOfModal: string) => {
    dispatch(analyticsEvent(`${replaceUnderscore(typeOfModal)}-modal-submitted`));
  };

  const handleModalCompletion = () => {
    context.variables.contact_modal_ending_redirect_url
      ? (window.location.href =
        context.variables.contact_modal_ending_redirect_url)
      : setIsModalFinished(true);
  };

  const handleModalRedirect = () => {
    context.variables.contact_modal_redirect_url &&
        (window.location.href = context.variables.contact_modal_redirect_url);
  };

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  const handleClose = () => {
    setShow(false);
    onClose();
  };

  const context = useContext(AppData);
  const modalContent = setModalContent(context, props.type);
  return (
    <Modal
      onHide={undefined}
      show={show}
      dialogClassName="feature-modal"
      centered
      onShow={() => handleShowAnalytics(props.type)}
    >
      {!isModalFinished ? (
        <Modal.Body>
          <button
            type={"button"}
            className={"icon-button"}
            onClick={() => handleClose()}
          >
            <IconClose />
          </button>
          <h3>{modalContent.title}</h3>
          <p>{modalContent.content}</p>
          <br />
          {(context.toggles.show_modal_contact_sales_form
            && props.type !== 'usage_not_found') ? (
            <ContactSalesForm
              onSubmitted={(data) => {
                return API.contactSalesRep({
                  ...data,
                  type: props.type,
                });
              }}
              onCompleted={() => {
                handleSendAnalytics(props.type);
                handleModalCompletion();
              }}
              journey={props.journey}
              buttonText={modalContent.buttonText}
            />
          ) : (
            <ButtonWrapper>
              {props.type === 'usage_not_found' ? (
                <Link to={"/"}>{context.labels.contact_modal_link_text}</Link>
              ) : (
                <Button onClick={() => handleModalRedirect()}>
                  {context.labels.contact_modal_button}
                </Button>
              )}
            </ButtonWrapper>
          )}
        </Modal.Body>
      ) : (
        <ContactSalesEnding />
      )}
    </Modal>
  );
};
