import { useContext } from "react";
import styled from "styled-components"
import { useCountdown } from "../lib/hooks/useCountdown"
import { AppData } from "../providers/AppData";
import Button from '../components/library/Button';

const Counter = styled.span`
  display: flex;
  align-items: center;
  font-size: 3rem;
  color: var(--countdown-timer-clock);
  font-weight: bold;
`

const Span = styled.span`
  display: flex;
  align-items: center;

  @media screen and (max-width: 991px){
        display: none;
    }
`

const SpanBold = styled.span`
  display: flex;
  align-items: left;
  color: var(--countdown-timer-text);
  font-weight: bold;
  font-size: 2.3rem;

  @media screen and (max-width: 991px){
        display: none;
    }
`

const Div = styled.div`
  display: flex;
  flex: 2 0 0;
  flex-direction:column;
  align-items: flex-start;
  justify-content: center;

  @media screen and (max-width: 991px){
        display: none;
    }
`;

const CompletedWrapper = styled.div`
  flex-direction:column;
  align-items: flex-end;
  justify-content: center;
`;

const CounterWrapper = styled.div`
  display: flex;
  flex: auto;
  flex-direction:column;
  align-items: flex-start;
  justify-content: center;
  width: 10rem;
  padding-left: 1rem;

  @media screen and (max-width: 991px){
    align-items: flex-end;
    }
`;

const ButtonWrapper = styled.div`
  max-width: 25rem;
`;

type CountdownProps = {
  onReset: () => void;
};
export function Countdown(props: CountdownProps) {
  const { labels: {
    quotes_countdown_length,
    quotes_countdown_text,
    quotes_countdown_completed_text,
    quotes_countdown_button_text,
    quotes_countdown_text_lower,
  } } = useContext(AppData);
  const { minutes, seconds, state } = useCountdown(quotes_countdown_length); 
  if (state === 'countdown') {
    return (
    <>
      <Div>
        <Span>{quotes_countdown_text}</Span>
        <SpanBold>{quotes_countdown_text_lower}</SpanBold>
      </Div>
      <CounterWrapper>
        <Counter>{minutes}:{seconds}</Counter>
      </CounterWrapper>
    </>
    )
  }

  return (
    <CompletedWrapper>
      <Span>{quotes_countdown_completed_text}</Span>
      <ButtonWrapper>
        <Button onClick={() => props.onReset()}>{quotes_countdown_button_text}</Button>
      </ButtonWrapper>
    </CompletedWrapper>
  )
}