export function createQueryParamString(obj: Record<string, string | boolean | null | undefined>): string {
    const query = new URLSearchParams();

    Object.entries(obj)
        .filter(([_, value]) => typeof value === "boolean" || typeof value === "string")
        .forEach(([key, value]) => {
            if (typeof value === "boolean") {
                query.append(key, value.toString())
            } else if (typeof value === "string") {
                query.append(key, value)
            }
        });

    return query.toString();
}