import { FuelType } from "../../models/utility-detail"

export type MetadataState = {
    partialMpan: string | undefined,
    partialMprn: string | undefined
}

const initialState: MetadataState = {
    partialMpan: undefined,
    partialMprn: undefined
}

type MetadataActions = {
    type: 'STORE_MPXN',
    payload: { mpxn: string, fuelType: FuelType }
}

export const storeMpxn = (payload: { mpxn: string, fuelType: FuelType }): MetadataActions => ({
    type: 'STORE_MPXN',
    payload: payload
})

export default function MetadataReducer(
    state: MetadataState = initialState,
    { type, payload }: MetadataActions
): MetadataState {
    switch (type) {
        case 'STORE_MPXN': {
            if (payload.fuelType === FuelType.Electricity) {
                return { ...state, partialMpan: payload.mpxn };
            } else {
                return { ...state, partialMprn: payload.mpxn };
            }
        }
        default:
            return state;
    }
}