import React, { useEffect, useState, useContext } from "react";
import { environment } from "../../../lib/environment";
import { getLocalStorageItem, setLocalStorageItem } from "../../../lib/local-storage";
import { useDispatch } from "react-redux";
import { setCookies } from "../../../stores/actions/createJourney";
import { FeatureToggle } from "../FeatureToggle";
import { CornerCookieConsent } from "./CornerCookieConsent";
import { FullScreenCookieConsent } from "./FullScreenCookieConsent";
import { initializeAnalytics } from "../../../stores/analytics/actions";
import { AppData } from "../../../providers/AppData";

type CookieConsentProps = {};

export const CookieConsent: React.FunctionComponent<CookieConsentProps> = () => {
  const { toggles } = useContext(AppData);
  const dispatch = useDispatch();
  const acceptedCookies = getLocalStorageItem("acceptCookies") === "true";
  const [acceptCookies, setCookieConsent] = useState(acceptedCookies);
  const [showForm, setShowForm] = useState(!acceptedCookies);

  useEffect(() => {
    if (toggles.disable_cookie_pop_up) {
      setCookieConsent(true)
      setLocalStorageItem('acceptCookies', acceptCookies ? "true" : "false");
      dispatch(setCookies());
    }
    
  }, []);

  useEffect(() => {
    const id = environment.REACT_APP_ANALYTICS_ID;
    const gtmId = environment.REACT_APP_GTM_ID;

    if (acceptCookies) {
      dispatch(initializeAnalytics({
        googleAnalyticsId: id,
        googleTagMangerId: gtmId
      }));
    }

    setLocalStorageItem("acceptCookies", acceptCookies ? "true" : "false");
    dispatch(setCookies());
  }, [acceptCookies, dispatch]);

  const handleOnClose = (accepted: boolean) => {
    setShowForm(false);
    setCookieConsent(accepted);
  };

  return (
    <>
      {showForm && !toggles.disable_cookie_pop_up && (
        <FeatureToggle
          name={"use_fullscreen_cookie_pop_up"}
          fallback={false}
          render={<FullScreenCookieConsent onClose={handleOnClose} />}
          otherwiseRender={<CornerCookieConsent onClose={handleOnClose} />}
        />
      )}
    </>
  );
};
