// Lib
import React, { useContext, useEffect } from "react";
import { Switch, Route, Redirect, RouteComponentProps } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";

// Components
import JourneyStep from "./pages/JourneyPage";
import QuotesPage from "./pages/QuotesPage";
import LoadingJourney from "./pages/LoadingJourney";
import CompletedPage from "./pages/CompletedPage";
import SwitchForm from "./pages/SwitchPage";

// TS Definitions
import { JourneyPageLayout } from "./interfaces/Journey";

// Store Actions
import { createJourney } from "./stores/actions/createJourney";
import { getSuppliers } from "./stores/actions/getSuppliers";

import { AppState } from "./stores/store";
import { AppStages } from "./models/journey.model";
import { FormInput } from "./interfaces/Form";

import { AppData } from "./providers/AppData";

import { getAppStageByComponentType } from "./lib/getAppStageByComponentType";
import { Footer } from "./components/footer/Footer";
import Header from "./components/header/Header";
import { ErrorPage } from "./pages/ErrorPage";
import { environment } from "./lib/environment";

// import "./sass/app.scss";

//Connect to store bootstrap
const mapStateToProps = (state: AppState) => state;
const connector = connect(mapStateToProps, { createJourney, getSuppliers });

const App: React.FunctionComponent<ConnectedProps<typeof connector>> = (props) => {
  const context = useContext(AppData);

  useEffect(() => {
    if (!props.suppliers.length) {
      props.getSuppliers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasCompletedPreviousPage = (page: JourneyPageLayout): boolean => {
    const previousStages = page.components.map((component: FormInput) => {
      return getAppStageByComponentType(component.type);
    });

    return previousStages.every((i: AppStages) => props.completedStages.includes(i));
  };

  const rootRoute =
    typeof context.pages[0] !== "undefined"
      ? `/journey/${context.pages[0].route}`
      : "/";

  const home = (
    <Route
      exact
      path="/"
      render={({ location }) => (
        <Redirect
          to={{
            pathname: rootRoute,
            search: location.search,
          }}
        />
      )}
    />
  );

  const pages = context.pages.map(
    (page: JourneyPageLayout, idx: number): JSX.Element => {
      return (
        <Route
          key={`step-${idx}`}
          path={`/journey/${page.route}`}
          render={() => {
            if (idx > 0) {
              const hasCompPrevStep = hasCompletedPreviousPage(context.pages[idx - 1]);
              if (!hasCompPrevStep) {
                return <Redirect to={rootRoute} />;
              }
            }

            return <JourneyStep {...page} />;
          }}
        />
      );
    }
  );

  const results = (
    <Route
      path="/results"
      render={() => {
        if (props.completedStages.indexOf(AppStages.QuotesPage) === -1) {
          return <Redirect to={rootRoute} />;
        }

        const idx = context.pages.length - 1;
        const editLink =
          typeof context.pages[idx] !== "undefined"
            ? `/journey/${context.pages[idx].route}`
            : "/";
        const editText =
          typeof context.pages[idx] !== "undefined"
            ? `Back to ${context.pages[idx].name}`
            : "Back";

        return (
          <QuotesPage
            {...{
              editLink: editLink,
              editText: editText,
            }}
          />
        );
      }}
    />
  );

  const switchRoute = (
    <Route
      path="/switch"
      render={() => {
        if (props.completedStages.indexOf(AppStages.DetailsPage) === -1) {
          return <Redirect to={rootRoute} />;
        }
        const listOptions = { listOptions: context.listOptions };
        return <SwitchForm {...context.detailsPage} {...listOptions} />;
      }}
    />
  );

  const saved = (
    <Route
      path={"/saved-journey"}
      render={(routeProps: RouteComponentProps) => {
        const props = {
          ...routeProps,
          loadRoute: "/results",
          typeOfLoad: "load-quotes",
        };

        sessionStorage.setItem("savedJourney", "true");

        return <LoadingJourney {...props} />;
      }}
    />
  );

  const load = (
    <Route
      path={"/load-journey"}
      render={(routeProps: RouteComponentProps) => {
        const props = {
          ...routeProps,
          loadRoute: "/results",
          typeOfLoad: "load-journey",
        };
        return <LoadingJourney {...props} />;
      }}
    />
  );

  const salesSaved = (
    <Route
      path={"/sales-saved-journey"}
      render={(routeProps: RouteComponentProps) => {
        const props = {
          ...routeProps,
          loadRoute: "/switch",
          typeOfLoad: "load-swtich",
        };
        return <LoadingJourney {...props} />;
      }}
    />
  );

  const completed = (
    <Route
      path={"/journey/completed"}
      render={(props: RouteComponentProps) => {
        return <CompletedPage />;
      }}
    />
  );

  const error = (
    <Route path="/error">
      <Header />
      <ErrorPage />
    </Route>
  );

  const defaultRoute = (
    <Route path="*">
      <Redirect to={rootRoute} />
    </Route>
  );

  const defaultErrorRoute = (
    <Route path="*">
      <Redirect to={"/error"} />
    </Route>
  );

  const routes = environment.REACT_APP_IS_CUSTOMER_PORTAL
    ? [saved, results, switchRoute, salesSaved, completed, error, defaultErrorRoute]
    : [
        home,
        pages,
        results,
        switchRoute,
        saved,
        salesSaved,
        load,
        completed,
        error,
        defaultRoute,
      ];

  return (
    <>
      <Switch>{routes}</Switch>
      <Footer></Footer>
    </>
  );
};

export default connector(App);
