import { useContext, useEffect, useState } from 'react';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import {
  Address,
  AddressDetail,
  AddressSearchType,
} from '../../../models/address.model';
import API from '../../../lib/API';
import { FuelType } from '../../../models/utility-detail';
import { addressFormat } from '../../../lib/addressFormat';
import { InputActionMeta, ValueType } from 'react-select';
import { testValidPostcode } from '../../../lib/testValidPostcode';
import { AppData } from '../../../providers/AppData';
import LookupFormWithSelectList from '../../forms/LookupFormWithSelectList';

type AddressSelectProps = {
  fuelType: number;
  searchType: any;
  handleChange: (selectedOption: ValueType<AddressDetail, boolean>) => void;
  formatOptionLabel: (detail: AddressDetail) => JSX.Element;
  placeholder: string;
  selectPlaceholder: string;
  passedInputValue: string;
  getInputChange: (value: string, actionMeta: InputActionMeta) => void;
  forceScrollIntoVisibility: () => void;
};

const searchByPostcodeDebounced = AwesomeDebouncePromise(
  API.getAddressByPostCode,
  500
);
const searchByMPANDebounced = AwesomeDebouncePromise(API.getAddressByMPAN, 500);

const AddressSelect = ({
  fuelType,
  searchType,
  handleChange,
  placeholder,
  passedInputValue,
  forceScrollIntoVisibility,
  selectPlaceholder
}: AddressSelectProps) => {
  const [options, setOptions] = useState<any>([]);
  const [inputValue, setInputValue] = useState<string>(passedInputValue);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [prevSearchType, setPrevSearchType] = useState<any>(searchType)
  const { labels } = useContext(AppData);

  useEffect(() => {
    if (passedInputValue) {
      getOptions(passedInputValue);
    }
  }, []);

  useEffect(() => {
    if (prevSearchType != searchType) {
      setPrevSearchType(searchType);
      setInputValue('');
      setError(undefined);
      setOptions([]);
    }    
  });

  const getAddresses = async (
    inputValue: string
  ): Promise<any> => {
    return await Promise.resolve(searchByPostcodeDebounced(inputValue, fuelType))
        .then((responses) => {
          if (responses.length > 0) {
            const elecArray: Address[] = responses.filter(
                (address) => address.meterType === FuelType.Electricity
            );
            const showMpan = elecArray.length > 1;
            const ele = elecArray.map<AddressDetail>((address) => ({
              address,
              value: addressFormat(address),
              label: addressFormat(address),
              showMPAN: showMpan,
            }));

            const gasArray: Address[] = responses.filter(
                (address) => address.meterType === FuelType.Gas
            );
            const showMrn = gasArray.length > 1;
            const gas = gasArray.map<AddressDetail>((address) => ({
              address,
              value: addressFormat(address),
              label: addressFormat(address),
              showMPAN: showMrn,
            }));

            const options = [...ele, ...gas];
            setOptions(options);
            forceScrollIntoVisibility();

            return options;
          }
        })
        .catch(() => {
          setIsLoading(false);
          setError('Please check your postcode is correct.')
        });
  };

  const getAddressesByMPAN = async (
    inputValue: string
  ): Promise<any> => {
    return await Promise.resolve(searchByMPANDebounced(inputValue, fuelType))
        .then((responses) => {
          if (responses.length > 0) {
            const addresses = responses.map<AddressDetail>((address) => ({
              address,
              value: addressFormat(address),
              label: addressFormat(address),
            }));
            setOptions(addresses);
            forceScrollIntoVisibility();
            return addresses;
          } 
        })
        .catch(() => {
          setIsLoading(false); 
          setError('Please check the value you entered is correct.')
        });
  };

  const getOptions = async (
    inputValue: string
  ): Promise<any> => {
    if (searchType === AddressSearchType.Postcode) {
      if (inputValue && inputValue.length >= 4 && inputValue.length <= 7) {
        return await getAddresses(inputValue);
      }
      else {
        setError('Please check your postcode is correct.');
        setIsLoading(false);
      }
    }

    if (searchType === AddressSearchType.MPAN) {
      if (inputValue && inputValue.length >= 6 && (inputValue.length <= 10 || inputValue.length > 12)) {
        return await getAddressesByMPAN(inputValue);
      }
      else {
        setError("Please check the value you entered is correct.")
        setIsLoading(false);
      }
    } 
  };

  const handleInputSubmit = async () => {
    setIsLoading(true);
    setError(undefined);
    
    if (inputValue) {
      const response = await getOptions(inputValue.replace(/\s/g, ""));

      if (!response) {
        if (!error) {
          setError("No results found.");
        }
      }
    } else {
      setError("Please enter a value.");
    }   
    
    setIsLoading(false);
  }

  return (
    <>
      <LookupFormWithSelectList
        value={inputValue}
        onChange={(e: any) => setInputValue(e.target.value)}
        placeholder={placeholder}
        onClick={handleInputSubmit}
        isLoading={isLoading}
        isError={error}
        defaultOptions={options}
        selectListOnChange={handleChange}
        selectPlaceholder={selectPlaceholder}
      />
    </>
  );
};

export default AddressSelect;
