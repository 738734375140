// Lib
import React from "react";

// Icons
import IconTick from "../icons/IconTick";

// TS Definitions
import { SupplierFeaturesProps } from "../../interfaces/Supplier";

class SupplierFeatures extends React.Component<SupplierFeaturesProps>{
    public render = () => {
        return (
            <ul className={"quote-details"}>
                {this.props.features.map((feature : {text:string}, idx: number) => {
                    return (
                        <li key={`feature-${idx}`}>
                            <IconTick wrapperClass={"quote-details__icon"}/>
                            <span className={"heading-4"}>{feature.text}</span>
                        </li>
                    );
                })}
            </ul>
        )
    }
}

export default SupplierFeatures;